    <template>
        <div class="search-alerts__popup">
            <div class="search-alerts__popup-overlay"></div>
            <div class="search-alerts__popup-content">
                <div class="row">
                    <div class="col">
                        <h3 class="search-alerts__popup-title">Invite viewers to this blockchain idea board</h3>
                    </div>
                    <div class="col">
                        <i name="close" class="fa fa-times float-right search-alerts__icon cursor-pointer p-3" @click="$emit('hide_invitations')"></i>
                    </div>

                </div>

                <div id="block_alias_invitations" class="container no-gutters" style="max-width: 5000px; max-height: 500px; overflow-y: scroll;">
                    <div class="row" style="border-bottom: 1px solid #DDD; width: 100%;">
                        <div class="col-2 pt-3">
                            <span style="font-weight: 500;">Name</span>
                        </div>
                        <div class="col-2 pt-3">
                            <span style="font-weight: 500;">Email</span>
                        </div>
                        <div class="col-2 pt-3">
                            <span style="font-weight: 500;">Phone</span>
                        </div>
                        <div class="col-2 pt-3">
                            <span style="font-weight: 500;">Company</span>
                        </div>
                        <div class="col-2 pt-3">
                            <span class="hit_timestamp d-inline-block fullwidth" style="font-weight: 500;">Visits</span>
                        </div>
                        <div class="col-2 pt-3 d-inline-flex justify-content-end pb-3">
                            <span style="font-weight: 500;">Actions</span>
                        </div>
                    </div>
                    <div class="row" v-for="(alias, aliasKey) in aliases" v-if="aliases.length" style="border-bottom: 1px solid #DDD; width: 100%;">
                        <div class="col-2 pt-3">
                            <span class="word-wraph-anywhere" v-text="alias.name"></span>
                        </div>
                        <div class="col-2 pt-3">
                            <span class="word-wraph-anywhere" v-text="alias.email"></span>
                        </div>
                        <div class="col-2 pt-3">
                            <span class="word-wraph-anywhere" v-text="alias.phone"></span>
                        </div>
                        <div class="col-2 pt-3">
                            <span class="word-wraph-anywhere" v-text="alias.company"></span>
                        </div>
                        <div class="col-2 pt-3">
                            <span class="hit_timestamp d-inline-block fullwidth word-wraph-anywhere" v-for="hit in alias.hits" v-text="DateTime.fromSQL(hit.timestamp).toFormat('ff')"></span>
                        </div>
                        <div class="col-2 pt-3 d-inline-flex justify-content-end pb-3">
                            <button class="btn btn-sm btn-primary mr-3" @click="sendInvitationEmail($event, alias.email, alias.identifier)">
                                <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                Resend</button>
                            <button class="btn btn-sm btn-danger" @click="deleteBlockAlias($event, alias.identifier, aliasKey)">Delete</button>
                        </div>
                    </div>
                    <div class="row" v-if="!aliases.length">
                        <div class="col-12 text-center mt-4" style="border-bottom: 1px solid #DDD">
                            <h4 class="d-inline">No invitations</h4>
                            <p>Use the form below to send invitations</p>
                        </div>
                    </div>

                </div>

                <div id="new" class="col-12 d-inline-flex justify-content-end">

                    <!--<validation-provider rules="required" v-slot="{ errors }">-->
                    <!--    <input v-model="value" name="myinput" type="text" />-->
                    <!--    <span>{{ errors[0] }}</span>-->
                    <!--</validation-provider>-->

                    <div class="spinner-border" role="status" style="width: 2rem; height: 2rem; margin-top: 48px;" v-if="uicontrol.loading"></div>

                    <template v-else>

                        <div class="form-group p-3">
                            <label for="name">Name</label>
                            <input type="text" id="name" name="name"  class="form-control" v-model="form.name" v-validate="'required'">
                            <span>{{ errors.first('name') }}</span>
                        </div>

                        <div class="form-group p-3">
                            <label for="email">Email</label>
                            <input type="text" id="email" name="email" class="form-control" v-model="form.email" v-validate="'required|email'">
                            <span>{{ errors.first('email') }}</span>
                        </div>

                        <div class="form-group p-3">
                            <label for="phone">Phone</label>
                            <input type="text" id="phone" name="phone" class="form-control" v-model="form.phone" v-validate="'required'">
                            <span>{{ errors.first('phone') }}</span>
                        </div>

                        <div class="form-group p-3">
                            <label for="company">Company</label>
                            <input type="text" id="company" name="company" class="form-control" v-model="form.company" v-validate="'required'">
                            <span>{{ errors.first('company') }}</span>
                        </div>

                    </template>

                    <div class="form-group p-3">
                        <button class="btn btn-primary btn-rounded" style="margin-top: 32px;" @click="createAliasAndSendInvitation()">Add</button>
                    </div>
                    </div>
            </div>
        </div>
    </template>
<script>

import {DateTime} from "luxon";

export default {
    name: "BlockInvitations",
    props: ['aliases'],
    components : {
    },
    data: function () {
        return {
            form: {
                name: '',
                email: '',
                phone : '',
                company: ''
            },
            uicontrol : {
                loading : false
            },
            DateTime : DateTime
        }
    },

    methods: {

        createAliasAndSendInvitation() {

            var vm = this;

            vm.$validator.validate().then(valid => {

                if (valid) {

                    vm.uicontrol.loading = true;
                    axios.post(window.location.href + '/create-alias-and-send-invitation-email', vm.form).then(r => {
                        vm.$emit('add_alias', r.data);
                        vm.form.name = vm.form.email = vm.form.phone = vm.form.company = null;
                        vm.uicontrol.loading = false;
                    });

                }

            });

        },

        sendInvitationEmail(event, alias_email, alias_identifier){

            event.target.classList.add('loading');
            event.target.disabled = true;

            axios.post(window.location.href + '/send-invitation-email', {
                email : alias_email,
                block_alias_identifier : alias_identifier
            }).then( () => {

                event.target.classList.remove('loading');
                event.target.disabled = false;

            });

        },

        deleteBlockAlias(event, alias_identifier, index){

            var vm = this;

            event.target.classList.add('loading');
            event.target.disabled = true;

            axios.delete(window.location.href + '/delete-alias/' + alias_identifier, {}).then( () => {

                vm.$emit('delete_alias', index);
                event.target.classList.remove('loading');
                event.target.disabled = false;

            });

        }

    }
}
</script>

<style scoped>

</style>
