<template>
	<div class="t-row" v-if="this.months[0].toMillis() && this.months[this.months.length - 1].toMillis()">
		<div class="sq section">
			{{ report.section }}
		</div>
		<div class="sq m4qchapter">
			{{ report.m4q_chapter }}
		</div>
		<div class="sq title">
			{{ report.title }}
		</div>
		<div class="sq description">
			{{ report.description }}
		</div>
		<div class="sq responsability">
			{{ report.data.Overview_responsability }}
		</div>
		<div class="sq total_cost">
			<div>
				<p class="text-danger mb-0 mt-2" style="height: 30px;">Requested {{ report.active_purchase_requests_grandtotal || 0 }} &euro;</p>
				<p class="mb-0" style="color: #F29D41; height: 30px;">Approved {{ report.approved_purchase_requests_grandtotal || 0 }} &euro;</p>
				<p class="mb-0" style="color: #5DB082; height: 30px;">Paid {{ report.paid_purchase_requests_grandtotal || 0 }} &euro;</p>
			</div>
		</div>

		<div class="d-flex flex-column">
			<component :is="timeline" :report="report" :months="months"></component>
		</div>
		<div class="sq open">
			<a :href="url">Open</a>
		</div>
	</div>


</template>

<script>

import result_events from "./reusable/Timeline.vue"
import purchase from "./reusable/PurchaseTimeline.vue"

export default {
	name: "ResultReport",
	props: {
		'report': {
			type: Object,
			default: null
		},
		'months': {
			type: Array,
			default: []
		},
		'timeline': {
			type: String,
			default: 'result_events'
		},
	},

	components: {
		result_events,
		purchase
	},

	computed: {

		url() {
			return '/dashboard/science/experiments/' + this.report.experiment.identifier + '/results/' + this.report.identifier;
		}

	}

}
</script>

<style scoped>

</style>
