<template>
    <a class="result empty no-link">
        <span>No results for this search</span>
    </a>
</template>

<script>
    export default {
        name : 'EmptyResult',
        props : ['result'],
        data : function(){
            return {

            }
        }
    }
</script>
