<template>
	<input :id="id" :name="name" class="form-control" type="text" :disabled="disabled || false">
</template>

<script>

import AutoNumeric from 'autonumeric';

export default {
	name: "FloatInput",
	data : function(){
		return {
			subject_value : null,
			element : null,
			autonumeric_instance : null
		}
	},
	props : [
		'id',
		'name',
		'val',
		'disabled',
		'decimals'
	],

	watch : {

	},

	methods :{

	},

	components: {
	},

	mounted(){

		var vm = this;

		this.element = document.getElementById(this.id);

		// autoNumeric with the defaults options
		this.autonumeric_instance = new AutoNumeric(this.element, this.val, {
			'decimalPlaces' : this.decimals
		});

		['change','textInput', 'input'].forEach( evt => {
			this.element.addEventListener(evt, function(){
				let number = vm.autonumeric_instance.getNumber()
				vm.$emit('update', number)
			}, false)
		});


	}
}
</script>

<style scoped>

</style>