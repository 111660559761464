<template>

    <div>

        <div class="ep" action="begin"></div>

        <div class="form-group">

            <!-- If no file and not uploading -->
            <template v-if="editable && !data.content && !uicontrol.uploading">
                <a class="btn btn-edit-exp ml-0" @click="file_input.click()">Select file</a>
            </template>

            <!-- If uploading -->
            <div v-else-if="uicontrol.uploading">
                <div class="progress">
                    <div class="progress-bar bg-success" role="progressbar" v-bind:style="{ 'width' : (uicontrol.upload_progress + '%') }" :aria-valuenow="uicontrol.upload_progress" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <h2 class="text-center" v-text="uicontrol.upload_progress + '%'"></h2>
            </div>

            <!-- If file -->
            <template v-else-if="data.content">

                <!-- File viewed from local source : file viewed from blockchain route -->
                <a :title="data.content.name.split('/')[1]" :href="(typeof data.content_base64 == 'undefined') ? '/file/' + data.content.identifier : window.location.href + '/file/' + data.content.identifier" target="_blank" v-if="">
                    <span class="file_name" v-text="fileName"></span>
                </a>

            </template>

            <template v-else>
                <span class="text-gray">No file selected</span>
            </template>

            <input type="file" class="d-none" :name="'file_input_' + id" :id="'file_input_' + id" accept="image/*,application/*" v-on:change="filePicked($event)">

        </div>

    </div>

</template>

<script>

export default {

    name: "File",
    props: ['index','data','editable'],
    data : function(){
        return {
            id : '',
            uicontrol: {
                editing: false,
                uploading: false,
                upload_progress: 0
            },
            selected_file: null,
            file_input: null,
            window : window
        }
    },

    computed: {

        fileUrl() {
            return baseurl + '/file/' + data.content.file.identifier;
        },

        fileName(){
            return this.data.content.name.split('/')[1].length > 40 ? this.data.content.name.split('/')[1].substr(0, 40) + '...' : this.data.content.name.split('/')[1];
        }

    },

    created(){
        this.id = this.makeid(10);
    },

    mounted() {
        this.file_input = document.getElementById('file_input_' + this.id);
    },

    methods : {

        makeid(length) {
            var result           = [];
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
                result.push(characters.charAt(Math.floor(Math.random() *
                    charactersLength)));
            }
            return result.join('');
        },

        filePicked: function (event) {

            this.selected_file = event.target.files[0];

            // Upload
            this.upload();

        },

        upload: function (event) {

            let vm = this;

            vm.uicontrol.uploading = true;

            let form_data = new FormData();

            form_data.append('uploaded_file', this.selected_file);

            axios.post('/file', form_data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: function (uploadEvent) {
                    vm.uicontrol.upload_progress = Math.round(uploadEvent.loaded / uploadEvent.total * 100);
                }
            }).then(response => {
                this.data.content = response.data.file;
                vm.uicontrol.uploading = false;
            })

        },

        deleteFile: function (event) {

            event.preventDefault();
            axios.delete('/file/' + this.data.content.file.identifier).then(response => {
                if (response.data.success) {
                    this.data.content.file = null;
                }
            });

        }

    },

    // Node specific properties
    readable_name: "File",
    type : 'file',
    sample_data : {
        content : '',
        tags : []
    },
    show_in_menu : true
}
</script>

<style scoped>

</style>
