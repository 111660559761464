\
<template>

	<div class="sq time" style="margin-bottom: 1px solid #DDD !important">

		<div class="progress-time">
			<span class="start" :style="{ 'left' : `calc(${getXAxisAsPercent(report.start_date)}% - 4px)`}" data-toggle="tooltip" data-placement="top" :title="'Started: ' + formatTimeStamp(report.start_date)" v-if="isInInterval(report.start_date)"></span>


			<span class="duration" v-if="report.end_date" :style="{ 'left' : `calc(${getXAxisAsPercent(report.start_date)}% - 4px)`,
																	'width' : (
																				// If signed, use the end date as the finishing of the line, else, use the last timestamp
																				(Boolean(report.signed_by_user_id) ? getXAxisAsPercent(report.end_date) : getXAxisAsPercent(report.end_dates[report.end_dates.length - 1].timestamp))
																				-
																				getXAxisAsPercent(report.start_date)) + '%'}">
			</span>

			<!-- The first end date ever set -->
			<span class="scheduled-end" :class="report.end_dates[0].accuracy" v-if="report.end_date && report.end_dates.length && isInInterval(report.end_dates[0].timestamp)" :style="{ 'left' : `calc(${getXAxisAsPercent(report.end_dates[0].timestamp)}% - 4px)`}" data-toggle="tooltip" data-placement="top" :title="'Scheduled end: ' + formatTimeStamp(report.end_dates[0].timestamp) + ' ' + (report.end_dates[report.end_dates.length - 1].accuracy + 'ly' )"></span>

			<template v-if="report.end_date && isInInterval(report.end_date)">
				<span class="amended" :style="{ 'left' : `calc(${getXAxisAsPercent(report.end_dates[report.end_dates.length - 1].timestamp)}% - 4px)`}" data-toggle="tooltip" data-placement="top" :title="'Amended: ' + formatTimeStamp(report.end_dates[report.end_dates.length - 1].timestamp)" v-if="report.end_dates.length > 1"></span>
				<span class="completed" :style="{ 'left' : `calc(${getXAxisAsPercent(report.end_date)}% - 4px)`}" data-toggle="tooltip" data-placement="top" :title="'Completed: ' + formatTimeStamp(report.end_date)" v-if="report.signed_by_user_id"></span>
			</template>

			<div class="dashed-line" :style="{ 'margin-left' : `calc(${getXAxisAsPercent(report.start_date)}%)`}" v-if="!Boolean(report.signed_by_user_id)"></div>

		</div>

		<div class="current_time" :style="{ 'margin-left' : `${getXAxisAsPercent(timestamp_now)}%`}" v-if="timestamp_now"></div>

		<div class="bg-lines">
			<span class="line" v-for="month in months"></span>
		</div>


	</div>

</template>


<script>

import {DateTime} from "luxon";

export default {
	name: "Timeline",
	props: ['report', 'months'],

	data: function () {
		return {
			first_month_millis: null,
			last_month_millis: null,
			timestamp_now: null
		}
	},

	mounted() {

		this.first_month_millis = this.months[0].toMillis();
		this.last_month_millis = this.months[this.months.length - 1].toMillis();

		// Set current timestamp
		this.timestamp_now = DateTime.local().toFormat('yyyy-MM-dd HH:mm:ss');

		// Enable tooltips for newly created DOM elements
		setTimeout(function () {
			$('[data-toggle="tooltip"]').tooltip();
		}, 500);
	},

	computed: {},

	methods: {

		getXAxisAsPercent(point_timestamp) {

			// Point
			let point_timestamp_millis = DateTime.fromSQL(point_timestamp).toMillis();

			//  Substract first month from timestamp_millis last_month_millis
			let start = 0;
			let point = point_timestamp_millis - this.first_month_millis;
			let end = this.last_month_millis - this.first_month_millis;

			// console.log('Points:', start, point, end);

			// Point is before the first month
			if (point < start) {
				console.log('Point smaller than start');
				return 0;
			}

			// Point is after last month
			if (point > end) {
				console.log('Point larger than end');
				return 100;
			}

			return point / end * 100;

		},

		formatTimeStamp(timestamp) {
			return DateTime.fromSQL(timestamp).toFormat('d LLL yyyy');
		},

		/**
		 * Return true if item falls inside FROM - TO interval
		 * @param timestamp
		 * @returns {boolean}
		 */
		isInInterval(timestamp) {
			return (this.getXAxisAsPercent(timestamp) < 100 && this.getXAxisAsPercent(timestamp) > 0);
		}

	}
}
</script>

<style scoped>

</style>
