var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({'margin-left' : _vm.margin_left }),attrs:{"id":"user_permissions"}},[_c('div',{staticClass:"usrs-groups"},_vm._l((_vm.users_local),function(user,pKey){return _c('div',{key:pKey,staticClass:"user",class:{ toslide : pKey > 0 },style:({ 'right': `${pKey * 20}px`, 'z-index': 9-`${pKey}`})},[_c('img',{staticStyle:{"width":"37px","margin-right":"10px"},attrs:{"src":'/plugins/profilethumbnailgenerator/profile_thumbnail.php?name=' + user.name,"alt":user.name,"title":user.name}}),_vm._v(" "),(_vm.editable)?_c('i',{staticClass:"remove",attrs:{"title":"Remove user from participants"},on:{"click":function($event){return _vm.removeUser(user, pKey)}}},[_vm._v("+")]):_vm._e()])}),0),_vm._v(" "),_c('div',{staticClass:"form-group add-rm-users"},[(_vm.uicontrol.show_search)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchstr),expression:"searchstr"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.searchstr)},on:{"input":function($event){if($event.target.composing)return;_vm.searchstr=$event.target.value}}}):_vm._e(),_vm._v(" "),(_vm.uicontrol.show_search)?_c('span',{staticClass:"close-input",on:{"click":() => {
                _vm.uicontrol.show_search = !_vm.uicontrol.show_search;
                _vm.results = [];
                _vm.searchstr = '';
            }}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"d-block"},[(_vm.results)?_c('ul',{staticClass:"results badge"},_vm._l((_vm.results),function(result){return _c('li',{class:{ exists : _vm.userExists(result) , disabled : Boolean(result.disabled) },on:{"click":function($event){return _vm.addUser(result)}}},[_vm._v("\n                    "+_vm._s(result.name)+"\n                ")])}),0):_vm._e()]),_vm._v(" "),(!_vm.uicontrol.show_search && _vm.editable)?_c('button',{staticClass:"plus",on:{"click":() => {
                _vm.$emit('open_search')
                _vm.uicontrol.show_search = !_vm.uicontrol.show_search;
                _vm.results = [];
                _vm.searchstr = '';
            }}},[_vm._v("\n            "+_vm._s(_vm.uicontrol.show_search ? '-' : '+')+"\n        ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }