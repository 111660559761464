<template>
    <div class="card countries mb-4 pb-4">
        <div class="top">
            <p class="card-title" v-text="title"></p>
        </div>

        <div class="content">

            <template v-if="uicontrol.ready">
                <iframe :src="'https://ourworldindata.org/explorers/coronavirus-data-explorer?zoomToSelection=true&time=2020-03-01..latest&pickerSort=desc&pickerMetric=new_cases_smoothed_per_million&Metric=Confirmed+cases&Interval=7-day+rolling+average&Relative+to+Population=true&Align+outbreaks=false&country=' + country_codes.toString().replaceAll(',', '~') + '&hideControls=true'"  loading="lazy" style="width: 100%; height: 600px; border: 0px none;"></iframe>
            </template>

            <template v-else="">
                <div class="fullwidth text-center">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>

export default {
    name: "CovidStatistics",
    data: function () {
        return {
            country_codes: [],
            uicontrol: {
                ready: false
            },
        }
    },

    props: [
        'countries', 'title'
    ],

    mounted() {

        var vm = this;

        axios.get('https://raphstats.xyz/api/data/top-ten-countries-by-infection').then(r => {
            vm.country_codes = r.data;
            vm.uicontrol.ready = true;
        });

    },

    methods: {

    }

}
</script>

<style scoped>

</style>
