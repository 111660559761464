<template>
    <main>
        <li id="notifications" class="nav-item dropdown">
            <a class="nav-link dropdown-toggle text-muted text-muted waves-effect waves-dark" @click="uicontrol.dropdown.visible = !uicontrol.dropdown.visible">
                <img src="/assets/icons/bell.svg" alt="">
                <div class="notify" v-show="blink">
                    <span class="heartbit"></span>
                    <span class="point"></span>
                </div>
            </a>
            <div class="dropdown-menu notificationbox scale-up-left" :class="{ 'show' : uicontrol.dropdown.visible }">
                <ul>
                    <li>
                        <div class="drop-title">
                            Notifications
                            <img src="/assets/icons/gear.svg" alt="Settings" class="settings_icon" @click="showSettings()" v-if="!uicontrol.settings.visible">
                            <button class="mark_all_read" v-on:click="markAllRead">Mark all as read</button>
                        </div>
                    </li>
                    <li v-if="uicontrol.settings.visible" class="p-4 pb-0">
                        <h4>Settings</h4>
                        <p class="mb-3">Enabled notification channels</p>
                        <form action="" class="">
                            <div class="form-group" v-for="channel in settings.channels">
                                <input type="checkbox"
                                    :id="channel.id"
                                    :name="channel.id"
                                    :checked="isChannelEnabled(channel.id)"
                                    @click="toggleStatus(channel.id)">
                                <label :for="channel.id" v-text="channel.label"></label>
                                <p class="small" v-text="channel.description"></p>
                            </div>
                        </form>
                        <button @click="uicontrol.settings.visible = false" class="btn-filled btn-filled-rounded btn-filled-right border-0 mb-3">Close</button>
                    </li>
                    <li v-else>
                        <div class="message-center" id="message-center" v-if="list.length">
                            <a :href="'/notification/' + notification.id" v-for="notification in list" v-bind:class="(notification.read_at === null) ? 'unread' : 'read'">
                                <div class="notification-content">
                                    <p class="notification-title" v-text="notification.data.title"></p>
                                    <span class="notification-desc" v-text="notification.data.body"></span>
                                    <span class="time" v-text="timeStamp(notification.created_at)"></span>
                                </div>
                                <i v-show="notification.loading" class="iconspin loading mdi mdi-rotate-right"></i>
                            </a>
							<div class="p-3 text-center">
								<button class="btn btn-sm btn-rounded btn-outline-secondary" @click="get()" v-if="!ran_out_of_notifications_to_load">Load more</button>
								<span class="text-secondary" v-else="">No more notifications to load</span>
							</div>
                        </div>
                        <div class="p-5" v-else>
                            <h5 class="text-center">You have no notifications</h5>
                        </div>
                    </li>
                </ul>
            </div>
        </li>
    </main>
</template>

<script>

    import {DateTime} from "luxon";

    export default {

        name: "Notifications",

        data: function () {

            return {
                DateTime: DateTime,
                blink: false,
                list: [],
				page : 0,
				ran_out_of_notifications_to_load : false,
                uicontrol: {
                    dropdown: {
                        visible: false
                    },
                    settings: {
                        visible: false,
                    },
                },
                settings : {
                    disabled_channels : [],
                    channels : [
                        {
                            id : 'email',
                            label : 'E-mail',
                            description : 'Sends an email'
                        },
                        {
                            id : 'sms',
                            label : 'SMS',
                            description : 'Sends a text message'
                        },
                        {
                            id : 'push',
                            label : 'Push Notification',
                            description : 'Sends a push notification to browsers'
                        }
                    ]
                }
            }

        },

        computed : {



        },

        mounted: function () {

            var vm = this;

            // Capture click outside the component
            window.addEventListener('click', function(e){
                if (vm.uicontrol.dropdown.visible && !document.getElementById('notifications').contains(e.target)){
                    vm.uicontrol.dropdown.visible = false;
                    vm.uicontrol.settings.visible = false;
                }
            });

            // Get notifications from database
            this.get();


            // Subscribe to broadcast channel
            Echo.private('App.Models.User.' + user.identifier).notification((notification) => {
                delete notification.type;
                this.list.unshift(notification);
                this.refreshBlink();
            });

        },

        methods: {

            timeStamp(string) {

                // Attempt to format timestamp
                let formatted_timestamp = this.DateTime.fromISO(string).toFormat('ff');

                // Return formatted time string if timestamp is valid, otherwise return string as it came in, ex: "1 second ago"
                return formatted_timestamp !== 'Invalid DateTime' ? formatted_timestamp : string;

            },

            // Shows the blink graphic if there is at least 1 unread notification
            refreshBlink: function () {
                let vm = this;

                vm.blink = false;

                $.each(vm.list, function (index, element) {
                    // Blink if there is at least 1 unread element
                    if (element.read_at === null) vm.blink = true;
                });
            },

            // Gets the notifications for the current user (default first 10)
            get: function () {

                let vm = this;

                axios.get('/notification', {
					params : {
						'page': vm.page,
						'limit': 10
					}
				}).then(response => {

					if(response.data.length){

						vm.list = vm.list.concat(response.data);
						vm.page++;
						vm.refreshBlink();

					}else{

						vm.ran_out_of_notifications_to_load = true;

					}


                })

            },

            markAllRead: function () {

                let vm = this;

                axios.post('/notification/markallread');

                // Stop blinking
                vm.blink = false;

                // Set all notifications as read
                $.each(vm.list, function (index, element) {
                    element.read_at = true;
                });

            },

            add: function (notification) {
                this.blink = true;
                this.list.unshift(notification);
            },

            toggleStatus : function(string){

                if(this.isChannelEnabled(string)){

                    // prevent both email and sms from being disabled
                    if((string == 'email' && this.settings.disabled_channels.includes('sms')) || (string == 'sms' && this.settings.disabled_channels.includes('email'))){
                        Swal.fire('Email an SMS cannot both be disabled', 'Please keep at least one of the two options enabled');
                        return;
                    }

                    // Disable the channel (attach disabled_notification_channel - sms to user meta)
                    this.attachMeta(string);
                    this.settings.disabled_channels.push(string);

                }else{

                    this.detachMeta(string);

                    _.remove(this.settings.disabled_channels, function(channel){
                        return channel == string;
                    });

                }

            },

            attachMeta : function(string){

                return axios.post('/dashboard/meta', {
                    key : 'disabled_notification_channel',
                    model : 'App\\Models\\User',
                    value : string,
                    model_id : null // force controller to get it from current user
                });

            },

            detachMeta : function(string){

                return axios.delete('/dashboard/meta', {
                    params : {
                        key : 'disabled_notification_channel',
                        model : 'App\\Models\\User',
                        value : string,
                        model_id : null // force controller to get it from current user
                    }
                });

            },

            getMeta : function(){

                return axios.get('/dashboard/meta', {
                    params : {
                        key : 'disabled_notification_channel',
                        model : 'App\\Models\\User',
                        model_id : null // force controller to get it from current user
                    }
                });

            },

            showSettings(){

                this.getMeta().then(response => {
                    this.settings.disabled_channels = response.data;
                    this.uicontrol.settings.visible = true;
                });

            },

            isChannelEnabled(string){
                return !this.settings.disabled_channels.includes(string);
            }

        }


    }
</script>

<style scoped>

</style>
