<template>
    <div class="container reports labs">
        <div class="experiment-top-nav">
            <h4>{{variables.page_title}}</h4>
            <div class="tools">

                <search :category="variables.search_category"></search>

                <div class="add-tags ml-4" v-if="available_tags.length">

                    <h4>Tags: </h4>

                    <div class="added">
                        <div class="tag" v-for="(selectedTag, stKey) in filters.tags">
                            <p v-text="selectedTag.name"></p>
                            <img src="/assets/icons/remove-lab.svg" class="remove-lab" @click="toggleTag(selectedTag)">
                        </div>
                    </div>

                    <div class="add" id="add-tag">
                        <div class="tag" @click="uicontrol.select_tag = !uicontrol.select_tag">
                            <p>Select</p>
                            <img class="add-icon add-tag" src="/assets/icons/lab-add.svg">
                            <img class="add-active-icon add-tag" :src="uicontrol.select_tag ? '/assets/icons/add-lab-up.svg' : '/assets/icons/add-lab-down.svg'">
                        </div>
                        <div class="select-tag" id="select-tag" v-if="uicontrol.select_tag">
                            <form action="">
                                <div class="form-check" v-for="(tag, tKey) in available_tags">
                                    <input class="form-check-input" type="checkbox" value=""
                                        :id="tag.id"
                                        :checked="filters.tags.find(item => item.id == tag.id)"
                                        @click="toggleTag(tag)">
                                    <label class="form-check-label" :for="tag.id" v-text="tag.name"></label>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>

                <div class="add-labs ml-4">

                    <h4>Facilities:</h4>
                    <div class="added">
                        <div class="lab" v-for="(selectedLab, slKey) in filters.labs">
                            <p v-text="selectedLab.name"></p>
                            <img src="/assets/icons/remove-lab.svg" class="remove-lab" @click="toggleLab(selectedLab)">
                        </div>
                    </div>

                    <div class="add" id="add-lab">
                        <div class="lab" @click="uicontrol.select_lab = !uicontrol.select_lab">
                            <p>Select</p>
                            <img class="add-icon add-lab" src="/assets/icons/lab-add.svg">
                            <img class="add-active-icon add-lab" :src="uicontrol.select_lab ? '/assets/icons/add-lab-up.svg' : '/assets/icons/add-lab-down.svg'">
                        </div>
                        <div class="select-lab" id="select-lab" v-if="uicontrol.select_lab">
                            <form action="">
                                <div class="form-check" v-for="(lab, lKey) in available_facilities">
                                    <input class="form-check-input" type="checkbox" value=""
                                        :id="lab.id"
                                        :checked="filters.labs.find(item => item.id == lab.id)"
                                        @click="toggleLab(lab)">
                                    <label class="form-check-label" :for="lab.id" v-text="lab.name"></label>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>


                <a class="btn new-exp" :href="variables['create_button_url']">
                    <img src="/assets/icons/plus-circle.svg" alt="New Experiment">
                    {{variables['create_button_text']}}
                </a>

                <!--<div class="date">-->
                <!--    <img class="mini-calendar" src="/assets/icons/mini-calendar.svg">-->
                <!--    <flat-pickr v-model="filters.start_date" placeholder="Start date"></flat-pickr>-->
                <!--    <span></span>-->
                <!--    <flat-pickr v-model="filters.end_date" placeholder="End date"></flat-pickr>-->
                <!--</div>-->

            </div>
        </div>

        <div class="experiments-grid">

            <div class="prev-lab" v-for="facility in facilities">
                <p class="title">{{ facility.country.name }}</p>
                <p class="subtitle">{{ facility.name }}</p>
                <span>{{variables.relation_label}}:</span>
                <ul>
                    <li v-for="item in facility[variables.relation]" :class="{ 'signed' : Boolean(item.signed_by_user_id) }">

						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check text-success" viewBox="0 0 16 16">
							<path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
						</svg>

                        <a :href="`/dashboard/science/${variables.relation.replace('_', '-')}/${item.identifier}`" :title="`Lab: ${facility.name}`">{{item.title}}</a>
                    </li>
                </ul>
            </div>

        </div>

    </div>
</template>

<script>

import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {DateTime} from "luxon";
import search from '@/components/search/Search';

// ScienceLabs

export default {
    name: "Facilities",
    data: function () {
        return {
            DateTime: DateTime,
            filters: {
                start_date: null,
                end_date: null,
                labs : [],
                tags : []
            },
            facilities : [],
            uicontrol : {
                select_lab : false,
                select_tag : false
            }
        }
    },

    components: {
        flatPickr,
        search
    },

    props : [
        'available_facilities',
        'available_tags',
        'variables',
    ],

    computed : {

        months(){

            let start_month = DateTime.fromSQL(this.filters.start_date);
            let end_month = DateTime.fromSQL(this.filters.end_date);

            // Populate array with start, end months and every month in between
            let months = [];
            var increment = 0;

            var current_month = start_month;

            while(current_month < end_month){
                current_month = start_month.plus({ months: increment });
                months.push(current_month);
                increment++;
            }

            return months;

        }

    },

    watch: {
        filters: {
            handler(val) {
                this.get();
            },
            deep: true
        }
    },

    mounted() {

        var vm = this;

        this.filters.start_date = DateTime.local().startOf('year').toSQL();
        this.filters.end_date = DateTime.local().endOf('year').toSQL();

        // Capture click outside the component
        window.addEventListener('click', function(e){
            if (vm.uicontrol.select_lab && (!document.getElementById('select-lab').contains(e.target) && !document.getElementById('add-lab').contains(e.target))){
                vm.uicontrol.select_lab = false;
            }
        });

        // Capture click outside the component
        window.addEventListener('click', function(e){
            if (vm.uicontrol.select_tag && (!document.getElementById('select-tag').contains(e.target) && !document.getElementById('add-tag').contains(e.target))){
                vm.uicontrol.select_tag = false;
            }
        });

    },

    methods: {

        timelineMove(direction){

            let start_date = DateTime.fromSQL(this.filters.start_date);
            let end_date = DateTime.fromSQL(this.filters.end_date);

            switch(direction){

                case 'left':

                    start_date = start_date.minus({ months : 1 });
                    end_date = end_date.minus({ months : 1 });

                    break;

                case 'right':

                    start_date = start_date.plus({ months : 1 });
                    end_date = end_date.plus({ months : 1 });

                    break;

                case 'zoomin':

                    start_date = start_date.plus({ months : 1 });
                    end_date = end_date.minus({ months : 1 });

                    break;

                case 'zoomout':

                    start_date = start_date.minus({ months : 1 });
                    end_date = end_date.plus({ months : 1 });

                    break;

            }

            this.filters.start_date = start_date.toSQL();
            this.filters.end_date = end_date.toSQL();

        },


        get() {

            var vm = this;
            this.facilities = [];
            return axios.get('/dashboard/science/facilities/get', {
                params: {
                    type : vm.variables['type'],
                    relation : vm.variables['relation'],
                    relation_view_permission : vm.variables['relation_view_permission'],
                    from: vm.filters.start_date,
                    to: vm.filters.end_date,
                    facilities : this.filters.labs.map(lab => lab.id),
                    tags : this.filters.tags.map(tag => tag.id),

                }
            }).then(r => {
                this.facilities = r.data;
            });

        },

        toggleLab(lab){

            let index = this.filters.labs.findIndex(item => item.id == lab.id);

            if(index > -1){
                this.filters.labs.splice(index, 1);
            }else{
                this.filters.labs.push(lab);
            }

        },

        toggleTag(tag){

            let index = this.filters.tags.findIndex(item => item.id == tag.id);

            if(index > -1){
                this.filters.tags.splice(index, 1);
            }else{
                this.filters.tags.push(tag);
            }

        },

    }
}
</script>

<style scoped>

</style>
