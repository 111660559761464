<template>
    <div class="form-group row image-component">
        <label class="col-2 text-grey" for="" v-text="field.label"></label>

        <div class="col-10 text-center" v-if="editable">

            <template v-if="!field_value && !uicontrol.uploading">
                <a class="btn btn-edit-exp" @click="file_input.click()">Select image</a>
                <img class="cursor-pointer" src="/assets/sections/image_placeholder.jpg" alt="" @click="file_input.click()">
            </template>

            <div v-else-if="uicontrol.uploading">
                <div class="progress">
                    <div class="progress-bar bg-success" role="progressbar" v-bind:style="{ 'width' : (uicontrol.upload_progress + '%') }" :aria-valuenow="uicontrol.upload_progress" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <h2 class="text-center" v-text="uicontrol.upload_progress + '%'"></h2>
            </div>

            <template v-else>
                <div class="image-wrapper">
                    <button class="btn btn-sm btn-danger delete" @click="deleteImage($event)">Delete image</button>
                    <img class="maxwidth-100" :src="imageUrl" alt="">
                </div>
            </template>

            <input type="file" class="d-none" name="file_input" id="profile_picture_input" accept="image/*" v-on:change="filePicked($event)">

        </div>

        <div class="col-10" v-else>
            <img class="maxwidth-100 toshow" :src="field_value ? imageUrl : '/assets/sections/image_placeholder.jpg'">
        </div>

    </div>
</template>

<script>
    export default {
        name: "ImageComponent",
        props: ['tab', 'field', 'val', 'editable'],

        data: function () {

            return {
                uicontrol: {
                    editing: false,
                    uploading: false,
                    upload_progress: 0
                },
                selected_file: null,
                file_input: null,
                fresh_val: null

            }

        },

        computed: {


            field_value: {

                get: function () {
                    return this.val;
                },

                set: function (newVal) {

                    this.fresh_val = newVal;

                    this.$emit('update', {
                        tab: this.tab,
                        field: this.field,
                        value: newVal
                    });
                }

            },

            imageUrl() {

                let val = (this.fresh_val || this.val);
                return baseurl + '/file/' + val;

            }

        },

        mounted() {
            this.file_input = document.getElementById('profile_picture_input');
        },


        methods: {


            filePicked: function (event) {

                this.selected_file = event.target.files[0];

                if (!this.selected_file.type.match(/(gif|png|jpg|jpeg)$/))
                    Swal.fire('File type not supported', 'Please upload one of these image formats: .jpg / .jpeg / .gif / .png');

                // Upload
                this.upload();

            },

            upload: function (event) {

                let vm = this;

                vm.uicontrol.uploading = true;

                let form_data = new FormData();

                form_data.append('uploaded_file', this.selected_file);

                axios.post('/file', form_data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: function (uploadEvent) {
                        vm.uicontrol.upload_progress = Math.round(uploadEvent.loaded / uploadEvent.total * 100);
                    }
                }).then(response => {
                    this.field_value = response.data.file.identifier;
                    vm.uicontrol.uploading = false;
                })

            },

            deleteImage: function (event) {

                event.preventDefault();

                axios.delete('/file/' + this.field_value, {
                    identifier: this.field_value
                }).then(response => {
                    if (response.data.success) {
                        this.field_value = null;
                        this.$emit('save');
                    }

                });

            }


        }


    }
</script>
