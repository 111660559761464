<template>

    <component
        :is="shipment.type"
        :shipment="shipment"
        :is_author="is_author"
        :is_operator="is_operator"
        :operators="operators"
        @update="update($event)"
        @save="save($event)"
        v-if="uicontrol.ready"
    ></component>

</template>

<script>


import standard from "./types/standard";
import direct from "./types/direct";

export default {

    name: "Shipment",

    data : function () {
      return {
          shipment : null,
          uicontrol: {
              ready : false
          }
      }
    },

    props : [
        'operators',
        'is_author',
        'is_operator'
    ],

    components : {
        standard,
		direct
    },

	computed : {

	},

    mounted(){
        this.shipment = shipment;
        this.uicontrol.ready = true;
    },

    methods: {

        update(updated_shipment){
            this.shipment = updated_shipment;
        },

        save(shipment){
            const vm = this;
            this.update(shipment);
            axios.put('/dashboard/shipment/' + vm.shipment.identifier, vm.shipment).then(response => {
                console.log(response.data);
            });
        }

    }

}

</script>

<style scoped>

</style>
