<template>

    <main>
        <a :href="fileUrl" v-if="field_value" v-text="prettyFileName"></a>
    </main>

</template>


<script>
export default {
    name: "TextCSVViewer",
    props : [
        'field_value'
    ],

    computed: {

        fileUrl() {
            return baseurl + '/file/download/' + this.field_value.identifier;
        },

        prettyFileName(){
            return this.field_value.name.split('/')[1];
        }

    },

    methods : {

    }

}
</script>

<style scoped>

</style>
