<template>
    <div>

        <div class="ep" action="begin"></div>

        <!-- Experiment Popup -->
        <template v-if="uicontrol.show_experiment_result_popup && result">
            <div class="experiment_result_popup">
                <button class="btn btn-sm btn-outline-secondary mb-3" @click="uicontrol.show_experiment_result_popup = false">Hide result</button>
                <experiment_result
                    :editable="false"
                    :hidden_tabs="['comments', 'actions']"
                    :can_participate="false"
                    :is_admin="false"
                    :is_author="false"
                    :experiment="result.experiment"
                    :result="result">
                </experiment_result>
            </div>
        </template>

        <!-- Search -->
        <template v-else="">

            <search
                v-if="uicontrol.show_search && editable"
                :category="'science_experiment_results'"
                :action="'select'"
                @selected_search_result="add($event)"
            ></search>

            <div v-else-if="Boolean(data.identifier)">

                <!--  Intranet button  -->
                <div v-if="!uicontrol.show_blockchain_ui">

                    <span v-html="title_with_line_breaks"></span> <br>

                    <a class="btn btn-secondary btn-sm mt-2" :href="data.url" :title="data.title" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-link" viewBox="0 0 16 16" style="width: 20px; margin-top: -2px;">
                            <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z"/>
                            <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z"/>
                        </svg>
                        Open from source
                    </a>

                    <button class="btn btn-secondary btn-sm mt-2" @click="openResultHere()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-box-seam" viewBox="0 0 16 16" style="width: 20px; margin-top: -2px;">
                            <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5l2.404.961L10.404 2l-2.218-.887zm3.564 1.426L5.596 5 8 5.961 14.154 3.5l-2.404-.961zm3.25 1.7-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z"/>
                        </svg>
                        Open here
                    </button>

                </div>



                <!--<a :title="data.title" class="title" target="_blank" :href="data.url" v-text="data.title.length > 40 ? data.title.substr(0, 40) + '...' : data.title" v-if="!uicontrol.show_blockchain_ui"></a>-->

                <!-- Blockchain "button" -->
                <a :title="data.title" class="title" target="_blank"v-text="data.title.length > 40 ? data.title.substr(0, 40) + '...' : data.title" @click="(e) => {
                    e.preventDefault();
                    uicontrol.show_experiment_result_popup = true;
                }" v-if="uicontrol.show_blockchain_ui"></a>

            </div>

            <div v-else="">
                <span class="text-gray">No result selected</span>
            </div>

        </template>






    </div>

</template>

<script>

export default {
    name: "ExperimentResult",
    props: ['index','data','editable'],
    data : function(){
        return {
            uicontrol : {
                show_search : true,
                show_blockchain_ui : false,
                show_experiment_result_popup : false
            },

            // We SHOULD NOT load the entire Experiment Result in the data property, because that would end up saved in the boards table when you save the board.
            // So we are rendering the experiment_result embedded component from `result` object instead of the data object.
                // - on blockchain view, result and data are the same thing, so we assign them on mount
                // - on intranet view, data contains only minimal information about the Experiment Result: (title, url, identifier, tags) and that is the only data we want saved in the boards table. When we want to display the experiment_result embedded component, we async load the Experiment Result into the result object

            result : {

            }
        }
    },

    computed : {

        title_with_line_breaks(){

            let string_with_line_breaks = '';

            let i = 0;

            for(var letter of this.data.title){

                string_with_line_breaks+=letter;

                if(i >= 30 && letter == ' '){
                    string_with_line_breaks+='<br>';
                    i = 0;
                    continue;
                }

                i++;
            }

            return string_with_line_breaks;

        }

    },

    mounted(){
        this.uicontrol.show_search = (this.data.identifier == null) ? true : false;
        this.uicontrol.show_blockchain_ui = typeof this.data.id !== 'undefined';

        // Load this.data into this.result if
        if(this.uicontrol.show_blockchain_ui)
            this.result = this.data;

    },

    methods : {

        openResultHere(){


            axios.get('/dashboard/science/experiments/results/get-by-identifier', {
                params : {
                    identifier: this.data.identifier,
                }
            }).then(response => {
               this.result = response.data;
               this.uicontrol.show_experiment_result_popup = true;

            });

        },

        add(data){

            this.$emit('update_node', {
                index : this.index,
                data : {
                    title : data.title,
                    url : data.url,
                    identifier : data.identifier
                }
            });

            this.uicontrol.show_search = false;

        }
    },

    // Node specific properties
    readable_name: "Experiment Result",
    type : 'experiment_result',
    sample_data : {
        title : null,
        url : null,
        identifier : null,
        tags : []
    },
    show_in_menu : true
}
</script>

<style scoped>

</style>
