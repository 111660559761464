<template>

    <div class="row">

        <div class="col-8 offset-2 mt-5">

            <form action="">

                <h4 class="mb-4">Recipient information</h4>

                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label for="invitee_name" class="form-label font-weight-500">Name</label>
                            <input type="text" class="form-control" id="invitee_name" name="invitee_name" placeholder="Recipient name" v-validate="'required'" v-model="form.invitee_name">
                            <span class="text-danger">{{ errors.first('invitee_name') }}</span>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label for="invitee_email" class="form-label font-weight-500">Email</label>
                            <input type="text" class="form-control" id="invitee_email" name="invitee_email" placeholder="Recipient email" v-validate="'required|email'" v-model="form.invitee_email">
                            <span class="text-danger">{{ errors.first('invitee_email') }}</span>
                        </div>
                    </div>

                </div>

                <hr>

                <h4>Author of invitation (optional)</h4>

                <div class="row">
                    <div class="col-12 text-left">
                        <user_permissions
                            :users="real_authors"
                            :permission_name="'do_not_save'"
                            @added_user=""
                            :editable="true">
                        </user_permissions>
                    </div>
                </div>

                <hr>

                <div class="form-group" v-if="available_resources.length > 1">
                    <label for="email_confirmation" class="form-label font-weight-500">Select resource from list</label>
                    <select id="resource" name="resource" v-model="form.resource_id" class="form-control" v-validate="'required'">
                        <option v-for="resource in available_resources" v-bind:value="resource.id" v-html="resource.title" :selected="resource.id == form.resource_id"></option>
                    </select>
                    <span class="text-danger">{{ errors.first('template') }}</span>
                </div>

                <div class="mt-2 file-upload p-3" style="border: 1px solid #DDD" v-if="form.resource_id == 'create_my_own'">
                    <h5>Create my own resource</h5>

                    <div class="container pl-0 pr-0">
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="resource_title" class="form-label font-weight-500">Resource Title</label>
                                    <input type="text" class="form-control" id="resource_title" name="resource_title" placeholder="Title" v-validate="'required'" v-model="resource.title"></input>
                                    <span class="text-danger">{{ errors.first('title') }}</span>
                                </div>
                            </div>

                            <div class="col-6">
                                <div class="form-group">
                                    <label for="resource_description" class="form-label font-weight-500">Description</label>
                                    <input type="text" class="form-control" id="resource_description" name="resource_description" placeholder="Description" v-validate="'required'" v-model="resource.description"></input>
                                    <span class="text-danger">{{ errors.first('resource_description') }}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="files_wrapper" style="border: 1px dashed rgb(235, 236, 240); border-radius: 6px;">

                        <ul class="file_list pl-2 pr-2" v-if="file">
                            <li>
                                <file :key="'uploaded_file'" :file="file" :can_delete="true" @removed_file="removedFile($event)"></file>
                            </li>
                        </ul>

                        <div class="button-holder text-center mt-2" v-if="!file">
                            <file_upload :data="{}" @uploaded="uploadedFile($event)"></file_upload>
                        </div>

                    </div>

                    <div class="col-12 text-center mt-2">
                        <button class="btn btn-white" @click="createResource($event)">Create resource</button>
                    </div>

                </div>

                <div class="form-group">
                    <label for="cover_note" class="form-label font-weight-500">Add cover note</label>
                    <textarea class="form-control" id="cover_note" name="cover_note" placeholder="Cover note" v-validate="'required'" v-model="form.cover_note"></textarea>
                    <span class="text-danger">{{ errors.first('cover_note') }}</span>
                </div>

                <div class="form-group text-center">
                    <button :disabled="typeof form.resource_id !== 'number'" class="btn btn-primary btn-rounded" style="padding-right: 20px; padding-left: 20px;" @click="send($event)">Send</button>
                </div>

            </form>

        </div>

    </div>

</template>

<script>

import user_permissions from '@/components/sections/science/UserPermissions';
import file_upload from '@/components/sections/reusable/FileUpload';
import file from "@/components/sections/reusable/File";

export default {

    name: "ResourceViewInvitation",
    data: function () {
        return {
            form: {
                invitee_name: null,
                invitee_email : null,
                invitee_email_confirmation: null,
                resource_id : null,
                cover_note : null,
                from_user_id : null
            },
            resource : {
              title : null,
              description : null,
            },
            file : null,
            available_resources: [
                {
                    id: null,
                    title: 'Select...'
                },
                {
                    id: 'create_my_own',
                    title: 'Create my own resource'
                }

            ],
            real_authors : [],
            components: {
                user_permissions
            }
        }
    },

    mounted(){

        this.getResources();

        Vue.nextTick(() => {

            let dropArea = document.getElementById('drop_area');

            if(!Boolean(dropArea))
                return;

            ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
                dropArea.addEventListener(eventName, preventDefaults, false)
            })

            function preventDefaults (e) {
                e.preventDefault()
                e.stopPropagation()
            }

            dropArea.addEventListener('drop', handleDrop, false)

            function handleDrop(e) {
                let dt = e.dataTransfer
                let files = dt.files
                handleFiles(files)
            }

            function handleFiles(files) {
                ([...files]).forEach(uploadFile)
            }

            function uploadFile(file){
                vm.$emit('drop_file', file);
            }

        });
    },



    computed: {

        file_upload_button_text(){
            return (!Boolean(this.form.file)) ? 'Select file...' : this.form.file.name;
        }

    },

    components: {
        file_upload,
        file
    },

    methods: {

        createResource(event){

            event.preventDefault();

            axios.post('/resource', {
                title :  this.resource.title,
                description : this.resource.description,
                model : 'App\\Models\\File',
                model_id : this.file.id,
            }).then(response => {
                this.available_resources.push(response.data);
                this.form.resource_id = response.data.id;

                this.resource.title = null;
                this.resource.description = null;
                this.file = null;

            });


        },

        uploadedFile(file) {
            this.file = file;
        },

        removedFile(identifier) {
            this.file = null;
        },

        filePicked(data){
            this.form.file = data.target.files[0];
        },

        send(event){

            var vm = this;

            event.preventDefault();

            if(this.real_authors.length > 0)
                this.form.from_user_id = this.real_authors[0].id;

            this.$validator.validate().then(valid => {

                if (valid) {
                    axios.post('/storage/resource-view-invitation', vm.form).then(response => {
                        if(response.status == 200){

                            Swal.fire('Invitation sent', `You sent an invitation to ${vm.form.invitee_name}`, 'info');

                                vm.form.invitee_name = null;
                                vm.form.invitee_email = null;
                                vm.form.invitee_email_confirmation = null;
                                vm.form.resource_id = null;
                                vm.form.cover_note = null;

                        }
                    })
                }

            });

        },

        getResources() {
            axios.get('/resource/index').then(response => {
                this.available_resources.push(...response.data);
            })
        },

    }

}
</script>

<style scoped>

</style>
