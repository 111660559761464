<template>
    <div class="container">
        <div class="header"></div>
        <div class="content">
            <div class="row header_container">
                <div class="logo">
                    <img src="/assets/logo.svg" alt=""/>
                </div>
                <div class="search">
                    <input class="search-input" type="search" v-model="query" placeholder="Search..."/> <br/>
                </div>
            </div>
            <p v-show="loading">Loading...</p>
            <p v-show="!loading && (!jsonData || !jsonData.length)">No feed data available</p>
            <div class="element" v-for="(element, key) in jsonData" :key="key">
                <div class="index">{{ key + 1 }}.</div>
                <div class="c-element">
                    <a class="title" :href="element.url" target="_blank">{{ adjustTitle(element.title) }}</a>
                    <br />
                    <a class="link" :href="'//' + element.url">{{ extractHostname(element.url) }}</a>
                    <span class="pub-date" v-if="element.pub_date">
                        {{formatPubDate(element.pub_date)}}
                    </span>
                    <p class="content">{{ element.description }}</p>
                    <div class="keywords">
                        <span>Keywords:</span>
                        <span>{{element.keyword.replaceAll(/[\(\)]/g, '').replaceAll(/%20/g, ' ').replaceAll(/\+/g,', ')}}</span>
                    </div>
                </div>
            </div>
            <strong v-show="fetchedAllRecords && jsonData.length">END.</strong>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      jsonData: [],
      feedSources: [],
      dataNew: null,
      date: new Date().toISOString().slice(0, 10),
      query: "",
      loading: true,
      page: 1,
      fetchedAllRecords: false,
    };
  },
  watch: {
    query($new, $old) {
      if (!$new) {
        this.reset();
      }

      if ($new.length > 3 && $new !== $old) {
        this.fetchedAllRecords = false;
        this.jsonData = [];
        this.page = 1;
        this.search($new);
      }
    },
  },
  methods: {
    processData(data) {
      let newArr = [];
      for (let iteration of data) {
        let randomnumber = Math.floor(Math.random() * data.length);
        const links =
          iteration.search_results[randomnumber].engine_results.index;

        for (let result in links) {
          iteration.search_results[randomnumber].engine_results.index[
            result
          ].result.links = this.extractHostname(
            iteration.search_results[randomnumber].engine_results.index[result]
              .result.links
          );
        }
        newArr.push(iteration.search_results[randomnumber]);
      }
      return newArr;
    },

    groupBy(arr, prop) {
      const map = new Map(Array.from(arr, (obj) => [obj[prop], []]));
      arr.forEach((obj) => map.get(obj[prop]).push(obj));
      return Array.from(map.values());
    },

    extractHostname(url) {
      if (url == null) {
        return;
      }
      let hostname;
      //find & remove protocol (http, ftp, etc.) and get hostname

      if (url.indexOf("//") > -1) {
        hostname = url.split("/")[2];
      } else {
        hostname = url.split("/")[0];
      }

      //find & remove port number
      hostname = hostname.split(":")[0];
      //find & remove "?"
      hostname = hostname.split("?")[0];

      return hostname;
    },
    getMostRecent() {
      this.query = "";
      this.loading = true;
      return axios.get("/api/feed?mostRecent=1").then((response) => {
          if (!response.data.feed.length) {
              this.fetchedAllRecords = true;
          }
        this.jsonData = [
            ...this.jsonData,
            ...response.data.feed
        ];

        this.feedSources = response.data.feed_sources;
        this.loading = false;

        this.uniqueNews();
      });
    },

    uniqueNews() {

        if (!this.feedSources.length) {
            return;
        }

        const uniqueNews = {};

        try {
            this.jsonData.forEach((news) => {
                const domainSearch = /(?:https?\:\/\/)(?:www.)*(?=[\w\d])([\w\W]+)(?:\.)/g.exec(news.url);
                const sourceName = domainSearch[1];

                if (domainSearch && domainSearch[1]) {

                    let sourceIndex = this.feedSources.indexOf(sourceName);

                    if (sourceIndex === -1) {
                        sourceIndex = this.feedSources.length + 1;
                    }

                    if (!uniqueNews[news.title]) {
                        uniqueNews[news.title] = {
                            news,
                            sourceIndex,
                        };
                    }

                    if (uniqueNews[news.title].sourceIndex > sourceIndex) {
                        uniqueNews[news.title] = {
                            news,
                            sourceIndex,
                        }
                    }
                }

            });

        } catch (e) {
            console.warn('The feeds are not prioritized by the feed source');
            return;
        }

        this.jsonData = [];

        for (let newsTitle in uniqueNews) {
            const data = uniqueNews[newsTitle];
            this.jsonData.push(data.news);

        }

        this.loading = false;
    },

    search(keyword) {
        axios.get("/api/feed?query=" + keyword + "&page=" + this.page).then((response) => {

            if(!response.data.length) {
                this.fetchedAllRecords = true;
            }
          this.jsonData = [
              ...this.jsonData,
              ...response.data.feed,

          ];

            this.feedSources = response.data.feed_sources;
            this.uniqueNews();

          this.loading = false;
        });
        this.loading = true;
    },

    reset() {
        this.page = 1;
        this.fetchedAllRecords = false;
        this.jsonData = [];
        this.getMostRecent();
    },

    lazyLoading() {
      window.addEventListener('scroll', () => {

        if (this.fetchedAllRecords) {
            return;
        }
        const { innerHeight } = window;
        const { height, y} = document.body.getBoundingClientRect();
        const scrollableAreaOffset = Math.floor(height - innerHeight);
        if (y <= -scrollableAreaOffset && !this.loading) {
          this.page++;
          if (this.query) {
              this.search(this.query);
                return;
          }

          this.getMostRecent();
        }
      })
    },
    adjustTitle(title) {
        const allTitleWords = title.split(' ');

        const words = allTitleWords.filter((titleWord) => {
            return titleWord.length >= 3;
        });

        if (words.length > 10) {

            return words.splice(0, 10).join(' ') + '...';
        }

        return title;
    },

    formatPubDate(date) {
        const dateTime = new Date(date.replace(/\s/, 'T') + '.000Z');

        return dateTime.toLocaleString();
    }
  },

  async mounted() {
    this.getMostRecent();
  },
};
</script>
<style scoped>
.pub-date {
    color: #999;
    font-size: 12px;
    margin-bottom: 11px;
}
.header_container{
    display: flex;
    justify-content: space-between;
}

.keywords {
    color: #999;
}
.search {
    overflow: hidden;
    padding: 20px 0 0;
}
.search-input {
  width: 400px;
  padding: 7px 20px;
  margin: -12px 0 40px;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.09);
  border: 1px solid #adadad;
  font-size: 15px;
  float: right;
  font-weight: bold;
  max-width: 100%;
  outline: 0;
}

.reset {
    font-size: 15px;
    margin-left: 30px;
    color: #000000;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  margin: 0 10px;
  text-align: left;
}
a {
  color: #42b983;
}
.nav {
  margin-bottom: 31px;
}
.date {
  width: 100px;
  display: flex;
  align-items: center;
}
.date a {
  background: #6c757d;
  padding: 5px 4px;
  display: flex;
  border-radius: 3px 0 0 3px;
}
.date img {
  background: #6c757d;
  width: 19px;
}
.date span {
  background: #545b62;
  color: white;
  padding: 6px 14px;
  padding-top: 7px;
  font-size: 13px;
}
.container {
  max-width: 1200px;
  margin: 0 auto;
}
.title {
  text-align: left;
  font-size: 21px;
  margin-left: 6px;
  margin-bottom: 0;
  margin: 0;
  color: #0071ba;
  text-decoration: none;
}
.element {
  display: flex;
  border-radius: 5px;
  margin-bottom: 20px;
  max-width: 865px;
}
.index {
  margin-top: 3px;
  font-size: 18px;
  margin-right: 14px;
}
.link {
  margin: 0;
  text-align: left;
  color: #006837;
  margin-top: 1px;
  text-decoration: none;
}

.content {
  margin: 0;
  text-align: left;
  margin-top: 3px;
  color: black;
}
.logo {
  text-align: left;
}

.logo img {
  width: 182px;
  margin-top: 11px;
}

ul {
  margin-left: 20px;
  margin-top: 0;
}
.nod > span {
  padding-left: 15px;
}
.wtree li {
  list-style-type: none;
  margin: 10px 0 10px 10px;
  position: relative;
}
.wtree li:before {
  content: "";
  position: absolute;
  top: -10px;
  left: -20px;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  width: 20px;
  height: 15px;
}
.wtree li:after {
  position: absolute;
  content: "";
  top: 5px;
  left: -20px;
  border-left: 1px solid #ddd;
  border-top: 1px solid #ddd;
  width: 20px;
  height: 100%;
}
.wtree li:last-child:after {
  display: none;
}
.wtree li span {
  display: block;
  border: 1px solid #ddd;
  padding: 11px 10px;
  color: #888;
  text-decoration: none;
  position: relative;
  padding-left: 15px;
  border-radius: 6px;
  overflow: hidden;
}

.wtree li span .color {
  border: none;
  position: absolute;
  background: #b6d5e5;
  left: 0;
  top: -7px;
  bottom: -26px;
  padding: 0;
  width: 7px;
}

.wtree li span:hover,
.wtree li span:focus {
  background: #eee;
  color: #000;
  border: 1px solid #aaa;
}

.wtree li span:hover + ul li:after,
.wtree li span:hover + ul li:before,
.wtree li span:focus + ul li:after,
.wtree li span:focus + ul li:before {
  border-color: #aaa;
}
.wtree li.result-group {
  color: #000;
  border: 1px solid #ddd;
  border-radius: 6px;
}
.wtree li.result-group span {
  border: none;
  border-radius: 0px;
}
</style>
