<template>
	<div>
		<div class="container">

			<!-- Header -->

			<div class="row">

				<div class="col-lg-6 d-flex pt-4 mb-4 justify-content-between">

					<div>
						<div class="w-100 d-flex flex-column">
							<h3 class="title">Payment</h3>
							<div v-if="subject.title && subject.url">
								<p>For {{subject.entity_name}} : <a :href="subject.url">{{subject.title}}</a></p>
							</div>
						</div>

						<div class="status" style="margin-top: 3px;">
							Status:
							<span class="font-weight-500 text-primary" v-if="payment.status == 'not_paid'">Not Paid</span>
							<span class="font-weight-500 text-success" v-if="payment.status == 'paid'">Paid</span>
						</div>
					</div>

				</div>

				<div class="col-lg-6 mb-4">

					<div class="pb-2 d-lg-flex justify-content-end">

						<div class="text-right mr-2 mt-2">
							<h4 class="mb-0 text-grey">Participant</h4>
							<p class="small text-grey mb-0" v-if="is_author">Add one or more payers who can pay the invoice</p>
							<p class="small text-grey mb-0" v-if="is_participant">You can approve or reject the request</p>
						</div>

						<user_permissions
							:users="participants"
							:permission_name="'payment.participate.' + payment.id"
							:permission_prerequisite="'operations.payment.view'" @added_user="popupAddedUser($event)" :editable="editable && is_author" @open_search="$validator.validate()">
						</user_permissions>

					</div>

					<!-- Statuses-->
					<div class="d-lg-flex justify-content-end">

						<!-- Participant -->
						<div v-if="is_participant">
							<template v-if="['not_paid'].includes(payment.status)">
								<button class="btn btn-rounded btn-outline-success" @click="markAsPaid($event)">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
										<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
										<path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
									</svg>
									Mark as paid
								</button>
							</template>
						</div>

						<div v-if="is_author">
							<button class="btn btn-rounded btn-white" @click="save($event)" v-if="['not_paid'].includes(payment.status) && editable" :class="{ loading : uicontrol.saving, 'mt-4' : !ready_to_add_operators }">
								<div data-loader="circle-side"></div>
								Save
							</button>
						</div>

					</div>
				</div>
			</div>

			<div class="row g-5">
				<div class="col-12">
					<form class="needs-validation" novalidate>
						<div class="row g-3">
							<div class="col-sm-6">
								<div class="d-md-flex" style="width: 100%">
									<div class="form-group mr-2" style="width: 100%">
										<label for="title" class="form-label">Title</label>
										<input type="text" class="form-control" id="title" name="title" v-validate="'required'" v-model="payment.title" :disabled="!is_author || !editable">
										<span class="text-danger">{{ errors.first('title') }}</span>
									</div>
									<div class="form-group mr-2" style="min-width: 150px">
										<label for="urgency" class="form-label">Urgency</label>
										<select name="field" id="urgency" v-model="payment.urgency" class="form-control" :disabled="!is_author || !editable">
											<option v-for="urgency in available_urgency_options" v-bind:value="urgency.value" v-html="urgency.label" :selected="urgency.value == urgency.urgency"></option>
										</select>
									</div>
								</div>
								<div class="form-group">
									<label for="due_at">Due Date</label>
									<flat-pickr id="due_at" v-model="payment.due_at" :config="flatpickr_config" placeholder="Due Date" :disabled="!is_author || !editable"></flat-pickr>
								</div>
								<div class="form-group">

									<h4>Files</h4>

									<ul class="file_list">
										<li v-for="(file, fKey) in payment.data.files">
											<file :key="fKey" :file="file" :can_delete="editable && is_author" @removed_file="removedFile($event)"></file>
										</li>
									</ul>

									<file_upload v-if="editable && is_author" :data="{}" @uploaded="uploadedFile($event)"></file_upload>
								</div>
							</div>

							<div class="col-sm-6">
								<label for="description" class="form-label">Description</label>
								<textarea rows="4" type="text" class="form-control" name="description" id="description" required v-model="payment.description" :disabled="!is_author || !editable"></textarea>
								<div class="invalid-feedback">
									Valid description is required.
								</div>
							</div>
						</div>

					</form>
				</div>

			</div>

			<div class="row">
				<div class="col-12 mt-4">
					<h2>Message board</h2>
				</div>
				<div class="col-12">
					<channel :channel="'payment.' + payment.identifier" :channel_type="'private'" :commenting_enabled="true" :scroll_bottom="false"></channel>
				</div>
			</div>
		</div>

	</div>
</template>

<script>

import user_permissions from '@/components/sections/science/UserPermissions';
import file_upload from '@/components/sections/reusable/FileUpload';
import file from "@/components/sections/reusable/File";
import channel from '@/components/chat/Channel';
import Swal from "sweetalert2";
import available_currencies from '@/misc/available_currencies';

import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {

	name: "Invoice",

	data: function () {
		return {
			template: {
				"item": {
					"name": null,
					"description": null,
					"price_per_unit": null,
					"currency": null,
					"quantity": null,
					"vat": null,
				},
				"file": {}
			},
			flatpickr_config: {
				dateFormat: "Y-m-d H:i:ss",
				altInput: true,
				altFormat: "F j, Y"
			},
			new_item: {},
			uicontrol: {
				saving: false,
				show_item_form: false
			},
			available_currencies,
			available_vat_options: [
				{
					label: 'Select...',
					value: null
				},
				{
					label: 'No vat (0%)',
					value: 1
				},
				{
					label: '20% S (20%)',
					value: 1.2
				},
				{
					label: 'Exempt (0%)',
					value: 1
				},
			],
			available_urgency_options: [
				{
					label: 'Normal',
					value: 0
				},
				{
					label: 'Urgent',
					value: 1
				},
				{
					label: 'Critical',
					value: 2
				},
			],
			touched: false,
			ready_to_add_operators: false
		}

	},

	props: [
		'participants',
		'payment',
		'editable',
		'is_author',
		'is_participant',
		'subject'
	],

	components: {
		channel,
		file_upload,
		file,
		user_permissions,
		flatPickr
	},

	watch: {

		payment: {
			deep: true,
			handler() {
				this.touched = true;
			}
		}

	},

	mounted() {

		setTimeout(() => {
			this.touched = false;
		}, 500);

	},

	methods: {

		popupAddedUser(user) {
			Swal.fire('Participant added', `Your request has been sent to ${user.name} for approval`, 'info');
		},

		isSelected: function (currency) {
			return currency === null;
		},

		update() {
			this.$emit('update', this.payment);
			return this;
		},

		save() {


			var vm = this;

			vm.$validator.validate().then(valid => {

				if (valid) {

					// Fake saving
					vm.uicontrol.saving = true;
					setTimeout(function () {
						vm.uicontrol.saving = false;
					}, 500);

					vm.$emit('save', this.payment);

					vm.touched = false;

					return vm;

				}

			});

		},

		markAsPaid() {
			this.payment.status = 'paid';
			this.save();
		},

		uploadedFile(file) {
			this.payment.data.files.push(file);
			this.save();
		},

		removedFile(identifier) {
			let file_index = this.payment.data.files.findIndex(el => {
				return el.identifier == identifier;
			})
			this.payment.data.files.splice(file_index, 1);
			this.save();
		},

	}

}
</script>

<style scoped>

.btn-white {
	background-color: #FFFFFF;
	border: 1px solid #DFE1E6;
	box-sizing: border-box;
	box-shadow: 0px 2px 2px -1px rgba(13, 42, 64, 0.06);
	border-radius: 100px;
	color: #44526C;
	margin-left: 20px;
	outline: none;
	font-size: 14.4px;
}

.btn-white:hover {
	 background-color: #f7f7f7;
}

</style>
