<template>

	<div class="row">
		<div class="col-6">
			<div class="form-group" style="width: 100%;">
				<label :for="'production_date_' + iKey" class="form-label">Production date</label>
				<input :id="'production_date_' + iKey" v-model="start_date" :name="'production_date_' + iKey" class="d-none" type="text">
				<flat-pickr
					:id="'production_date_' + iKey"
					v-model="start_date"
					v-validate="'required'"
					:config="flatpickr_config_start"
					:disabled="disabled"
					:name="'production_date_' + iKey"
					class="fltpickr flatpickr_one"
					@on-change="startDateChanged($event)"
					v-if="uicontrol.show_start_date"
				>
				</flat-pickr>
				<span class="text-danger">{{ errors.first('production_date_' + iKey) }}</span>
			</div>
		</div>
		<div class="col-6">
			<div class="form-group" style="width: 100%;">
				<label :for="'expiry_date_' + iKey" class="form-label">Expiry Date</label>
				<input :id="'expiry_date_' + iKey" v-model="end_date" :name="'expiry_date_' + iKey" class="d-none" type="text">
				<flat-pickr
					v-model="end_date"
					v-validate="'required'"
					:config="flatpickr_config_end"
					:disabled="disabled"
					:name="'expiry_date_' + iKey"
					class="fltpickr flatpickr_one"
					v-if="uicontrol.show_end_date"
				>
				</flat-pickr>
				<span class="text-danger">{{ errors.first('expiry_date_' + iKey) }}</span>
			</div>
		</div>
	</div>

</template>

<script>

import flatPickr from 'vue-flatpickr-component';

export default {
	name: "DateRange",
	props: ['iKey', 'input_start_date', 'input_end_date', 'disabled'],

	components : {
		flatPickr
	},

	computed: {

		start_date: {

			get() {
				return this.input_start_date;
			},
			set(val) {
				this.$emit('update_start_date', val)
			}

		},

		end_date: {

			get() {
				return this.input_end_date;
			},
			set(val) {
				this.$emit('update_end_date', val)
			}

		}


	},

	data: function () {

		return {

			flatpickr_config_start: {
				dateFormat: "Y-m-d H:i:ss",
				altInput: true,
				altFormat: "F j, Y"
			},

			flatpickr_config_end: {
				dateFormat: "Y-m-d H:i:ss",
				altInput: true,
				altFormat: "F j, Y"
			},

			uicontrol : {
				show_start_date : true,
				show_end_date : true
			}

		}

	},

	methods : {

		startDateChanged(date){

			this.uicontrol.show_end_date = false;
			this.flatpickr_config_end.minDate = date[0];

			Vue.nextTick(() => {
				this.uicontrol.show_end_date = true;
			})
		}

	}
}

</script>

<style scoped>

</style>