(function() {

    const flatpickr = require('flatpickr');

    const setDefaultDate = function() {

        const { search } = window.location;

        if (search) {
            const searchParams = new URLSearchParams(search);

            return [
                new Date().setTime(searchParams.get('from')),
                new Date().setTime(searchParams.get('to'))
            ]
        }

        return []
    }

    const setRangeDates = function(event) {
        const { element } = this;
        console.log(this);

        if (element.parentNode) {
            try {
                console.log(event[0])
                element.parentNode.querySelector('.from').value = event[0].getTime();

                if (event[1]) {
                    element.parentNode.querySelector('.to').value = event[1].getTime();
                }
            } catch (error) {
                console.log('Make sure that .from and .to inputs are defined');
            }
        }
    }

    $('.flatpickr-range').flatpickr({
        mode: 'range',
        maxDate: new Date(),
        defaultDate: setDefaultDate(),
        onReady(event) {
            setRangeDates.call(this, event);
        },
        onChange(event) {
            setRangeDates.call(this, event);
        }
    });



    $('.flatpickr-altinput').flatpickr({
        dateFormat: "Y-m-d H:i:ss",
        altInput: true,
        altFormat: "F j, Y"
    });

}());
