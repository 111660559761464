<template>
    <div class="card">
        <img class="main-img" :src="imgUrl(post.data.image)" alt="">
        <div class="card-body">
            <button class="options edit-btn" @click="$emit('edit', index)"></button>

            <!-- <button class="btn btn-sm btn-filled-rounded delete" @click="$emit('delete_post', index)">Delete icon</button> -->

            <p class="small" v-text="DateTime.fromISO(post.created_at).toFormat('dd LLL yyyy')"></p>
            <h3 class="card-title" v-text="short_title"></h3>
            <span v-text="short_description" class="description"></span>
            <!-- <a :href="`/dashboard/communications/${post.category_slug}/${post.slug}`" class="btn btn-secondary btn-sm btn-white">Read</a> -->
        </div>
    </div>
</template>

<script>

    import { DateTime } from "luxon";

    export default {

        name: "QuarterWidthThumbnailTop",

        data(){

            return {

                DateTime : DateTime

            }

        },

        props : ['post', 'categorySlug', 'index'],

        computed : {

            short_title() {
               
                let desired_length = 30;

                return (this.post.data.title.length <= desired_length) ? this.post.data.title : this.post.data.title.substr(0, desired_length) + "..."; 
            },

            short_description(){

                let desired_length = 100;
                return (this.post.data.short_description.length <= desired_length) ? this.post.data.short_description : this.post.data.short_description.substr(0, desired_length) + "...";

            }

        },

        methods : {

            

            imgUrl(string){

                if(string == null)
                    return '';

                return string.includes('http') ? string : '/file/' + this.post.data.image;

            }

        }

    }

</script>

<style scoped>

</style>
