<template>
	<div v-if="uicontrol.ready" class="container">
		<div class="card">

			<div class="container">

				<div class="row">

					<div class="col-lg-6 d-flex pt-4 mb-4">

						<div>
							<h3 class="title">Product Order</h3>
							<span class="small text-grey">
                                <strong>
                                    Author:
                                </strong>
                                {{ product_order.author.name }}
                            </span>
						</div>

						<div class="status" style="margin-top: 3px;">

                            <span v-if="product_order.status === 'pending'" class="ml-2 font-weight-500 text-primary">
                                Pending
                            </span>

							<span v-if="product_order.status === 'in_progress'" class="ml-2 font-weight-500 text-dark">
                                In progress
                            </span>

							<span v-if="product_order.status === 'completed'" class="ml-2 font-weight-500 text-success">
                                Completed
                            </span>

							<span v-if="product_order.status === 'cancelled'" class="ml-2 font-weight-500 text-danger">
                                Cancelled
                            </span>

						</div>

					</div>


					<div class="col-lg-6 mb-4" >

						<div class="pb-2 d-lg-flex justify-content-end" v-if="ready_to_add_operators">

							<div class="text-right mr-2 mt-2">
								<h4 class="mb-0 text-grey">Operators</h4>
								<p v-if="is_author && !operators.length" class="small text-grey mb-0">You need an
									operator to handle this order</p>
								<p v-if="is_author && operators.length" class="small text-grey mb-0">The selected
									operator(s) will handle this order</p>

								<p v-if="is_operator" class="small text-grey mb-0">You have been assigned to handle this
									order</p>
							</div>

							<user_permissions
								:editable="editable"
								:permission_name="'product_order.operator.' + product_order.id"
								:permission_prerequisite="null"
								:users="operators"
								@added_user="popupAddedUser($event)"
								@open_search="$validator.validate()">
							</user_permissions>

						</div>

						<!-- Statuses-->
						<div class="d-lg-flex justify-content-end" :class="{ 'mt-5' : !ready_to_add_operators }">

							<!-- Author -->
							<div v-if="is_author">
								<button v-if="['pending', 'in_progress'].includes(product_order.status)"
									:class="{ loading : uicontrol.saving }"
									class="btn btn-rounded btn-white"
									@click="validateAndSave($event)">

									<span data-loader="circle-side"></span>
									Save
								</button>
							</div>

							<!-- Participant -->
							<div v-if="is_operator">
								<template v-if="['pending'].includes(product_order.status)">
									<button class="btn btn-rounded btn-outline-success" @click="setStatus($event, 'in_progress')">
										<svg class="bi bi-check-circle" fill="currentColor" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
											<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
											<path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
										</svg>
										Production Started
									</button>
									<button class="btn btn-rounded btn-outline-danger" @click="setStatus($event, 'cancelled')">
										<svg class="bi bi-x-circle" fill="currentColor" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
											<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
											<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
										</svg>
										Production Cancelled
									</button>
								</template>

								<template v-if="['in_progress'].includes(product_order.status)">
									<button class="btn btn-rounded btn-outline-danger" @click="setStatus($event, 'stopped')">
										<svg class="bi bi-x-circle" fill="currentColor" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
											<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
											<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
										</svg>
										Production Stopped
									</button>
								</template>
							</div>
						</div>
					</div>
				</div>

				<template v-if="in_progress && product_order.price_per_unit <= 0">
					<h4 class="text-info">Production started</h4>
					<span class="text-danger fullwidth d-block ml-1 mb-3 text-small">*Product order cannot be completed until the Author specifies a valid 'Price per unit'</span>
				</template>

				<div v-if="(in_progress || completed) && product_order.price_per_unit > 0" class="row g-5 mb-5">
					<div class="col-12">
						<h5 v-if="is_operator">Production details</h5>
						<p  v-if="is_operator">Enter the products being produced for this order</p>
						<div class="fullwidth"></div>
						<form class="needs-validation" novalidate>
							<div v-for="(item, iKey) in product_order.data.production_items" class="row g-3" v-if="is_operator || is_author && productionItemValid(item)">

								<div class="col-6">

									<date_range
										:iKey="iKey"
										:input_start_date="item.production_date"
										@update_start_date="item.production_date = $event"
										:input_end_date="item.expiry_date"
										@update_end_date="item.expiry_date = $event"
										:disabled="!is_operator || completed"
									>
									</date_range>

								</div>

								<div class="col-3">
									<div class="form-group" style="width: 100%;">
										<label :for="'batch_' + iKey" class="form-label">Batch</label>
										<input :id="'batch_' + iKey" v-model="item.batch" v-validate="'required'" :disabled="!is_operator || completed" :name="'batch_' + iKey" class="form-control" placeholder="" type="text">
										<span class="text-danger">{{ errors.first('batch_' + iKey) }}</span>
									</div>
								</div>
								<div class="col-2">
									<div class="form-group" style="width: 100%;">

										<label :for="'amount_' + iKey" class="form-label">Amount</label>

										<float_input
											:id="'amount_' + iKey"
											:name="'amount_' + iKey"
											:val="item.amount" @update="item.amount = $event"
											:decimals="0"
											:disabled="!is_operator || completed">
										</float_input>

										<span class="text-danger">{{ errors.first('amount_' + iKey) }}</span>

									</div>
								</div>



								<div class="col-1">
									<button v-if="is_operator && !completed" class="btn btn-outline-danger" style="margin-top: 31px;" @click="deleteProductionItem($event, iKey)">
										<svg class="bi bi-trash" fill="currentColor" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
											<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
											<path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z" fill-rule="evenodd"/>
										</svg>
									</button>
									<button v-else-if="completed" class="btn btn-outline-success disabled" disabled style="margin-top: 31px;" @click="deleteProductionItem($event, iKey)">
										<svg class="bi bi-check-circle-fill" fill="currentColor" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
											<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
										</svg>
									</button>
								</div>
							</div>
						</form>
					</div>

					<div class="col-12 mb-4 d-flex justify-content-between" v-if="is_operator">
						<p v-if="!product_order.data.production_items" class="mr-2 mt-1 mb-0 text-primary">Add at least
							one item to the production list before you can complete the order</p>
						<button v-if="!completed" :disabled="!is_operator" class="btn btn-sm btn-rounded btn-outline-primary" @click="addProductionItem()">
							<svg class="bi bi-plus-circle" fill="currentColor" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
								<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
								<path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
							</svg>
							Add item
						</button>
					</div>
					<button
						v-if="!completed && is_operator" :disabled="!can_be_completed"
						class="btn btn-rounded" @click="setStatus($event, 'completed')"
						:class="{ 'btn-outline-secondary' : !can_be_completed, 'btn-success' : can_be_completed, 'text-white' : can_be_completed }"
					>




						<svg class="bi bi-check-circle" fill="currentColor" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
							<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
							<path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
						</svg>

						Complete order: {{ number_en(production_amount_total, 0) }} / {{ number_en(product_order.amount, 0) }}

					</button>

					<div class="col-12"></div>

				</div>

				<div class="row g-5">
					<div class="col-12">
						<h5>Order details</h5>
						<form class="needs-validation" novalidate>
							<div class="row g-3">

								<div class="col-12">
									<div class="d-md-flex" style="width: 100%">
										<div class="form-group mr-2" style="width: 100%">
											<label class="form-label" for="title">Title</label>
											<input id="title" v-model="product_order.title" v-validate="'required'" :disabled="!is_author || !editable" class="form-control" name="title" placeholder="" type="text">
											<span class="text-danger">{{ errors.first('title') }}</span>
										</div>
									</div>
								</div>

								<div class="col-4 pr-0">
									<div v-if="uicontrol.products.ready" class="form-group">
										<label class="form-label" for="title">Product</label>
										<select id="template" v-model="product_order.product_id" v-validate="'required'" :disabled="!is_author || !editable" class="form-control" name="template">
											<option v-for="product in available_products" :selected="product.id === product_order.product_id" v-bind:value="product.id" v-html="product.name"></option>
										</select> <span class="text-danger">{{ errors.first('template') }}</span>
									</div>
								</div>

								<div class="col-2">
									<div class="form-group" style="width: 100%;">
										<label class="form-label" :for="'amount'">Amount</label>

										<float_input
											:id="'amount'"
											:name="'amount'"
											:val="product_order.amount"
											@update="product_order.amount = $event"
											:decimals="0"
											:disabled="!is_author || !editable">
										</float_input>

									</div>
								</div>

								<div class="col-2 pl-0">
									<div class="form-group" style="width: 100%;">
										<label class="form-label" :for="'price_per_unit'">Price per unit</label>

										<float_input
											:id="'price_per_unit'"
											:name="'price_per_unit'"
											:val="product_order.price_per_unit" @update="product_order.price_per_unit = $event"
											:decimals="2"
											:disabled="!is_author || (is_author && !editable && product_order.price_per_unit > 0 && !touched)">
										</float_input>

									</div>
								</div>

								<div class="col-2 pl-0">
									<div class="form-group pr-2 pl-lg-2">
										<label class="form-label" for="currency">Currency</label>
										<select id="currency" v-model="product_order.currency" v-validate="'required'" class="form-control" name="currency" :disabled="!is_author || !editable">
											<option v-for="currency in available_currencies" :selected="currency.value === product_order.currency" v-bind:value="currency.value" v-html="currency.label"></option>
										</select>
										<span class="text-danger">{{ errors.first('currency') }}</span>
									</div>
								</div>

								<div class="col-2  pl-0">
									<div class="form-group" style="width: 100%;">
										<label class="form-label" for="urgency">Urgency</label>
										<select id="urgency" v-model="product_order.urgency" :disabled="!is_author || !editable" class="form-control" name="urgency">
											<option v-for="urgency in available_urgency_options" :selected="urgency.value === urgency.urgency" v-bind:value="urgency.value" v-html="urgency.label"></option>
										</select>
									</div>
								</div>

								<div class="col-12">
									<div class="form-group" style="width: 100%;">
										<label class="form-label" for="description">Description</label>
										<textarea id="description" v-model="product_order.description" :disabled="!is_author || !editable" class="form-control" name="description" required rows="4" type="text"></textarea>
										<div class="invalid-feedback">
											Valid description is required.
										</div>
									</div>
								</div>

							</div>

						</form>
					</div>
				</div>

				<div class="row">
					<div class="col-12 mt-4">
						<h2>Message board</h2>
					</div>
					<div class="col-12">
						<channel :channel="'product_order.' + product_order.identifier" :channel_type="'private'" :commenting_enabled="true" :scroll_bottom="false"></channel>
					</div>
				</div>
			</div>

		</div>
	</div>

</template>

<script>

import file_upload from '@/components/sections/reusable/FileUpload';
import file from "@/components/sections/reusable/File";
import flatPickr from 'vue-flatpickr-component';
import channel from '@/components/chat/Channel';
import Swal from "sweetalert2";
import available_currencies from '@/misc/available_currencies';
import {number_en} from '@/misc/number_utilities';
import date_range from '@/misc/DateRange.vue';

export default {

	name: "ProductOrder",

	data: function () {
		return {
			product_order: null,
			uicontrol: {
				ready: false,
				saving: false,
				products: {
					ready: false
				},
				form_valid: false
			},
			available_urgency_options: [
				{
					label: 'Normal',
					value: 0
				},
				{
					label: 'Urgent',
					value: 1
				},
				{
					label: 'Critical',
					value: 2
				},
			],
			available_currencies,
			available_products: [
				{
					id: null,
					name: 'Select product...'
				}
			],
			production_item_template: {
				batch: null,
				amount: 0,
				production_date: null,
				expiry_date: null
			},
			operators: [],
			touched : false,
			ready_to_add_operators : false,
			number_en
		}
	},

	props: [
		'is_author',
		'is_operator',
	],

	components: {
		file_upload,
		file,
		channel,
		flatPickr,
		date_range
	},


	computed: {

		can_be_completed(){
			return !(!this.has_production_details || this.production_amount_total !== this.product_order.amount || this.product_order.price_per_unit <= 0);
		},

		editable() {
			return this.is_author && this.product_order.status === 'pending';
		},

		in_progress() {
			return this.product_order.status === 'in_progress'
		},

		completed() {
			return this.product_order.status === 'completed'
		},

		has_production_details() {
			return Boolean(this.product_order.data.production_items.length);
		},

		production_amount_total() {

			return this.product_order.data.production_items.length === 0 ? 0 : this.product_order.data.production_items.map(item => {
				return item.amount;
			}).reduce((prev, next) => {
				return parseInt(prev) + parseInt(next);
			});

		}


	},

	watch : {

		product_order: {
			deep: true,
			handler(){
				this.touched = true;
			}
		}

	},

	mounted() {

		var vm = this;

		vm.product_order = product_order;

		vm.operators = operators;
		vm.uicontrol.ready = true;
		vm.getProducts();

		if (typeof vm.product_order.data.production_items == 'undefined') {
			vm.product_order.data = {
				files: [],
				production_items: []
			}

			vm.addProductionItem();
			vm.save();
		}

		setTimeout(() => {
			vm.touched = false;
			vm.evaluateReadyToAddOperators();
		}, 500);

	},

	methods: {

		evaluateReadyToAddOperators(){
			this.ready_to_add_operators = Boolean(this.product_order.product_id) && this.product_order.amount && Boolean(this.product_order.currency);
		},

		productionItemValid(item){
			return item.batch && item.amount > 0 && item.production_date && item.expiry_date;
		},

		validateForm() {


			this.$validator.validate().then(valid => {

				console.log('Is not valid');

				if (!valid) return;

				console.log('Is Valid');

			});

		},

		save() {

			var vm = this;
			vm.uicontrol.saving = true;

			axios.put('/dashboard/product/order/' + vm.product_order.identifier, vm.product_order).then(response => {
				console.log(response);
				vm.uicontrol.saving = false;
				vm.touched = false;
				vm.evaluateReadyToAddOperators();
			});

		},

		validateAndSave() {

			this.$validator.validate().then(valid => {

				if (!valid) return;
				this.save();

			});

		},

		popupAddedUser(user) {
			Swal.fire('Operator added', `The order has been sent to ${user.name} to be processed`, 'info');
		},

		getProducts() {

			return axios.get('/dashboard/product/get', {
				params: {
					offset: 0,
					limit: 100000
				}
			}).then(response => {
				this.available_products.push(...response.data.results);
				this.uicontrol.products.ready = true;
			});

		},

		setStatus(event, status) {

			event.preventDefault();

			this.$validator.validate().then(valid => {

				if (!valid) return;
				this.product_order.status = status;
				this.save();

			});

		},

		addProductionItem() {
			this.product_order.data.production_items.push(Object.assign({}, this.production_item_template));
		},

		deleteProductionItem(event, iKey) {
			event.preventDefault();
			this.product_order.data.production_items.splice(iKey, 1);
		}

	}

}


</script>

<style scoped>

</style>
