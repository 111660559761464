<template>
	<div>
		<div class="container">

			<!-- Header -->

			<div class="row">

				<div class="col-lg-6 d-flex pt-4">

					<div class="w-100 d-flex flex-column">
						<h3 class="title">Purchase Request</h3>
						<div v-if="subject.title && subject.url">
							<p>For {{subject.entity_name}} : <a :href="subject.url">{{subject.title}}</a></p>
						</div>
					</div>

					<div class="status" style="margin-top: 3px;">
                        <span class="ml-2 font-weight-500 text-primary" v-if="approval_request.status == 'active'">
                            Active
                        </span>

						<span class="ml-2 font-weight-500 text-success" v-if="approval_request.status == 'approved'">
                            Approved
                        </span>

						<span class="ml-2 font-weight-500 text-danger" v-if="approval_request.status == 'rejected'">
                            Rejected
                        </span>

						<span class="ml-2 font-weight-500 text-secondary" v-if="approval_request.status == 'completed'">
                            Completed
                        </span>

						<span class="ml-2 font-weight-500 text-secondary" v-if="approval_request.status == 'closed'">
                            Closed
                        </span>
					</div>
				</div>

				<div class="col-lg-6">

					<div class="pb-2 d-lg-flex justify-content-end" v-if="ready_to_add_operators">

						<div class="text-right mr-2 mt-2">
							<h4 class="mb-0 text-grey">Participant</h4>
							<p class="small text-grey mb-0" v-if="is_author">You need a participant to approve your
								request</p>
							<p class="small text-grey mb-0" v-if="is_participant">You can approve or reject the
								request</p>
						</div>

						<user_permissions :users="participants" :permission_name="'approval_request.participate.' + approval_request.id" :permission_prerequisite="'operations.approval_request.purchase.view'" @added_user="popupAddedUser($event)" :editable="editable && is_author" @open_search="$validator.validate()"></user_permissions>

					</div>

					<!-- Statuses-->
					<div class="d-lg-flex justify-content-end">

						<!-- Author -->
						<div v-if="is_author">
							<button class="btn btn-rounded btn-white" @click="save($event)" v-if="['active','approved'].includes(approval_request.status) && editable" :class="{ loading : uicontrol.saving, 'mt-4' : !ready_to_add_operators }">
								<div data-loader="circle-side"></div>
								Save
							</button>
							<button class="btn btn-rounded btn-success" @click="completeRequest($event)" v-if="approval_request.status == 'approved'">
								Mark Complete
							</button>
							<button class="btn btn-rounded btn-warning" @click="reopenRequest($event)" v-if="approval_request.status == 'rejected'">
								Reopen request
							</button>
							<button class="btn btn-rounded btn-danger" @click="closeRequest($event)" v-if="approval_request.status == 'rejected'">
								Close request
							</button>
						</div>

						<!-- Participant -->
						<div v-if="is_participant">
							<template v-if="['active'].includes(approval_request.status)">
								<button class="btn btn-rounded btn-outline-success" @click="approveRequest($event)">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
										<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
										<path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
									</svg>
									Approve
								</button>
								<button class="btn btn-rounded btn-outline-danger" @click="rejectRequest($event)">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
										<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
										<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
									</svg>
									Reject
								</button>
							</template>
						</div>
					</div>
				</div>
			</div>

			<div class="row" v-if="is_author">
				<div class="col-12 mb-5">

					<div v-if="approval_request.payments.length" v-for="payment in approval_request.payments">
						Payment -
						<span class="text-capitalize font-weight-500">{{ (payment.status.charAt(0).toUpperCase() + payment.status.slice(1)).replaceAll('_', ' ') }}</span> :
						<a :href="'/dashboard/payments/' + payment.identifier"> <strong> {{ payment.title }} </strong> </a>
					</div>

					<div v-if="!approval_request.payments.length">No payments</div>

					<div class="mt-2" v-if="['approved'].includes(approval_request.status) && editable && !approval_request.payments.length">
						<a class="btn btn-rounded btn-white ml-0" :href="'/dashboard/payments/store-for-approval-request/' + approval_request.identifier">
							+ Invoice Payment
						</a>
					</div>

				</div>
			</div>

			<div class="row g-5">
				<div class="col-12">
					<form class="needs-validation" novalidate>
						<div class="row g-3">
							<div class="col-sm-6">
								<div class="d-md-flex" style="width: 100%">
									<div class="form-group mr-2" style="width: 100%">
										<label for="title" class="form-label">Title</label>
										<input type="text" class="form-control" id="title" name="title" v-validate="'required'" v-model="approval_request.title" :disabled="!is_author || !editable">
										<span class="text-danger">{{ errors.first('title') }}</span>
									</div>
									<div class="form-group">
										<label for="urgency" class="form-label">Urgency</label>
										<select name="field" id="urgency" v-model="approval_request.urgency" class="form-control" :disabled="!is_author || !editable">
											<option v-for="urgency in available_urgency_options" v-bind:value="urgency.value" v-html="urgency.label" :selected="urgency.value == urgency.urgency"></option>
										</select>
									</div>
								</div>
								<div class="form-group">
									<label for="">Due Date</label>
									<flat-pickr v-model="approval_request.due_at" :config="flatpickr_config" placeholder="Due Date" :disabled="!is_author || !editable"></flat-pickr>
								</div>
								<div class="form-group">

									<h4>Files</h4>

									<ul class="file_list">
										<li v-for="(file, fKey) in approval_request.data.files">
											<file :key="fKey" :file="file" :can_delete="editable && is_author" @removed_file="removedFile($event)"></file>
										</li>
									</ul>

									<file_upload v-if="editable && is_author" :data="{}" @uploaded="uploadedFile($event)"></file_upload>
								</div>
							</div>

							<div class="col-sm-6">
								<label for="description" class="form-label">Description</label>
								<textarea rows="4" type="text" class="form-control" name="description" id="description" required v-model="approval_request.description" :disabled="!is_author || !editable"></textarea>
								<div class="invalid-feedback">
									Valid description is required.
								</div>
							</div>
						</div>

					</form>
				</div>
				<div class="col-12 mt-2">
					<h2>Items</h2>
				</div>


				<!-- Existing items-->
				<div class="col-12 items-columns-container-header justify-content-between mt-2">

					<div class="form-group pr-2">
						<span class="font-weight-500">Name</span>
					</div>
					<div class="form-group">
						<span class="font-weight-500">Description</span>
					</div>
					<div class="form-group">
						<span class="font-weight-500">Unit price</span>
					</div>
					<div class="form-group">
						<span class="font-weight-500">Currency</span>
					</div>
					<div class="form-group">
						<span class="font-weight-500">Quantity</span>
					</div>
					<div class="form-group">
						<span class="font-weight-500">Tax</span>
					</div>
					<div class="form-group">
						<span class="font-weight-500">Total price</span>
					</div>
					<div class="form-group">
						<div style="width: 42px;">

						</div>
					</div>

				</div>

				<div class="col-12 items-columns-container-header  justify-content-between mt-2" v-for="(item, iKey) in approval_request.data.items">

					<div class="form-group pr-2">
						{{ item.name }}
					</div>

					<div class="form-group pr-2 pl-lg-2">
						{{ item.description }}
					</div>

					<div class="form-group pr-2 pl-lg-2">
						{{ item.price_per_unit }}
					</div>

					<div class="form-group pr-2 pl-lg-2">
						<span v-html="available_currencies.find(available_currency => {
								return available_currency.value == item.currency
							}).label"></span>
					</div>

					<div class="form-group pr-2 pl-lg-2">
						{{ item.quantity }}
					</div>

					<div class="form-group pr-2 pl-lg-2">

						<span v-html="available_vat_options.find(available_vat_option => {
								return available_vat_option.value == item.vat
							}).label"></span>

						<!--<select name="field" id="tax" v-model="item.vat" class="form-control" :disabled="!is_author || !editable">-->
						<!--	<option v-for="vat in available_vat_options" v-bind:value="vat.value" v-html="vat.label" :selected="vat.value == item.currency"></option>-->
						<!--</select>-->
					</div>

					<div class="form-group pl-lg-2">
						{{ item.price_per_unit * item.quantity * item.vat }}
					</div>

					<hr class="d-block d-lg-none">

					<div class="form-group pl-lg-2">
						<button class="btn btn-outline-danger" @click="deleteItem(iKey)" v-if="is_author && editable">
							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
								<path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
								<path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
							</svg>
						</button>
					</div>
				</div>


				<!-- New item form -->
				<template v-if="uicontrol.show_item_form">

					<div class="col-12 items-columns-container mt-2" id="item_form">

						<div class="form-group pr-2">
							<input type="text" class="form-control" placeholder="Item name" v-model="new_item.name" :disabled="!is_author || !editable">
						</div>

						<div class="form-group pr-2 pl-lg-2">
							<input type="text" class="form-control" placeholder="Description" v-model="new_item.description" :disabled="!is_author || !editable">
						</div>

						<div class="form-group pr-2 pl-lg-2">
							<float_input :id="'price_per_unit'" :name="'price_per_unit'" :val="new_item.price_per_unit" @update="new_item.price_per_unit = $event" :decimals="2" :disabled="!is_author || !editable"></float_input>
						</div>

						<div class="form-group pr-2 pl-lg-2">
							<select name="field" id="currency" v-model="new_item.currency" class="form-control" :disabled="!is_author || !editable">
								<option v-for="currency in available_currencies" v-bind:value="currency.value" v-html="currency.label" :selected="currency.value == new_item.currency"></option>
							</select>
						</div>

						<div class="form-group pr-2 pl-lg-2">
							<input type="number" class="form-control" placeholder="Quantity" v-model="new_item.quantity" :disabled="!is_author || !editable">
						</div>

						<div class="form-group pr-2 pl-lg-2">
							<select name="field" id="tax" v-model="new_item.vat" class="form-control" :disabled="!is_author || !editable">
								<option v-for="vat in available_vat_options" v-bind:value="vat.value" v-html="vat.label" :selected="vat.value == new_item.currency"></option>
							</select>
						</div>

						<div class="form-group pl-lg-2">
							<input type="number" class="form-control" placeholder="Total price" disabled v-model="new_item.price_per_unit * new_item.quantity * new_item.vat">
						</div>

						<div class="form-group pl-lg-2">
							<div style="width: 42px;">

							</div>
						</div>

					</div>

				</template>


			</div>

			<div class="row">
				<div class="col-12 mt-4 text-center mb-5" v-if="!uicontrol.show_item_form">
					<button class="btn btn-rounded btn-primary" @click="newItem()" v-if="is_author && editable">
						New item
					</button>
				</div>
				<div class="col-12 mt-4 text-center mb-5" v-else="">
					<button class="btn btn-rounded btn-secondary" @click="() => { uicontrol.show_item_form = false; new_item = {}; }">
						Cancel
					</button>
					<button class="btn btn-rounded btn-primary" @click="addNewItem()" :disabled="!new_item_valid">
						Save new item
					</button>
				</div>
			</div>

			<div class="row">
				<div class="col-12">

				</div>
			</div>
			<div class="row">
				<div class="col-12 mt-4">
					<h2>Message board</h2>
				</div>
				<div class="col-12">
					<channel :channel="'approval_request.' + approval_request.identifier" :channel_type="'private'" :commenting_enabled="true" :scroll_bottom="false"></channel>
				</div>
			</div>
		</div>

	</div>
</template>

<script>

import user_permissions from '@/components/sections/science/UserPermissions';
import file_upload from '@/components/sections/reusable/FileUpload';
import file from "@/components/sections/reusable/File";
import channel from '@/components/chat/Channel';
import Swal from "sweetalert2";
import available_currencies from '@/misc/available_currencies';

import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {

	name: "Purchase",

	data: function () {
		return {
			template: {
				"item": {
					"name": null,
					"description": null,
					"price_per_unit": null,
					"currency": null,
					"quantity": null,
					"vat": null,
				},
				"file": {}
			},
			flatpickr_config: {
				dateFormat: "Y-m-d H:i:ss",
				altInput: true,
				altFormat: "F j, Y"
			},
			new_item: {},
			uicontrol: {
				saving: false,
				show_item_form: false
			},
			available_currencies,
			available_vat_options: [
				{
					label: 'Select...',
					value: null
				},
				{
					label: 'No vat (0%)',
					value: 1
				},
				{
					label: '20% S (20%)',
					value: 1.2
				},
				{
					label: 'Exempt (0%)',
					value: 1
				},
			],
			available_urgency_options: [
				{
					label: 'Normal',
					value: 0
				},
				{
					label: 'Urgent',
					value: 1
				},
				{
					label: 'Critical',
					value: 2
				},
			],
			touched: false,
			ready_to_add_operators: false
		}

	},

	props: [
		'participants',
		'approval_request',
		'editable',
		'is_author',
		'is_participant',
		'subject'
	],

	components: {
		channel,
		file_upload,
		file,
		user_permissions,
		flatPickr
	},

	watch: {

		approval_request: {
			deep: true,
			handler() {
				this.touched = true;
			}
		}

	},

	computed: {

		items_valid() {

			if (!this.approval_request.data.items.length)
				return false;

			let valid = true;

			this.approval_request.data.items.forEach(item => {
				if (item.currency == null || item.name == null || item.price_per_unit == null || item.quantity == null || item.vat == null)
					valid = false;
			});

			return valid;

		},

		new_item_valid(){

			let valid = true;

			if (this.new_item.currency == null || this.new_item.name == null || this.new_item.price_per_unit == null || this.new_item.quantity == null || this.new_item.vat == null)
				valid = false;

			return valid;

		}

	},

	mounted() {

		var vm = this;

		if (typeof this.approval_request.data.items == 'undefined') {

			this.approval_request.data = {
				items: [],
				files: []
			}

			this.save();

		}

		setTimeout(() => {
			vm.touched = false;
			vm.evaluateReadyToAddOperators();
		}, 500);

	},

	methods: {

		evaluateReadyToAddOperators() {
			this.ready_to_add_operators = Boolean(this.approval_request.title.length) && this.items_valid || this.participants.length;
		},

		popupAddedUser(user) {
			Swal.fire('Participant added', `Your request has been sent to ${user.name} for approval`, 'info');
		},

		isSelected: function (currency) {
			return currency === null;
		},

		deleteItem(iKey) {
			this.approval_request.data.items.splice(iKey, 1);
			this.evaluateReadyToAddOperators();
			this.update();
		},

		newItem(){

			let new_item = Object.assign({}, this.template.item);
			this.new_item = new_item;
			this.uicontrol.show_item_form = true;

		},

		addNewItem(){
			this.approval_request.data.items.push(Object.assign({}, this.new_item));

			this.new_item = Object.assign({}, this.template.item);
			this.uicontrol.show_item_form = false;

			this.evaluateReadyToAddOperators();
			return this;
		},

		update() {
			this.$emit('update', this.approval_request);
			return this;
		},

		save() {


			var vm = this;

			vm.$validator.validate().then(valid => {

				if (valid) {

					// Fake saving
					vm.uicontrol.saving = true;
					setTimeout(function () {
						vm.uicontrol.saving = false;
					}, 500);

					vm.$emit('save', this.approval_request);

					vm.touched = false;
					vm.evaluateReadyToAddOperators();

					return vm;

				}

			});

		},

		approveRequest() {
			this.approval_request.status = 'approved';
			this.save();
		},

		rejectRequest() {
			this.approval_request.status = 'rejected';
			this.save();
		},

		completeRequest() {
			this.approval_request.status = 'completed';
			this.$emit('set_editable', false);
			this.save();
		},

		closeRequest() {
			this.approval_request.status = 'closed';
			this.save();
		},

		reopenRequest() {
			this.approval_request.status = 'active';
			this.$emit('set_editable', true);
			this.save();
		},

		uploadedFile(file) {
			this.approval_request.data.files.push(file);
			this.save();
		},

		removedFile(identifier) {
			let file_index = this.approval_request.data.files.findIndex(el => {
				return el.identifier == identifier;
			})
			this.approval_request.data.files.splice(file_index, 1);
			this.save();
		},

	}

}
</script>

<style scoped>

.btn-white {
	background-color: #FFFFFF;
	border: 1px solid #DFE1E6;
	box-sizing: border-box;
	box-shadow: 0px 2px 2px -1px rgba(13, 42, 64, 0.06);
	border-radius: 100px;
	color: #44526C;
	margin-left: 20px;
	outline: none;
	font-size: 14.4px;
}

.btn-white:hover {
	 background-color: #f7f7f7;
}

</style>
