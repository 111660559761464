/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import Swal from 'sweetalert2'
window.Swal = Swal;

require('slick-carousel');

window.Vue = require('vue');

// Do not show "you are running vue in development mode"
Vue.config.productionTip = false;

import CKEditor from '@ckeditor/ckeditor5-vue2';

window.Vue.use( CKEditor );

import VeeValidate, { Validator } from 'vee-validate';
import en from 'vee-validate/dist/locale/en';

en.attributes = {
    recipient_email : 'recipient email',
    recipient_name : 'recipient name',
    recipient_telephone : 'recipient telephone',
    recipient_company : 'recipient company',
    recipient_address_1 : 'recipient address',
    recipient_address_2 : 'recipient address 2',
    recipient_state : 'recipient state',
    recipient_province : 'recipient province',
    recipient_country : 'recipient country',
    recipient_zip : 'recipient zip',
    company_name : 'company name'
};

import { add } from 'lodash';

import Vue from 'vue';

Vue.use(VeeValidate, {
    classes: true,
    events: 'change',
    locale: 'en',
    dictionary: {
        en
    }
});

import PhoneNumber from 'awesome-phonenumber'

const phoneNumber = {
    getMessage: field => `${field} is not a valid phone number`,
    validate (value) {
        return new Promise(resolve => {
            let phone = new PhoneNumber(value);
            resolve({ valid: phone.isValid() })
        })
    }
}

Validator.extend('phoneNumber', phoneNumber)


const dhltrackingnumber = {
	getMessage(field, args) {
		return "DHL Tracking number invalid";
	},
	validate(value, args) {
		return new Promise(function(resolve, reject){
			return axios.get('/dashboard/shipment/tracking/dhl/' + value).then(response => {
				resolve(true);
			}).catch(err => {
				console.log('error', err);
				resolve(false);
			})
		});
	}
};

Validator.extend('dhlTrackingNumber', dhltrackingnumber)

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

Vue.component('feed', require('./components/Feed.vue').default);

Vue.component('user_permissions', require('./components/sections/science/UserPermissions.vue').default);

Vue.component('experiment_result', require('./components/sections/science/ExperimentResult.vue').default);

Vue.component('manufacturing_study_result', require('./components/sections/science/ManufacturingStudyResult.vue').default);

Vue.component('facilities', require('./components/sections/science/Facilities.vue').default);

Vue.component('manufacturing_studies', require('./components/sections/science/ManufacturingStudies.vue').default);

Vue.component('manufacturing_study_result_reports', require('./components/sections/science/ManufacturingStudyResultReports.vue').default);

Vue.component('manufacturing_study_reports', require('./components/sections/science/ManufacturingStudyReports.vue').default);

Vue.component('manufacturing_study_result_purchase_reports', require('./components/sections/science/ManufacturingStudyResultPurchaseReports.vue').default);

Vue.component('experiment_result_reports', require('./components/sections/science/ExperimentResultReports.vue').default);

Vue.component('experiment_result_purchase_reports', require('./components/sections/science/ExperimentResultPurchaseReports.vue').default);

Vue.component('experiment_reports', require('./components/sections/science/ExperimentReports.vue').default);

Vue.component('post_list', require('./components/sections/communications/PostList.vue').default);

Vue.component('push_notifications', require('./components/PushNotifications.vue').default);

Vue.component('notifications', require('./components/Notifications.vue').default);

Vue.component('calendar_grid', require('./components/calendar/CalendarGrid.vue').default);

Vue.component('result_reports_timeline', require('./components/ResultReportsTimeline.vue').default);

Vue.component('search', require('./components/search/Search.vue').default);

Vue.component('search_alerts_home', require('./components/search-alerts/Home.vue').default);

Vue.component('idea_board', require('./components/idea/Board.vue').default);

Vue.component('idea_demo_board', require('./components/idea/DemoBoard.vue').default);

Vue.component('covid_statistics_vaccinations_vs_infections', require('./components/reporting/CovidStatisticsVaccinationsVsInfections').default);

Vue.component('covid_statistics_vaccinations_vs_infections_custom', require('./components/reporting/CovidStatisticsVaccinationsVsInfectionsCustom').default);

Vue.component('covid_statistics_vaccinations_vs_infections_averaged', require('./components/reporting/CovidStatisticsVaccinationsVsInfectionsAveraged').default);

Vue.component('covid_statistics_vaccinations_vs_infections_averaged_chart', require('./components/reporting/CovidStatisticsVaccinationsVsInfectionsAveragedChart').default);

Vue.component('covid_statistics_vaccinations_vs_infections_averaged_year_comparison_chart', require('./components/reporting/CovidStatisticsVaccinationsVsInfectionsAveragedYearComparisonChart').default);

Vue.component('covid_statistics_vaccinations_vs_infections_country_comparison_chart', require('./components/reporting/CovidStatisticsVaccinationsVsInfectionsCountryComparisonChart').default);

Vue.component('covid_statistics_scores_country_comparison_chart', require('./components/reporting/CovidStatisticsScoresCountryComparisonChart').default);

Vue.component('covid_statistics_top_countries_by_infection_rate', require('./components/reporting/CovidStatisticsTopCountriesByInfectionRate').default);

Vue.component('covid_statistics_top_countries_by_vaccination_rate', require('./components/reporting/CovidStatisticsTopCountriesByVaccinationRate').default);

Vue.component('approval_requests', require('./components/sections/approval_requests/ApprovalRequestsDashboard').default);

Vue.component('approval_request', require('./components/sections/approval_requests/ApprovalRequest').default);

Vue.component('payments_dashboard', require('./components/sections/payments/PaymentsDashboard').default);

Vue.component('payment', require('./components/sections/payments/Payment').default);

Vue.component('resource_view_invitation', require('@/components/sections/storage/ResourceViewInvitation').default);

Vue.component('shipment', require('./components/sections/shipment/Shipment').default);

Vue.component('products', require('./components/sections/products/ProductsDashboard').default);

Vue.component('product', require('./components/sections/products/Product').default);

Vue.component('product_orders', require('./components/sections/product_orders/ProductOrdersDashboard').default);

Vue.component('text_area', require('./components/sections/reusable/field_types/TextArea').default);

Vue.component('product_order', require('./components/sections/product_orders/ProductOrder').default);

Vue.component('shipments', require('./components/sections/shipment/ShipmentDashboard').default);

Vue.component('shipment', require('./components/sections/shipment/Shipment').default);

Vue.component('expenses', require('./components/sections/expenses/ExpensesDashboard').default);

Vue.component('expense', require('./components/sections/expenses/Expense').default);

Vue.component('float_input', require('./components/FloatInput').default);





/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 *
 */

const app = new Vue({
    el: '#app',
    data(){
        return {
            root_var_1 : 10,
            root_var_2 : 10,
            uicontrol : {
                show_content_1 : true,
                show_content_2 : true
            }
        }
    },
    watch : {

        root_var_1(){
            this.uicontrol.show_content_1 = false;
            Vue.nextTick(() => {
                this.uicontrol.show_content_1 = true;
            });
        },

        root_var_2(){
            this.uicontrol.show_content_2 = false;
            Vue.nextTick(() => {
                this.uicontrol.show_content_2 = true;
            });
        }

    }
});

const storageDisplayTypeKey = 'storageDisplayType';

function changeStorageDisplayType(type) {
    $('.documents-wrapper').removeClass(['list', 'grid']).addClass(type);
    $('.list-view').removeClass('active');
    $('.grid-view').removeClass('active');
    $(`.${type}-view`).addClass('active');

}

$(window).on('load', function () {
    const storageDisplayType = localStorage.getItem(storageDisplayTypeKey) || 'list';
    changeStorageDisplayType(storageDisplayType);
});

$('.grid-view').on('click', function () {
    localStorage.setItem(storageDisplayTypeKey, 'grid');
    changeStorageDisplayType('grid');
});

$('.list-view').on('click', function () {
    localStorage.setItem(storageDisplayTypeKey, 'list');
    changeStorageDisplayType('list');
});

$('.slides').slick({
    dots: true,
    infinite: false,
    speed: 500,
    fade: true,
    cssEase: 'linear',
    lazyLoad: 'ondemand',
    nextArrow: '<button class="next arrow-next">Next<img src="/assets/icons/arrow-right-rounded.svg"></button>'
});

$(window).on('load', function () {
    if (window.location.hash.indexOf('#glossary') >= 0) {
        $('.slides').slick('goTo', 4);
    }
});

require('./misc/datepicker');


// Make request modal params

$('#makeRequestModal').on('show.bs.modal', function (e) {

    // Gather data from clicked button
    let resource = $(e.relatedTarget).data('resource');
    let resource_name = $(e.relatedTarget).data('resourcename');
    let resourceid = $(e.relatedTarget).data('resourceid');
    let extension = $(e.relatedTarget).data('extension');

    // Inject data in modal
    if (typeof extension == 'undefined') {
        $(e.currentTarget).find('#file_type_icon').hide();
    } else {
        $(e.currentTarget).find('#file_type_icon').show().attr("src", `../../assets/icons/${extension}.svg`);
    }

    // Title
    if (typeof extension == 'undefined') {
        $(e.currentTarget).find('#resource_name').hide();
    } else {
        $(e.currentTarget).find('#resource_name').show().html(resource_name.slice(0, 55) + '...');

        let str = new String();

        str.slice(0, 100)

    }

    // Hidden fields
    $(e.currentTarget).find("input[name=resource]").val(resource || 'Generic');
    $(e.currentTarget).find("input[name=resource_name]").val(resource_name || '-');

});


// Confirm before submitting form
$(".swa-confirm").on("click", function (e) {
    e.preventDefault();

    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    }).then((result) => {

        if (result.isConfirmed) {
            $(this).closest('form').submit();

        }
    })

});



/* */
(() => {

    let isalive = {

        seconds_remaining : 1800,

        listen : function() {

            var vm = this;

            try {
                window.Echo.private(`isalive.${user.identifier}`).listenForWhisper('isalive', (isalive) => {

                    if(vm.seconds_remaining <= 0){
                        // if countdown is over and alert is already on screen
                        // it means there was a login from another browser or tab
                        Swal.close();
                    }

                    vm.seconds_remaining = 1799;


                });
            } catch(e) {
                console.warn(e);
            }

            return this;
        },

        count : function() {

            var th = this;

            let i = setInterval(function(){

                if(th.seconds_remaining == 0){

                    // clearInterval(i);

                    // Force logout
                    axios.post('/logout');

                    Swal.fire({
                        title: 'Session timeout',
                        text: "You have been logged out",
                        icon: 'info',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'OK'
                    }).then((result) => {

                        if (result.isConfirmed) {
                            window.location.href = '/login';
                        }

                    });

                }


                if(typeof window.show_session_timer !== 'undefined'){

                    console.clear();
                    console.log(th.seconds_remaining);

                }

                th.seconds_remaining--;

            }, 1000);

            return this;

        },

        ping : function() {

            try {
                window.Echo.private(`isalive.${user.identifier}`).whisper('isalive', {
                    content: "something"
                });
            } catch (e) {
                console.warn(e);
            }

        }

    };

    function initIsAlive(){

        // Start listening and counting down
        isalive.listen().count();

        // Ping (for other browser windows)
        setTimeout(isalive.ping, 1000);

    }

    // If logged in
    if(user !== null){
        initIsAlive()
    }

    // Make available globally for ease of testing
    window.isalive = isalive;

})();

//Traffic charts.js map

function generateTrafficChart() {
    if (typeof trafficData === 'undefined' || !trafficData) {
        return;
    }
    var Chart = require('chart.js');

    var results = Object.entries(trafficData);
    var xData = [];
    var yData = [];

    results.forEach(function(entry) {
        xData.push(entry[0]);
        yData.push(entry[1]);
    });

    var ctx = document.getElementById('trafficChart');
    var trafficChart = new Chart(ctx, {
        type: 'line',
        responsive: true,
        data: {
            labels: xData.slice(Math.max(xData.length - 5, 0)),
            datasets: [{
                label: '# of Users',
                data: yData,
                backgroundColor: [
                    'rgba(93, 176, 130, 0.1)',
                    'rgba(93, 176, 130, 0.1)',
                    'rgba(93, 176, 130, 0.1)',
                    'rgba(93, 176, 130, 0.1)',
                    'rgba(93, 176, 130, 0.1)',
                    'rgba(93, 176, 130, 0.1)'
                ],
                borderColor: [
                    'rgba(93, 176, 130, 1)',
                    'rgba(93, 176, 130, 1)',
                    'rgba(93, 176, 130, 1)',
                    'rgba(93, 176, 130, 1)',
                    'rgba(93, 176, 130, 1)',
                    'rgba(93, 176, 130, 1)'
                ],
                borderWidth: 1
            }]
        },
        options: {
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true
                    }
                }]
            },
            legend: {
                display: false
            },
            maintainAspectRatio: false
        }
    });

}

generateTrafficChart();

$('.favorites-form').on('submit', function(event) {
    event.preventDefault();

    const dropbox_id = event.currentTarget.querySelector('input[name="dropbox_id"]');
    const csrf = event.currentTarget.querySelector('input[name="_token"]');
    const star = event.currentTarget.querySelector('.star');

    const removeURL = event.currentTarget.getAttribute('data-remove-url');
    const addURL = event.currentTarget.getAttribute('data-add-url');
    const url = star.classList.contains('full') ? removeURL : addURL;

    const formData = new FormData();
    formData.set('dropbox_id', dropbox_id.getAttribute('value'));
    formData.set('_token', csrf.getAttribute('value'));

    fetch(url, {
        method: 'POST',
        body: formData,
    }).then(() => {
        if (star.classList.contains('full')) {
            star.classList.remove('full');
            return;
        }

        star.classList.add('full');
    });
});


/**
 *  DELETE EXPERIMENT
 */
$('#delete_experiment').click(function(event){
    event.preventDefault();
    Swal.fire({
        title: 'Delete experiment?',
        text: 'This will delete all results and remove all signers and participants. This action cannot be undone',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete('/dashboard/science/experiments/' + $(event.target).data('experiment_identifier')).then(function(){
                window.location.href = '/dashboard/science/experiments';
            });
        }
    });
});

$('#delete_manufacturing_study').click(function(event){
    event.preventDefault();
    Swal.fire({
        title: 'Delete experiment?',
        text: 'This will delete all results and remove all signers and participants. This action cannot be undone',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
    }).then((result) => {
        if (result.isConfirmed) {
            axios.delete('/dashboard/science/experiments/' + $(event.target).data('experiment_identifier')).then(function(){
                window.location.href = '/dashboard/science/experiments';
            });
        }
    });
});



// Phone

import intlTelInput from 'intl-tel-input';

import 'intl-tel-input/build/css/intlTelInput.css';

const input = document.querySelector("#mobile");

if(null !== input){
    intlTelInput(input, {
        // any initialisation options go here
    });
}
