<!--suppress XmlInvalidId -->
<template>
    <div class="container">

        <div class="row">

            <div class="col-12">

                <div class="card">

                    <div id="approval_request_dashboard">
                        <div class="container">

                            <div class="row">
                                <div class="col-12 d-lg-flex justify-content-between border-1 mb-2 mt-2">
                                    <h3 class="title mb-3">Approval Requests</h3>
                                    <template v-if="user_permissions.some(el => ['operations.approval_request.nda.create', 'operations.approval_request.purchase.create'].indexOf(el) >= 0) ">

                                        <!-- Only show buttons if only one -->
                                        <a class="btn btn-white" style="line-height: 26px;" href="/dashboard/approval-requests/create/nda" v-if="user_permissions.includes('operations.approval_request.nda.create') && !user_permissions.includes('operations.approval_request.purchase.create')">Create NDA Request</a>
                                        <a class="btn btn-white" style="line-height: 26px;" href="/dashboard/approval-requests/create/purchase" v-else-if="!user_permissions.includes('operations.approval_request.nda.create') && user_permissions.includes('operations.approval_request.purchase.create')">Create Purchase Request</a>


                                        <div class="dropdown" v-else>

                                            <button class="btn btn-white dropdown-toggle new_request_btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                New request
                                            </button>

                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a class="dropdown-item"
                                                    :href="'/dashboard/approval-requests/create/' + type.id"
                                                    v-for="type in available_type_options"
                                                    v-if="user_permissions.includes(type.permission_prerequisite)">
                                                    {{type.title}}
                                                </a>
                                            </div>

                                        </div>

                                    </template>
                                </div>

                            </div>

                        </div>

                        <div class="container">

                            <div class="row">

                                <div class="col-12 d-lg-flex justify-content-between">

                                    <div class="form-group pr-2 mt-5 mt-md-0 mt-lg-0" style="width: 50%">
                                        <label class="font-weight-500 text-dark-grey " for="keywords">Keywords</label>
                                        <input type="text" id="keywords" name="keywords" class="form-control border-none pl-0" v-model="filters.keywords" placeholder="Title or description...">
                                        <span>{{ errors.first('keywords') }}</span>
                                    </div>

                                    <div class="form-group pr-2">
                                        <label for="type_filter" class="form-label font-weight-500 text-dark-grey">Type</label>
                                        <select name="type_filter" id="type_filter" v-model="filters.type_id" class="form-control border-none pl-0">
                                            <option v-for="type in filter_type_options" v-bind:value="type.id" v-html="type.title" :selected="type.id == filters.type_id"></option>
                                        </select>
                                    </div>

                                    <div class="form-group pr-2">
                                        <label for="status_filter" class="form-label font-weight-500 text-dark-grey">Status</label>
                                        <select name="status_filter" id="status_filter" v-model="filters.status_id" class="form-control border-none pl-0">
                                            <option v-for="status in filter_status_options" v-bind:value="status.id" v-html="status.title" :selected="status.id == filters.status_id"></option>
                                        </select>
                                    </div>

                                    <div class="form-group pr-2">
                                        <label for="urgency_filter" class="form-label font-weight-500 text-dark-grey">Urgency</label>
                                        <select name="urgency_filter" id="urgency_filter" v-model="filters.urgency_id" class="form-control border-none pl-0">
                                            <option v-for="urgency in filter_urgency_options" v-bind:value="urgency.id" v-html="urgency.title" :selected="urgency.id == filters.urgency_id"></option>
                                        </select>
                                    </div>

                                    <div class="form-group pr-2 text-lg-right" style="width: 430px;">
                                        <label for="date_range" class="form-label font-weight-500 text-dark-grey mr-3">Date range</label>
                                        <div class="d-flex">
                                            <flat-pickr class="fltpickr flatpickr_one text-right" v-model="filters.date_range_start" :config="flatpickr_config" placeholder="Start"></flat-pickr>
                                            <span style="border-bottom: 2px solid rgb(15, 117, 188); font-weight: 500; width: 20px; margin-bottom: 17px;"></span>
                                            <flat-pickr class="fltpickr flatpickr_two" v-model="filters.date_range_end" :config="flatpickr_config" placeholder="End"></flat-pickr>
                                        </div>
                                        </select>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <ul class="list-group">
                                        <li class="list-group-item d-none d-md-none d-lg-block">
                                            <div class="container">
                                                <div class="row">
                                                    <div :class="col()">
                                                        <span class="font-weight-500 text-dark-grey">Title</span>
                                                    </div>
                                                    <div class="col-2">
                                                        <span class="font-weight-500 text-dark-grey">To</span>
                                                    </div>
                                                    <div class="col-1">
                                                        <span class="font-weight-500 text-dark-grey">Type</span>
                                                    </div>
                                                    <div class="col-2">
                                                        <span class="font-weight-500 text-dark-grey">Data &amp; Time</span>
                                                    </div>
                                                    <div class="col-1">
                                                        <span class="font-weight-500 text-dark-grey">Status</span>
                                                    </div>
                                                    <div class="col-1">
                                                        <span class="font-weight-500 text-dark-grey">Urgency</span>
                                                    </div>
                                                    <div class="col-1 pr-0 text-right" v-if="is_admin">
                                                        <span class="font-weight-500 text-dark-grey">Delete</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="list-group-item " v-for="(item, itemKey) in items">
                                            <div class="container">
                                                <div class="row pt-2 pb-2">

                                                    <div :class="col()">
                                                        <a class="font-weight-500" :href="'/dashboard/approval-requests/' + item.identifier">
                                                            <span class="font-weight-500 white-space-nowrap">{{ item.title || 'No title' }}</span> </a>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <span class="text-light-grey  white-space-nowrap">
                                                            {{ item.data.recipient_name || 'Not set' }}
                                                        </span>
                                                    </div>

                                                    <div class="col-lg-1">
                                                        <span class="text-light-grey white-space-nowrap">{{
                                                                available_type_options.find(type => {
                                                                    return type.id == item.type;
                                                                }).title
                                                            }}</span>
                                                    </div>

                                                    <div class="col-lg-2">

                                                        <!-- NDA Template -->
                                                        <span class="text-light-grey white-space-nowrap"  v-if="item.type == 'nda'" v-text="getNdaTemplateTitle(item)"></span>

                                                        <span class="text-light-grey white-space-nowrap">
                                                            {{ formatTimeStamp(item.created_at) }}
                                                        </span>
                                                    </div>

                                                    <div class="col-lg-1">
                                                        <span class="font-weight-500 white-space-nowrap" :class="'text-' + item.status">
                                                            {{ (item.status.charAt(0).toUpperCase() + item.status.slice(1)).replaceAll('_', ' ') }}
                                                        </span>
                                                    </div>

                                                    <div class="col-lg-1 text-lg-right">
                                                        <span class="text-light-grey white-space-nowrap">
                                                            {{ available_urgency_options[item.urgency].title }}
                                                        </span>
                                                    </div>

                                                    <div class="col-lg-1 text-right" v-if="is_admin">
                                                        <i class="fa fa-trash btn-icon cursor-pointer text-danger" @click="deleteItem(item, itemKey)"></i>
                                                    </div>

                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-12 text-center" v-if="uicontrol.show_load_more">
                                    <button @click="loadMore()" class="btn btn-sm btn-outline-secondary mt-4 btn-rounded loadmore">Load more...</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>


import {DateTime} from "luxon";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Swal from "sweetalert2";

import hellosign_helper from "./types/hellosign_helper";

export default {
    name: "ApprovalRequestsDashboard",

    data: function () {
        return {

            is_admin : is_admin,

            filters: {
                keywords: '',
                type_id: null,
                status_id: null,
                urgency_id: null,
                date_range_start: null,
                date_range_end: null,
                limit : 15
            },

            available_nda_templates : [

            ],

            available_type_options: [
                {
                    title: 'Purchase',
                    id: 'purchase',
                    permission_prerequisite: 'operations.approval_request.purchase.create'
                },
                {
                    title: 'NDA',
                    id: 'nda',
                    permission_prerequisite: 'operations.approval_request.nda.create'
                },
                {
                    title: 'Legal',
                    id: 'legal',
                    permission_prerequisite: 'operations.approval_request.legal.create'
                }
            ],

            available_urgency_options: [
                {
                    title: 'Normal',
                    id: 0
                },
                {
                    title: 'Urgent',
                    id: 1
                },
                {
                    title: 'Critical',
                    id: 2
                }
            ],

            available_status_options: [
                {
                    title: 'Active',
                    id: 'active'
                },
                {
                    title: 'Approved',
                    id: 'approved'
                },
                {
                    title: 'Rejected',
                    id: 'rejected'
                },
                {
                    title: 'Completed',
                    id: 'completed'
                },
                {
                    title: 'Closed',
                    id: 'closed'
                }
            ],

            any_option : {
                title: 'Any',
                id: null
            },

            flatpickr_config: {
                dateFormat: "Y-m-d H:i:ss",
                altInput: true,
                altFormat: "F j, Y"
            },

            items: [

            ],

            uicontrol : {
                show_load_more : false,
                nda_templates_loaded : false
            }

        }

    },

    props : [
        'user_permissions'
    ],

    props : [
        'user_permissions'
    ],


    computed: {

        filter_status_options(){
            let options = this.available_status_options.slice(0);
            options.unshift(this.any_option);
            return options;
        },

        filter_type_options(){
            let options = this.available_type_options.slice(0);
            options.unshift(this.any_option);
            return options;
        },

        filter_urgency_options(){
            let options = this.available_urgency_options.slice(0);
            options.unshift(this.any_option);
            return options;
        },

        params(){

            let params = Object.assign({}, this.filters)

            if(params.keywords == '')
                delete params.keywords;

            // Add offset
            params.offset = this.items.length ? this.items.length + 1 : 0;

            return params

        }


    },

    watch: {

        filters: {
            deep: true,
            handler : _.debounce(function () {

                    this.items = [];
                    this.get().then(response => {
                        this.items = response.data.results;
                        this.uicontrol.show_load_more = response.data.more_results > 0;
                    });
                },

                500 // debounce time

            )
        }

    },

    components: {
        flatPickr
    },

    mounted() {

        hellosign_helper.getTemplates().then(response => {
            this.available_nda_templates.push(...response.data);
            this.uicontrol.nda_templates_loaded = true;
        });

        this.get().then(response => {
            this.items = response.data.results;
            this.uicontrol.show_load_more = response.data.more_results > 0;
        });

    },

    methods: {

        getNdaTemplateTitle(item){

            if(!this.uicontrol.nda_templates_loaded)
                return 'Loading...'

            if(!Boolean(item.data.selected_nda_template))
                return 'No template set'

            let template = this.available_nda_templates.find(template => template.id == item.data.selected_nda_template)

            let title

            if(Boolean(template)){

                title = template.title;
                if(title.length > 20)
                    title = title.substring(0, 20) + '...';

            }else{
                title = "Template not found"
            }

            return title;

        },

        col(){
          return this.is_admin ? 'col-lg-4' : 'col-lg-5';
        },

        deleteItem(item, itemKey){

            Swal.fire({
                title: 'Delete?',
                text: "This is not reversible!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {

                if (result.isConfirmed) {

                    axios.delete('/dashboard/approval-requests/delete/' + item.identifier).then(response => {
                        this.items.splice(itemKey, 1);
                    });

                }
            })

        },

        get: function () {
            return axios.get('/dashboard/approval-requests/get', {
                params: this.params
            });
        },

        loadMore(){
            this.get().then(response => {
                this.items = this.items.concat(response.data.results);
                this.uicontrol.show_load_more = response.data.more_results > 0;
            });
        },

        formatTimeStamp(timestamp) {
            return DateTime.fromISO(timestamp).toFormat('d LLL yyyy H:mm');
        }

    },
}
</script>

<style scoped>

</style>
