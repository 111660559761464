<template>
    <div class="container" v-if="uicontrol.ready">
        <div class="card">

            <component
                :is="approval_request.type"
                :approval_request="approval_request"
				:subject="subject"
                :editable="editable"
                :is_author="is_author"
                :is_participant="is_participant"
                :participants="participants"
                @update="update($event)"
                @save="save($event)"
                @set_editable="setEditable($event)"
            ></component>


        </div>
    </div>

</template>

<script>

import purchase from "./types/purchase";
import nda from "./types/nda";

export default {

    name: "ApprovalRequest",

    data : function () {
      return {
          approval_request : null,
          uicontrol: {
              ready : false
          },
          editable : null
      }
    },

    props : [
        'participants',
        'is_author',
        'is_participant',
		'subject'
    ],

    components : {
        purchase,
        nda
    },

    mounted(){
        this.approval_request = approval_request;
        this.editable = editable;
        this.uicontrol.ready = true;
    },

    methods: {

        update(updated_approval_request){
            this.approval_request = updated_approval_request;
        },

        save(approval_request){
            var vm = this;
            this.update(approval_request);
            axios.put('/dashboard/approval-requests/' + vm.approval_request.identifier, vm.approval_request);
        },

        setEditable(value){
            Vue.set(this, 'editable', value);
        }


    }

}

</script>

<style scoped>

</style>
