var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',[(_setup.props.items.length)?_c('div',{staticClass:"table mt-4"},[_vm._m(0),_vm._v(" "),_vm._l((_setup.props.items),function(approvalRequest){return _c('div',{staticClass:"table-row"},[_c('div',{staticClass:"table-cell"},[_c('a',{attrs:{"href":'/dashboard/approval-requests/' + approvalRequest.identifier}},[_c('strong',[_vm._v(" "+_vm._s(approvalRequest.title)+" ")])])]),_vm._v(" "),_c('div',{staticClass:"table-cell"},[_c('span',{staticClass:"p-2 text-capitalize",class:{
									'badge badge-primary' : approvalRequest.status == 'active',
									'badge badge-success' : approvalRequest.status == 'completed',
									'badge badge-danger' : approvalRequest.status == 'rejected',
							}},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(approvalRequest.status)+"\n\t\t\t\t\t\t")])]),_vm._v(" "),_c('div',{staticClass:"table-cell"},[_c('span',{class:{
								'badge badge-primary' : approvalRequest.payments?.length,
								'badge badge-danger' : !approvalRequest.payments?.length,
							},staticStyle:{"padding":"0.5rem !important"}},[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(approvalRequest.payments?.length ? 'Yes' : 'No')+"\n\t\t\t\t\t\t")])]),_vm._v(" "),_c('div',{staticClass:"table-cell"},[(!approvalRequest.payments?.length)?[_vm._v("\n\t\t\t\t\t-\n\t\t\t\t")]:[_c('span',{class:{
									'badge badge-success' : approvalRequest.payments[0].status == 'paid',
									'badge badge-danger' : approvalRequest.payments[0].status == 'not_paid',
							},staticStyle:{"padding":"0.5rem !important"}},[_vm._v("\n\t\t\t\t\t\t\t\t"+_vm._s(approvalRequest.payments[0].status)+"\n\t\t\t\t\t\t\t")])]],2)])})],2):_vm._e(),_vm._v(" "),(!_setup.props.items.length)?_c('div',[_vm._v("No purchase requests")]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"table-header"},[_c('div',{staticClass:"table-cell"},[_vm._v("Purchase Request")]),_vm._v(" "),_c('div',{staticClass:"table-cell"},[_vm._v("Request Status")]),_vm._v(" "),_c('div',{staticClass:"table-cell"},[_vm._v("Has Invoice")]),_vm._v(" "),_c('div',{staticClass:"table-cell"},[_vm._v("Invoice Status")])])
}]

export { render, staticRenderFns }