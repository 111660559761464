<template>
    <div class="card countries mb-4 pb-4">
        <div class="top">
            <p class="card-title" v-text="title"></p>
        </div>
        <div class="content">
            <template v-if="uicontrol.ready">
                <div class="header">
                    <div>
                        <span>Country</span>
                    </div>
                    <div>
                        <span>Population</span>
                    </div>
                    <div>
                        <span>Partially Vaccinated</span>
                    </div>
                    <div>
                        <span>Fully Vaccinated</span>
                    </div>
                    <div>
                        <span>New Cases Per Million</span>
                    </div>
                    <div>
                        <span data-toggle="tooltip" data-placement="top" title="Population density (people/square km)">Density</span>
                    </div>
                    <div>
                        <span data-toggle="tooltip" data-placement="top" title="Infection trend in previous weeks">Infection trend</span>
                    </div>
                    <div>
                        <span data-toggle="tooltip" data-placement="top" title="Infection trend last week">Last week</span>
                    </div>
                    <div>
                        <span></span>
                    </div>
                </div>
                <div class="lines">
                    <div v-for="(item, key) in items">
                        <div class="line">
                            <p class="country" :title="(item.has_booster) ? 'Has Boosters' : ''" data-toggle="tooltip" data-placement="top" :class="{ 'font-weight-bold' : item.has_booster }" v-text="(item.has_booster ? '*' : '') +  countries_by_code[key]"></p>
                            <p class="population" v-html="humanNumber(item.population, n => Number.parseFloat(n).toFixed(1))"></p>
                            <p class="single_dose" v-html="item.people_vaccinated_per_hundred == 'N/A' ? 'n.a.' :  Math.round((item.people_vaccinated_per_hundred), 2)  + '%'"></p>
                            <p class="fully_vaccinated" v-html="item.people_fully_vaccinated_per_hundred == 'N/A' ? 'n.a.' :  Math.round((item.people_fully_vaccinated_per_hundred), 2)  + '%'"></p>
                            <p class="infected_now" v-text="approx(item.new_cases_smoothed_per_million[0])"></p>
                            <p class="population_density_square_km" v-text="item.density_per_square_km == 'n.a.' ? 'n.a.' : approx(item.density_per_square_km)"></p>
                            <div>
                                <div :title="'5 weeks ago: ' + percentageDifference(item.new_cases_smoothed_per_million[4], item.new_cases_smoothed_per_million[5])" data-toggle="tooltip" data-placement="top" class="square" :class="{ 'background-danger' : (item.new_cases_smoothed_per_million[4] > item.new_cases_smoothed_per_million[5] && item.new_cases_smoothed_per_million[5] !== 0), 'background-success' : item.new_cases_smoothed_per_million[4] <= item.new_cases_smoothed_per_million[5], 'background-neutral' : item.new_cases_smoothed_per_million[5] == 0 }"></div>
                                <div :title="'4 weeks ago: ' + percentageDifference(item.new_cases_smoothed_per_million[3], item.new_cases_smoothed_per_million[4])" data-toggle="tooltip" data-placement="top" class="square" :class="{ 'background-danger' : (item.new_cases_smoothed_per_million[3] > item.new_cases_smoothed_per_million[4] && item.new_cases_smoothed_per_million[4] !== 0), 'background-success' : item.new_cases_smoothed_per_million[3] <= item.new_cases_smoothed_per_million[4], 'background-neutral' : item.new_cases_smoothed_per_million[4] == 0 }"></div>
                                <div :title="'3 weeks ago: ' + percentageDifference(item.new_cases_smoothed_per_million[2], item.new_cases_smoothed_per_million[3])" data-toggle="tooltip" data-placement="top" class="square" :class="{ 'background-danger' : (item.new_cases_smoothed_per_million[2] > item.new_cases_smoothed_per_million[3] && item.new_cases_smoothed_per_million[3] !== 0), 'background-success' : item.new_cases_smoothed_per_million[2] <= item.new_cases_smoothed_per_million[3], 'background-neutral' : item.new_cases_smoothed_per_million[3] == 0 }"></div>
                                <div :title="'2 weeks ago: ' + percentageDifference(item.new_cases_smoothed_per_million[1], item.new_cases_smoothed_per_million[2])" data-toggle="tooltip" data-placement="top" class="square" :class="{ 'background-danger' : (item.new_cases_smoothed_per_million[1] > item.new_cases_smoothed_per_million[2] && item.new_cases_smoothed_per_million[2] !== 0), 'background-success' : item.new_cases_smoothed_per_million[1] <= item.new_cases_smoothed_per_million[2], 'background-neutral' : item.new_cases_smoothed_per_million[2] == 0 }"></div>
                                <div :title="'Last week: ' + percentageDifference(item.new_cases_smoothed_per_million[0], item.new_cases_smoothed_per_million[1])" data-toggle="tooltip" data-placement="top" class="square" :class="{ 'background-danger' : (item.new_cases_smoothed_per_million[0] > item.new_cases_smoothed_per_million[1] && item.new_cases_smoothed_per_million[1] !== 0), 'background-success' : item.new_cases_smoothed_per_million[0] <= item.new_cases_smoothed_per_million[1], 'background-neutral' : item.new_cases_smoothed_per_million[1] == 0 }"></div>
                            </div>
                            <p class="last_week_infection_trend" :class="{ 'text-danger' : (item.new_cases_smoothed_per_million[0] > item.new_cases_smoothed_per_million[1] && item.new_cases_smoothed_per_million[1] !== 0), 'text-success' : item.new_cases_smoothed_per_million[0] < item.new_cases_smoothed_per_million[1]}" v-text="percentageDifference(item.new_cases_smoothed_per_million[0], item.new_cases_smoothed_per_million[1])"></p>
                            <div>
                                <button class="btn btn-outline-secondary btn-sm" @click="showCharts(key)" v-if="!items[key].show_charts">
                                    Show
                                </button>
                                <button class="btn btn-outline-secondary btn-sm" @click="hideCharts(key)" v-if="items[key].show_charts">
                                    Hide
                                </button>
                            </div>
                        </div>

                        <div class="graphs d-flex" v-if="items[key].show_charts" style="width: 100%;">
                            <div style="width: 50%;">
                                <iframe :src="'https://ourworldindata.org/explorers/coronavirus-data-explorer?zoomToSelection=true&time=2021-01-01..latest&pickerSort=desc&pickerMetric=total_vaccinations&Metric=People+fully+vaccinated&Interval=Cumulative&Relative+to+Population=true&Align+outbreaks=false&country=~' + key + '&hideControls=true'" loading="lazy" style="width: 100%; height: 600px; border: 0px none;"></iframe>
                            </div>
                            <div style="width: 50%;">
                                <iframe :src="'https://ourworldindata.org/explorers/coronavirus-data-explorer?zoomToSelection=true&time=2021-01-01..latest&pickerSort=desc&pickerMetric=new_cases_smoothed_per_million&Metric=Confirmed+cases&Interval=7-day+rolling+average&Relative+to+Population=true&Align+outbreaks=false&country=~' + key + '&hideControls=true'" loading="lazy" style="width: 100%; height: 600px; border: 0px none;"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else="">
                <div class="fullwidth text-center">
                    <div class="spinner-border" role="status" v-if="uicontrol.loading">
                        <span class="sr-only">Loading...</span>
                    </div>

                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pause" viewBox="0 0 16 16" v-else="" style="width: 35px; height: 35px;">
                        <path d="M6 3.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5zm4 0a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5z"/>
                    </svg>

                </div>
            </template>
        </div>
    </div>
</template>

<style scoped>

    .last_week_infection_trend{

    }

    .square{
        display: inline-block;
        width: 15px;
        height: 15px;
    }

</style>

<script>

var approx = require('approximate-number');
const retry = require('retry');
const humanNumber = require('human-number');

const operation = retry.operation({
    retries: 10,
    factor: 3,
    minTimeout: 1 * 1000,
    maxTimeout: 5 * 1000,
    randomize: true,
});

export default {
    name: "CovidStatisticsVaccinationsVsInfections",
    data: function () {
        return {
            humanNumber : humanNumber,
            items: [],
            countries_by_code: {
                AFG: 'Afghanistan',
                ALB: 'Albania',
                DZA: 'Algeria',
                ASM: 'American Samoa',
                AND: 'Andorra',
                AGO: 'Angola',
                AIA: 'Anguilla',
                ATA: 'Antarctica',
                ATG: 'Antigua and Barbuda',
                ARG: 'Argentina',
                ARM: 'Armenia',
                ABW: 'Aruba',
                AUS: 'Australia',
                AUT: 'Austria',
                AZE: 'Azerbaijan',
                BHS: 'Bahamas',
                BHR: 'Bahrain',
                BGD: 'Bangladesh',
                BRB: 'Barbados',
                BLR: 'Belarus',
                BEL: 'Belgium',
                BLZ: 'Belize',
                BEN: 'Benin',
                BMU: 'Bermuda',
                BTN: 'Bhutan',
                BOL: 'Bolivia',
                BIH: 'Bosnia and Herzegovina',
                BWA: 'Botswana',
                BRA: 'Brazil',
                IOT: 'British Indian Ocean Territory',
                VGB: 'British Virgin Islands',
                BRN: 'Brunei',
                BGR: 'Bulgaria',
                BFA: 'Burkina Faso',
                BDI: 'Burundi',
                KHM: 'Cambodia',
                CMR: 'Cameroon',
                CAN: 'Canada',
                CPV: 'Cape Verde',
                CYM: 'Cayman Islands',
                CAF: 'Central African Republic',
                TCD: 'Chad',
                CHL: 'Chile',
                CHN: 'China',
                CXR: 'Christmas Island',
                CCK: 'Cocos Islands',
                COL: 'Colombia',
                COM: 'Comoros',
                COK: 'Cook Islands',
                CRI: 'Costa Rica',
                HRV: 'Croatia',
                CUB: 'Cuba',
                CUW: 'Curacao',
                CYP: 'Cyprus',
                CZE: 'Czech Republic',
                COD: 'Democratic Republic of the Congo',
                DNK: 'Denmark',
                DJI: 'Djibouti',
                DMA: 'Dominica',
                DOM: 'Dominican Republic',
                TLS: 'East Timor',
                ECU: 'Ecuador',
                EGY: 'Egypt',
                SLV: 'El Salvador',
                GNQ: 'Equatorial Guinea',
                ERI: 'Eritrea',
                EST: 'Estonia',
                ETH: 'Ethiopia',
                FLK: 'Falkland Islands',
                FRO: 'Faroe Islands',
                FJI: 'Fiji',
                FIN: 'Finland',
                FRA: 'France',
                PYF: 'French Polynesia',
                GAB: 'Gabon',
                GMB: 'Gambia',
                GEO: 'Georgia',
                DEU: 'Germany',
                GHA: 'Ghana',
                GIB: 'Gibraltar',
                GRC: 'Greece',
                GRL: 'Greenland',
                GRD: 'Grenada',
                GUM: 'Guam',
                GTM: 'Guatemala',
                GGY: 'Guernsey',
                GIN: 'Guinea',
                GNB: 'Guinea-Bissau',
                GUY: 'Guyana',
                HTI: 'Haiti',
                HND: 'Honduras',
                HKG: 'Hong Kong',
                HUN: 'Hungary',
                ISL: 'Iceland',
                IND: 'India',
                IDN: 'Indonesia',
                IRN: 'Iran',
                IRQ: 'Iraq',
                IRL: 'Ireland',
                IMN: 'Isle of Man',
                ISR: 'Israel',
                ITA: 'Italy',
                CIV: 'Ivory Coast',
                JAM: 'Jamaica',
                JPN: 'Japan',
                JEY: 'Jersey',
                JOR: 'Jordan',
                KAZ: 'Kazakhstan',
                KEN: 'Kenya',
                KIR: 'Kiribati',
                XKX: 'Kosovo',
                KWT: 'Kuwait',
                KGZ: 'Kyrgyzstan',
                LAO: 'Laos',
                LVA: 'Latvia',
                LBN: 'Lebanon',
                LSO: 'Lesotho',
                LBR: 'Liberia',
                LBY: 'Libya',
                LIE: 'Liechtenstein',
                LTU: 'Lithuania',
                LUX: 'Luxembourg',
                MAC: 'Macau',
                MKD: 'Macedonia',
                MDG: 'Madagascar',
                MWI: 'Malawi',
                MYS: 'Malaysia',
                MDV: 'Maldives',
                MLI: 'Mali',
                MLT: 'Malta',
                MHL: 'Marshall Islands',
                MRT: 'Mauritania',
                MUS: 'Mauritius',
                MYT: 'Mayotte',
                MEX: 'Mexico',
                FSM: 'Micronesia',
                MDA: 'Moldova',
                MCO: 'Monaco',
                MNG: 'Mongolia',
                MNE: 'Montenegro',
                MSR: 'Montserrat',
                MAR: 'Morocco',
                MOZ: 'Mozambique',
                MMR: 'Myanmar',
                NAM: 'Namibia',
                NRU: 'Nauru',
                NPL: 'Nepal',
                NLD: 'Netherlands',
                ANT: 'Netherlands Antilles',
                NCL: 'New Caledonia',
                NZL: 'New Zealand',
                NIC: 'Nicaragua',
                NER: 'Niger',
                NGA: 'Nigeria',
                NIU: 'Niue',
                PRK: 'North Korea',
                MNP: 'Northern Mariana Islands',
                NOR: 'Norway',
                OMN: 'Oman',
                PAK: 'Pakistan',
                PLW: 'Palau',
                PSE: 'Palestine',
                PAN: 'Panama',
                PNG: 'Papua New Guinea',
                PRY: 'Paraguay',
                PER: 'Peru',
                PHL: 'Philippines',
                PCN: 'Pitcairn',
                POL: 'Poland',
                PRT: 'Portugal',
                PRI: 'Puerto Rico',
                QAT: 'Qatar',
                COG: 'Republic of the Congo',
                REU: 'Reunion',
                ROU: 'Romania',
                RUS: 'Russia',
                RWA: 'Rwanda',
                BLM: 'Saint Barthelemy',
                SHN: 'Saint Helena',
                KNA: 'Saint Kitts and Nevis',
                LCA: 'Saint Lucia',
                MAF: 'Saint Martin',
                SPM: 'Saint Pierre and Miquelon',
                VCT: 'Saint Vincent and the Grenadines',
                WSM: 'Samoa',
                SMR: 'San Marino',
                STP: 'Sao Tome and Principe',
                SAU: 'Saudi Arabia',
                SEN: 'Senegal',
                SRB: 'Serbia',
                SYC: 'Seychelles',
                SLE: 'Sierra Leone',
                SGP: 'Singapore',
                SXM: 'Sint Maarten',
                SVK: 'Slovakia',
                SVN: 'Slovenia',
                SLB: 'Solomon Islands',
                SOM: 'Somalia',
                ZAF: 'South Africa',
                KOR: 'South Korea',
                SSD: 'South Sudan',
                ESP: 'Spain',
                LKA: 'Sri Lanka',
                SDN: 'Sudan',
                SUR: 'Suriname',
                SJM: 'Svalbard and Jan Mayen',
                SWZ: 'Swaziland',
                SWE: 'Sweden',
                CHE: 'Switzerland',
                SYR: 'Syria',
                TWN: 'Taiwan',
                TJK: 'Tajikistan',
                TZA: 'Tanzania',
                THA: 'Thailand',
                TGO: 'Togo',
                TKL: 'Tokelau',
                TON: 'Tonga',
                TTO: 'Trinidad and Tobago',
                TUN: 'Tunisia',
                TUR: 'Turkey',
                TKM: 'Turkmenistan',
                TCA: 'Turks and Caicos Islands',
                TUV: 'Tuvalu',
                VIR: 'U.S. Virgin Islands',
                UGA: 'Uganda',
                UKR: 'Ukraine',
                ARE: 'United Arab Emirates',
                GBR: 'United Kingdom',
                USA: 'United States',
                URY: 'Uruguay',
                UZB: 'Uzbekistan',
                VUT: 'Vanuatu',
                VAT: 'Vatican',
                VEN: 'Venezuela',
                VNM: 'Vietnam',
                WLF: 'Wallis and Futuna',
                ESH: 'Western Sahara',
                YEM: 'Yemen',
                ZMB: 'Zambia',
                ZWE: 'Zimbabwe'
            },
            uicontrol: {
                ready: false,
                loading : false
            },
            approx: approx
        }
    },

    props: [
        'countries', 'title', 'sort_by', 'sort_order', 'index'
    ],

    mounted() {

        var vm = this;

        if(this.index == 0){
            this.getData();
        }else{
            this.$root.$on('load_index_' + this.index, () => {
                this.getData();
            });
        }


    },

    methods: {

        getData(){

            var vm = this;

            vm.uicontrol.loading = true;

            operation.attempt(async (currentAttempt) => {

                console.log('sending request: ', currentAttempt, ' attempt');

                try {

                    await axios.get('https://raphstats.xyz/api/data/infections-vs-vaccinations', {
                        params : {
                            countries : vm.countries,
                            sort_by : vm.sort_by,
                            sort_order : vm.sort_order,
							steps_back : vm.steps_back,
							step_size : vm.step_size
                        }
                    }).then(r => {

                        if(r.status == 404)
                            throw('Error, please retry');
                        vm.items = r.data;
                        vm.uicontrol.ready = true;

                        vm.$root.$emit('load_index_' + (vm.index + 1));

                        setTimeout(function(){
                            $('[data-toggle="tooltip"]').tooltip();
                        }, 500);

                        // Load next index
                        // setTimeout(function(){
                        //     vm.$root.$emit('load_index_' + (vm.index++));
                        // }, 500);



                    });

                } catch (e) {
                    if (operation.retry(e)) { return; }
                }
            });

        },

        percentageDifference(new_val, old_val){

            if(old_val == 0)
                return 'n.a.';

            return (new_val < old_val ? '' : '+') + ((new_val - old_val) / old_val * 100).toFixed(2) + '%';

        },

        showCharts(key) {
            this.uicontrol.ready = false;
            this.items[key].show_charts = true;
            this.uicontrol.ready = true;
        },

        hideCharts(key) {
            this.uicontrol.ready = false;
            this.items[key].show_charts = false;
            this.uicontrol.ready = true;
        },

    }
}
</script>

<style scoped>

</style>
