<template>

	<div v-if="uicontrol.ready" class="container">

		<div class="card">

			<div class="row">

				<div class="col-12 d-flex pt-4 justify-content-between mb-4">
					<div>
						<h3 class="title mb-2" style="margin-top: 2px">Expense</h3>
					</div>

					<div v-if="is_author && editable">
						<button :class="{ loading : uicontrol.saving }" class="btn btn-rounded btn-white" @click="save($event, true)">
							<div data-loader="circle-side"></div>
							Save
						</button>
					</div>
				</div>

			</div>

			<div class="row mb-4">

				<div class="col-6">

					<div class="d-flex">
						<div class="form-group mr-2">
							<label class="form-label font-weight-500 text-grey" for="category">Category</label>
							<select id="category" v-model="expense.category" v-validate="'required'" :disabled="!is_author || !editable" class="form-control" name="category">
								<option v-for="category in available_categories" :selected="category.identifier == expense.category" v-bind:value="category.identifier" v-html="category.name"></option>
							</select> <span class="text-danger">{{ errors.first('product') }}</span>
						</div>


						<div class="form-group mr-2 mb-0">
							<label class="form-label font-weight-500" for="amount">Unit price</label>
							<float_input
								:id="'unit_price'"
								:name="'unit_price'"
								:val="expense.amount" @update="expense.amount = $event"
								:decimals="2"
								:disabled="!is_author || !editable">
							</float_input>
						</div>

						<div class="form-group pr-2 pl-lg-2" style="width: 150px">
							<label class="form-label font-weight-500" for="currency">Currency</label>
							<select id="currency" v-model="expense.currency" v-validate="'required'" class="form-control" name="field" :disabled="!is_author || !editable">
								<option v-for="currency in available_currencies" :selected="currency.value == expense.currency" v-bind:value="currency.value" v-html="currency.label"></option>
							</select> <span class="text-danger">{{ errors.first('currency') }}</span>
						</div>

					</div>

					<div class="d-flex">

						<div class="form-group pr-2">
							<label class="form-label font-weight-500" for="created_at">Date</label>
							<flat-pickr name="created_at" class="form-control" v-model="expense.created_at" placeholder="Date & Time" :disabled="!is_author || !editable"></flat-pickr>
						</div>

						<div class="form-group pr-2 pl-lg-2">
							<label class="form-label font-weight-500" for="currency">Reimbursement required</label>
							<select id="reimburesement_required" v-model="expense.reimbursement_required" v-validate="'required'" class="form-control" name="field" :disabled="!is_author || !editable">
								<option v-for="reimbursement_option in available_reimbursement_options" :selected="reimbursement_option.identifier == expense.reimbursement_required" v-bind:value="reimbursement_option.identifier" v-html="reimbursement_option.name"></option>
							</select> <span class="text-danger">{{ errors.first('reimburesement_required') }}</span>
						</div>

					</div>

					<div class="form-group mt-2 file-upload">
						<h6 class="text-grey">Upload receipt</h6>
						<div :style="{ 'padding-top' : (expense.data.files.length) ? '20px' : '137px'}" class="files_wrapper" style="border: 1px dashed rgb(235, 236, 240); border-radius: 6px; padding-top: 137px; padding-bottom: 10px;">

							<ul v-if="expense.data.files.length" class="file_list pl-2 pr-2">
								<li v-for="(file, fKey) in expense.data.files">
									<file :key="fKey" :can_delete="editable && is_author" :file="file" @removed_file="removedFile($event)"></file>
								</li>
							</ul>

							<div v-else id="drop_area" class="files_bg">
								<svg class="bi bi-cloud-arrow-up" fill="#B4BAC4" height="40" viewBox="0 0 16 16" width="40" xmlns="http://www.w3.org/2000/svg">
									<path d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2z" fill-rule="evenodd"/>
									<path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"/>
								</svg>
								<br>
								<span>
									<span class="bigger font-weight-700">
										Drag &amp; Drop
									</span>files here <br> or
								</span>

							</div>

							<div class="button-holder text-center">
								<file_upload v-if="editable && is_author" :data="{}" @uploaded="uploadedFile($event)"></file_upload>
							</div>

						</div>
					</div>


				</div>

				<div class="col-6">

					<div>
						<label class="form-label font-weight-500" for="description">Description</label>
						<textarea id="description" v-model="expense.description" :disabled="!is_author || !editable" class="form-control" name="description" required rows="2" style="height:150px; resize: none;" type="text"></textarea>
						<div class="invalid-feedback">
							Valid description is required.
						</div>
						<span class="text-danger">{{ errors.first('description') }}</span>
					</div>

				</div>

			</div>

		</div>

	</div>

</template>

<script>

import available_currencies from '@/misc/available_currencies';
import file_upload from '@/components/sections/reusable/FileUpload';
import file from "@/components/sections/reusable/File";
import droparea from '@/misc/droparea';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {

	name: "Expense",

	data: function () {
		return {
			expense: null,
			uicontrol: {
				ready: false,
				saving: false
			},
			editable: null,
			available_categories: [
				{
					identifier: null,
					name: 'Select...'
				},
				{
					identifier: 'advertising-and-marketing',
					name: 'Advertising & Marketing'
				},
				{
					identifier: 'event',
					name: 'Event'
				},
				{
					identifier: 'fuel-and-mileage',
					name: 'Fuel and Mileage'
				},
				{
					identifier: 'insurance',
					name: 'Insurance'
				},
				{
					identifier: 'medical',
					name: 'Medical'
				},
				{
					identifier: 'misc',
					name: 'Miscellaneous'
				},
				{
					identifier: 'office-supplies',
					name: 'Office Supplies'
				},
				{
					identifier: 'printing',
					name: 'Printing'
				},
				{
					identifier: 'purchases',
					name: 'Purchases'
				},
				{
					identifier: 'rent',
					name: 'Rent'
				},
				{
					identifier: 'shipment',
					name: 'Shipment'
				},
				{
					identifier: 'software-subscriptions',
					name: 'Software subscriptions'
				},
				{
					identifier: 'stocks-and-materials',
					name: 'Stock & Materials'
				},
				{
					identifier: 'sundries',
					name: 'Sundries'
				},
				{
					identifier: 'telephone',
					name: 'Telephone'
				},
				{
					identifier: 'travel-and-entertainment',
					name: 'Travel & Entertainment'
				},
				{
					identifier: 'utilities',
					name: 'Utilities'
				},
			],
			available_reimbursement_options: [
				{
					identifier: 0,
					name: 'No'
				},
				{
					identifier: 1,
					name: 'Yes'
				},
			],
			available_currencies,
		}
	},

	props: [
		'is_author'
	],

	components: {
		file_upload,
		file,
		flatPickr
	},

	mounted() {

		this.expense = expense;
		this.editable = editable;
		this.uicontrol.ready = true;

		Vue.nextTick(() => {
			droparea.initialize(this, 'emit');
		});

	},

	methods: {

		save(event, redirect) {

			var vm = this;

			vm.$validator.validate().then(valid => {

				console.log(valid);

				if (valid) {

					// Fake saving
					vm.uicontrol.saving = true;
					setTimeout(function () {
						vm.uicontrol.saving = false;
					}, 500);

					axios.put('/dashboard/expense/' + vm.expense.identifier, vm.expense).then(response => {
						console.log(response.data);
					});

					if(redirect)
						window.location.href = '/dashboard/expense';


				}

			});

		},

		setEditable(value) {
			Vue.set(this, 'editable', value);
		},

		uploadedFile(file) {
			this.expense.data.files.push(file);
			this.save(null,false);
		},

		removedFile(identifier) {
			let file_index = this.expense.data.files.findIndex(el => {
				return el.identifier == identifier;
			})
			this.expense.data.files.splice(file_index, 1);
			this.save(null, false);
		}

	}

}

</script>

<style scoped>

</style>