<template>

    <div class="row">

        <div class="col-12 mt-4 d-flex justify-content-center">

            <div class="form-group mr-2" style="width: 100%">
                <label for="title" class="form-label font-weight-500 text-grey">Product</label>
                <select id="product" name="product" v-model="selected_product_identifier" class="form-control" v-validate="'required'">
                    <option v-for="product in available_products" v-bind:value="product.identifier" v-html="product.name" :selected="product.identifier == selected_product_identifier"></option>
                </select>
                <span class="text-danger">{{ errors.first('product') }}</span>
            </div>

            <div class="form-group mr-2" style="width: 100%">
                <label :for="'amount'" class="form-label font-weight-500  text-grey">Amount ({{ number_en(total_in_stock, 0) }} in stock)</label>

				<float_input
					:id="'amount'"
					:name="'amount'"
					:val="amount" @update="amount = $event"
					:decimals="0"
					:disabled="total_in_stock == 0">
				</float_input>

                <span class="text-danger">{{ errors.first('title') }}</span>
            </div>

            <div class="form-group mr-2" style="margin-top: 24px; min-width: 80px;">
                <button class="btn btn-transparent text-primary" @click="add()" :disabled="!form_valid">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
                    </svg>
                    <span class="font-weight-500 mt-1 text-primary" style="line-height:28px; display: inline-block;">Add</span>
                </button>
            </div>

            <div class="form-group" style="margin-top: 31px;">
                <button class="btn btn-transparent text-grey pr-0" @click="$emit('hide')">Cancel</button>
            </div>

        </div>

    </div>

</template>

<script>

import { number_en } from '@/misc/number_utilities';

export default {
    name: "ProductPicker",
    props : [],
    data : function(){
        return {
            selected_product_identifier : null,
            amount : 0,
            available_products: [
                {
                    identifier: null,
                    name: 'Select...'
                },
            ],
			number_en
        }
    },

    computed : {

        selected_product(){
            return this.selected_product_identifier == null ? null : this.available_products.find(product => product.identifier == this.selected_product_identifier);
        },

        total_in_stock(){
            return this.selected_product == null ? 'N/A' : this.selected_product.total_in_stock;
        },

        form_valid(){
            return this.selected_product_identifier !== null && this.amount > 0 && this.amount <= this.total_in_stock;
        }

    },

    watch : {

        selected_product_identifier(){
            this.amount = 0;
        }

    },

    mounted(){

        this.loadAllProducts();

    },

    methods : {

        add(){

            var vm = this;

            vm.$validator.validate().then(valid => {

                console.log(valid);

                if (valid) {

                    vm.$emit('add_product', {
                        'product_identifier' : vm.selected_product_identifier,
                        'amount' : vm.amount
                    });

                }

            });




        },


        loadAllProducts() {

            return axios.get('/dashboard/product/get',{
                params : {
                    offset : 0,
                    limit : 100000
                }
            }).then(response => {
                this.available_products.push(...response.data.results);
            });

        },

    }
}
</script>

<style scoped>

</style>
