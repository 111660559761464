<template>
    <main>

        <blog_post_editor
            v-if="uicontrol.blog_post_editor.show"
            :post="uicontrol.blog_post_editor.post"
            :category_name="uicontrol.blog_post_editor.post.category_name"
            :category_slug="uicontrol.blog_post_editor.post.category_slug"
            :category_id="uicontrol.blog_post_editor.post.category_id"
            @insert_post="insertPost($event)"
            @update_post="updatePost($event)"
            @close="() => {
                uicontrol.blog_post_editor.show = false;
                uicontrol.blog_post_editor.post = null;
            }">
        </blog_post_editor>

        <div class="container">

            <div class="row">
                <div class="col-12 d-flex justify-content-between pb-3">
                    <button class="btn btn-primary" @click="newPost()" v-if="Boolean(category_slug)">New post in {{category_name}}</button>
                </div>
            </div>

            <!-- Communications category page:  /dashboard/communications/{category-slug} -->
            <template v-if="category_slug">
                <div class="communications-category-page">
                    <div>
                        <a href="/dashboard/communications" class="back-to-communications">Back to Communications</a>
                    </div>
                    <div>
                        <h3 class="cat-title" v-text="category_name"></h3>
                        <div class="grid-components">
                            <component v-for="(post, postKey) in posts"
                                :is="getTemplate(post.category_slug)"
                                :post="post"
                                :categorySlug="category_slug"
                                :key="postKey"
                                :index="postKey"
                                @edit="editPost($event)"
                                @delete_post="deletePost($event)"
                            ></component>
                        </div>
                    </div>
                </div>
            </template>

            <!-- Communications landing page:  /dashboard/communications -->
            <template v-else="">

                <div  class="communications-template">
                    <div class="group">

                        <!-- Suggested News -->
                        <div class="pre-card st" v-if="getPostByCategory('suggested-news')">
                            <div class="card">
                                <div class="title-area">
                                    <h3 class="title">Suggested news</h3>
                                    <a href="#" class="see-more">See more</a>
                                </div>
                                <div class="card-body">

                                    <p class="small" v-text="DateTime.fromISO(getPostByCategory('suggested-news').created_at).toFormat('dd LLL yyyy')"></p>
                                    <h3 class="card-title" v-text="shortText(getPostByCategory('suggested-news').data.title, 50)"></h3>
                                    <span v-text="shortText(getPostByCategory('suggested-news').data.short_description, 500)" class="description"></span>
                                    <a :href="`/dashboard/communications/${getPostByCategory('suggested-news').category_slug}/${getPostByCategory('suggested-news').slug}`">Read</a>
                                </div>
                            </div>
                        </div>

                        <!-- Weekly Update -->
                        <div class="pre-card nd" v-if="getPostByCategory('weekly-update')">
                            <div class="card">
                                <div class="card-body">
                                    <div class="card-content">
                                        <div>
                                            <div class="title-area">
                                                <h3 class="title">Weekly update</h3>
                                            </div>
                                            <p class="small" v-text="DateTime.fromISO(getPostByCategory('weekly-update').created_at).toFormat('dd LLL yyyy')"></p>
                                            <h3 class="card-title" v-text="shortText(getPostByCategory('weekly-update').data.title, 50)"></h3>
                                            <p v-text="shortText(getPostByCategory('weekly-update').data.short_description, 220)" class="description"></p>
                                        </div>
                                        <a :href="`/dashboard/communications/${getPostByCategory('weekly-update').category_slug}/${getPostByCategory('weekly-update').slug}`" class="more btn btn-secondary btn-sm btn-white">Read more</a>
                                    </div>
                                    <img class="main-img" :src="imgUrlDash(getPostByCategory('weekly-update'))" alt="">

                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="group right" v-if="getPostByCategory('weekly-science-update')">
                        <div class="group one">
                            <!-- Weekly Science Update -->
                            <div class="pre-card h">
                                <div class="card">
                                    <div class="title-area">
                                        <h3 class="title">Weekly science update</h3>
                                        <a href="#" class="see-more white">See more</a>
                                    </div>
                                    <div class="card-body d-flex p-0">
                                        <div class="pr-4">
                                            <div>
                                                <p v-text="shortText(getPostByCategory('weekly-science-update').data.short_description, 400)" class="description"></p>
                                            </div>
                                            <a :href="`/dashboard/communications/${getPostByCategory('weekly-science-update').category_slug}/${getPostByCategory('weekly-science-update').slug}`" class="btn btn-secondary btn-sm btn-white">Link</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Featured Articles of Interest -->
                            <div class="pre-card n" v-if="getPostByCategory('featured-articles')">
                                <div class="card">
                                    <div class="title-area">
                                        <h3 class="title">Featured articles of interest</h3>
                                    </div>
                                    <div class="card-body">
                                        <img class="main-img" :src="imgUrlDash(getPostByCategory('featured-articles'))" alt="">
                                        <div class="card-content">
                                            <p class="small" v-text="DateTime.fromISO(getPostByCategory('featured-articles').created_at).toFormat('dd LLL yyyy')"></p>
                                            <h3 class="card-title" v-text="shortText(getPostByCategory('featured-articles').data.title, 17)"></h3>
                                            <p>
                                                <span v-text="shortText(getPostByCategory('featured-articles').data.short_description, 70)" class="description"></span>
                                                <a :href="`/dashboard/communications/${getPostByCategory('featured-articles').category_slug}/${getPostByCategory('featured-articles').slug}`">Read</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="group two">
                            <!-- Video based news -->
                            <div class="pre-card sm video" v-if="getPostByCategory('video-based-news')">
                                <div class="card">
                                    <div class="card-body">
                                        <div>
                                            <h3 class="card-title">Video based news</h3>
                                        </div>
                                        <a :href="`/dashboard/communications/${getPostByCategory('video-based-news').category_slug}/${getPostByCategory('video-based-news').slug}`" class="btn btn-secondary btn-sm btn-white">See more</a>
                                    </div>
                                    <div class="overlay"></div>
                                </div>
                            </div>
                            <!-- Scientific Papers -->
                            <div class="pre-card sm" v-if="getPostByCategory('scientific-papers')">
                                <div class="card">
                                    <div class="title-area">
                                        <h3 class="title">Scientific papers</h3>
                                    </div>
                                    <div class="card-body d-flex p-0">
                                        <div class="pr-4">
                                            <div>
                                                <button class="btn btn-sm btn-filled-rounded options" @click="$emit('edit', index)">...</button>
                                                <h3 class="card-title" v-text="shortText(getPostByCategory('scientific-papers').data.title, 30)"></h3>
                                                <p class="description" v-text="shortText(getPostByCategory('scientific-papers').data.short_description, 50)"></p>
                                            </div>
                                            <a :href="`/dashboard/communications/${getPostByCategory('scientific-papers').category_slug}/${getPostByCategory('scientific-papers').slug}`" class="btn btn-secondary btn-sm btn-white">See more</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Milestones achieved and timelines -->
                            <div class="pre-card xs" v-if="getPostByCategory('milestones-achieved-and-timelines')">
                                <div class="card">
                                    <div class="card-body">
                                        <div>
                                            <!-- <button class="btn btn-sm btn-filled-rounded options options" @click="editPost(8)">...</button> -->
                                            <h3 class="card-title">Milestones achieved and timelines</h3>
                                        </div>
                                        <a :href="`/dashboard/communications/${getPostByCategory('milestones-achieved-and-timelines').category_slug}/${getPostByCategory('milestones-achieved-and-timelines').slug}`" class="btn btn-secondary btn-sm btn-white">See more</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

        </div>
    </main>
</template>

<script>
    import { DateTime } from "luxon";
    import Swal from 'sweetalert2'
    import quarter_width_thumbnail_top from './post_templates/QuarterWidthThumbnailTop';
    import half_width_thumbnail_right from './post_templates/HalfWidthThumbnailRight';
    import full_width_no_image from './post_templates/FullWidthNoImage';
    import half_width_no_image from './post_templates/HalfWidthNoImage';
    import mixed_size_mixed_width_mixed_align from './post_templates/MixedSizeMixedWidthMixedAlign';
    import blog_post_editor from './BlogPostEditor';

    export default {
        name: "PostList",
        data: function () {
            return {
                uicontrol : {
                    blog_post_editor : {
                        show : false,
                        post : null
                    }
                },
                DateTime : DateTime
            }
        },

        props: [
            'posts',
            'category_name',
            'category_slug',
            'category_id'
        ],

        components: {
            half_width_thumbnail_right,
            quarter_width_thumbnail_top,
            full_width_no_image,
            half_width_no_image,
            mixed_size_mixed_width_mixed_align,
            blog_post_editor
        },

        computed : {

        },

        methods: {
            shortText(post, desired_length) {
                return (post.length <= desired_length) ? post : post.substr(0, desired_length) + "...";
            },

            imgUrlDash(post){

                if(post == null || post.data == null || post.data.image == null)
                    return '';

                let imgurl = post.data.image;

                return imgurl.includes('http') ? imgurl : '/file/' + post.data.image;

            },

            getPostByCategory(category){
                let post = this.posts.filter(post => post.category_slug == category);

                return typeof post[0] == 'undefined' ? false : post[0];
            },

            getTemplate(slug) {

                let map = {
                    'suggested-news': 'quarter_width_thumbnail_top',
                    'weekly-update': 'half_width_thumbnail_right',
                    'science-news': 'half_width_thumbnail_right',
                    'featured-articles': 'half_width_thumbnail_right',
                    'weekly-science-update': 'half_width_no_image',
                    'scientific-papers': 'full_width_no_image',
                    'video-based-news': 'half_width_thumbnail_right',
                    'milestones-achieved-and-timelines': 'half_width_thumbnail_right'
                }

                return map[slug] || 'half_width_thumbnail_right'; // TODO: replace this with dashboard template

            },

            newPost(){

                this.uicontrol.blog_post_editor.show = true;

                this.uicontrol.blog_post_editor.post = {
                    category_name : this.category_name,
                    category_slug : this.category_slug,
                    category_id : this.category_id,
                }


            },

            insertPost(post){
                this.posts.unshift(post);
            },

            updatePost(post){

                let index = this.posts.findIndex(item => item.id == post.id);
                this.posts.splice(index, 1, post);

            },

            editPost(postKey){
                this.uicontrol.blog_post_editor.post = this.posts[postKey];
                this.uicontrol.blog_post_editor.show = true;
            },

            deletePost(postKey){

                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.isConfirmed) {

                        let post_id = this.posts[postKey].id;
                        this.posts.splice(postKey, 1);
                        this.$forceUpdate();

                        axios.delete('/blog-post/' + post_id);

                    }
                })

            }

        }

    }
</script>

<style scoped>
    .cat-title{
        color: #0D1F40;
        font-size: 22px;
        font-weight: 700;
    }
    .weekly-update .row{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }
</style>
