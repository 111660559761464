<!--suppress XmlInvalidId -->
<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div id="approval_request_dashboard">
                        <div class="container">
                            <div class="row">
                                <div class="col-12 d-lg-flex justify-content-between border-1 mb-4 mt-2">
                                    <h3 class="title mb-3">Products</h3>
                                    <template v-if="user_permissions.includes('operations.product_order.create') ">
                                        <a class="btn btn-white" style="line-height: 26px;" href="/dashboard/product/order/create">
                                            New Order
                                        </a>
                                    </template>

                                </div>
                            </div>
                        </div>

                        <div class="container">
                            <div class="row">
                                <div class="col-12 d-lg-flex justify-content-between">
                                    <div class="form-group pr-2 mt-5 mt-md-0 mt-lg-0" style="width: 50%">
                                        <label class="font-weight-500 text-dark-grey " for="keywords">Keywords</label>
                                        <input type="text" id="keywords" name="keywords" class="form-control border-none pl-0" v-model="filters.keywords" placeholder="Title or description...">
                                        <span>{{ errors.first('keywords') }}</span>
                                    </div>
                                    <!--<div class="form-group pr-2">-->
                                    <!--    <label for="type_filter" class="form-label font-weight-500 text-dark-grey">Type</label>-->
                                    <!--    <select name="type_filter" id="type_filter" v-model="filters.type_id" class="form-control border-none pl-0">-->
                                    <!--        <option v-for="type in filter_type_options" v-bind:value="type.id" v-html="type.title" :selected="type.id == filters.type_id"></option>-->
                                    <!--    </select>-->
                                    <!--</div>-->
                                    <div class="form-group pr-2">
                                        <label for="stock_filter" class="form-label font-weight-500 text-dark-grey">Status</label>
                                        <select name="stock_filter" id="stock_filter" v-model="filters.status_id" class="form-control border-none pl-0">
                                            <option v-for="status in filter_status_options" v-bind:value="status.id" v-html="status.title" :selected="status.id == filters.status_id"></option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="container">
                            <div class="row">
                                <div class="col-12">
                                    <ul class="list-group">
                                        <li class="list-group-item cursor-default d-none d-md-none d-lg-block">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-5">
                                                        <span class="font-weight-500 text-dark-grey">Title</span>
                                                    </div>
                                                    <div class="col-3">
                                                        <span class="font-weight-500 text-dark-grey">Product</span>
                                                    </div>
                                                    <div class="col-2">
                                                        <span class="font-weight-500 text-dark-grey">Amount</span>
                                                    </div>
                                                    <div class="col-1">
                                                        <span class="font-weight-500 text-dark-grey">Status</span>
                                                    </div>
													<div class="col-1">
														<span class="font-weight-500 text-dark-grey">Action</span>
													</div>
                                                </div>
                                            </div>
                                        </li>

                                        <li class="list-group-item cursor-default" v-for="(item, itemKey) in items" v-if="items.length">
                                            <div class="container">
                                                <div class="row pt-2 pb-2">

													<div class="col-5">
                                                        <a :href="'/dashboard/product/order/' + item.identifier">
                                                            <span class="font-weight-500 white-space-nowrap">{{ item.title }}</span>
                                                        </a>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <span class="text-light-grey white-space-nowrap">
                                                            {{ item.product ? item.product.name : 'Not set' }}
                                                        </span>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <span class="text-light-grey white-space-nowrap">
                                                            <currency_sign :currency="item.currency" v-if="item.amount"></currency_sign>{{
																number_en(item.amount * item.price_per_unit) || 'n.a.'
															}}

                                                        </span>
                                                    </div>

                                                    <div class="col-lg-1">
                                                        <span class="font-weight-500 white-space-nowrap">
                                                            {{ available_status_options.find(option => option.id == item.status).title }}
                                                        </span>
                                                    </div>

													<div class="col-lg-1 text-center">
														<i class="fa fa-trash btn-icon cursor-pointer text-danger" @click="deleteItem(item, itemKey)" v-if="item.status == 'pending' && is_admin"></i>
													</div>

                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-12 text-center" v-if="uicontrol.show_load_more">
                                    <button @click="loadMore()" class="btn btn-sm btn-outline-secondary mt-4 btn-rounded loadmore">Load more...</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import {DateTime} from "luxon";
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import Swal from "sweetalert2";
import {number_en} from '@/misc/number_utilities';
import currency_sign from '../reusable/CurrencySign';

export default {
    name: "ProductOrdersDashboard",
    data: function () {
        return {

            is_admin : is_admin,

            filters: {
                type_id: null,
                status_id: null,
                limit : 15
            },

            available_type_options: [
                {
                    title: 'Product',
                    id: 'product',
                    permission_prerequisite: null
                }
            ],

            available_status_options: [
                {
                    title: 'Pending',
                    id: 'pending'
                },
                {
                    title: 'Approved',
                    id: 'approved'
                },
                {
                    title: 'In Progress',
                    id: 'in_progress'
                },

                {
                    title: 'Completed',
                    id: 'completed'
                },
				{
					title: 'Cancelled',
					id: 'cancelled'
				},
				{
					title: 'Stopped',
					id: 'stopped'
				},

            ],

            any_option : {
                title: 'Any',
                id: null
            },

            flatpickr_config: {
                dateFormat: "Y-m-d H:i:ss",
                altInput: true,
                altFormat: "F j, Y"
            },

            items: [

            ],

            uicontrol : {
                show_load_more : false
            },

			number_en: number_en,

        }

    },

    props : [
        'user_permissions'
    ],


    computed: {

        filter_status_options(){
            let options = this.available_status_options.slice(0);
            options.unshift(this.any_option);
            return options;
        },

        filter_type_options(){
            let options = this.available_type_options.slice(0);
            options.unshift(this.any_option);
            return options;
        },

        filter_urgency_options(){
            let options = this.available_urgency_options.slice(0);
            options.unshift(this.any_option);
            return options;
        },

        params(){

            let params = Object.assign({}, this.filters)

            if(params.keywords == '')
                delete params.keywords;

            // Add offset
            params.offset = this.items.length ? this.items.length + 1 : 0;

            return params

        }


    },

    watch: {

        filters: {
            deep: true,
            handler : _.debounce(function () {
                    this.items = [];
                    this.get().then(response => {
                        this.items = response.data.results;
                        this.uicontrol.show_load_more = response.data.more_results > 0;
                    });
                },
                500 // debounce time
            )
        }

    },

    components: {
        flatPickr,
		currency_sign
    },

    mounted() {

        this.get().then(response => {
            this.items = response.data.results;
            this.uicontrol.show_load_more = response.data.more_results > 0;
        });

    },

    methods: {

		deleteItem(item, itemKey) {

			Swal.fire({
				title: 'Delete?',
				text: "This is not reversible!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {
				if (result.isConfirmed) {
					axios.delete('/dashboard/product/order/' + item.identifier).then(response => {
						this.items.splice(itemKey, 1);
					});
				}
			})


		},

        col(){
            return this.is_admin ? 'col-lg-4' : 'col-lg-5';
        },

        get: function () {
            return axios.get('/dashboard/product/order/get', {
                params: this.params
            });
        },

        loadMore(){
            this.get().then(response => {
                this.items = this.items.concat(response.data.results);
                this.uicontrol.show_load_more = response.data.more_results > 0;
            });
        },

        formatTimeStamp(timestamp) {
            return DateTime.fromISO(timestamp).toFormat('d LLL yyyy H:mm');
        }

    },
}
</script>

<style scoped>

</style>
