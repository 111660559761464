<template>
    <div class="card">
        <div class="card-body d-flex p-0">
            <div class="pr-4">
                <div>
                    <button class="btn btn-sm btn-filled-rounded options" @click="$emit('edit', index)">...</button>
                    <h3 class="card-title" v-text="post.data.title"></h3>
                    <p v-text="post.data.short_description"></p>
                </div>
                <a :href="`/dashboard/communications/${post.category_slug}/${post.slug}`" class="btn btn-secondary btn-sm btn-white">Read more</a>
            </div>
            <div>
                <img class="maxwidth-100" :src="imgUrl(post.data.image)" alt="">
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "MixedSizeMixedWidthMixedAlign",
        data(){
            return {

            }
        },

        props : ['post', 'categorySlug', 'index'],

        methods : {

            imgUrl(string){

                if(string == null)
                    return '';

                return string.includes('http') ? string : '/file/' + this.post.data.image;

            }

        }
    }

</script>

<style scoped>

</style>
