<template>
	<span v-html="sign"></span>
</template>

<script>

import available_currencies from '@/misc/available_currencies';

// remove first element which should not exist in available_currencies anyways
available_currencies.shift();

export default {
	name: "CurrencySign",
	props : [
		'currency'
	],

	computed : {
		sign(){

			if(!Boolean(this.currency))
				return '';

			let currency = available_currencies.find(currency => { return currency.value == this.currency})
			return currency.label;
		}
	}
}
</script>

<style scoped>

</style>