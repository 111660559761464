<template>
    <div class="form-group row" v-if="uicontrol.ready">

        <label class="col-2 text-grey" for="" v-text="field.label"></label>

        <template v-if="editable">
            <div class="col-10">
                <ckeditor :editor="editor" v-model="field_value" :config="editorConfig"></ckeditor>
            </div>
        </template>

        <template v-else>
            <div class="col-lg-6 font-weight-500" v-html="field_value"></div>
        </template>

    </div>
</template>

<script>

    import '@/ckeditor5';

    export default {
        name: "TextAreaComponent",
        props: ['tab', 'field', 'val', 'editable'],
        components: {},
        computed: {

            field_value: {

                get: function () {
                    return this.val || '';
                },

                set: function (newVal) {
                    this.$emit('update', {
                        tab: this.tab,
                        field: this.field,
                        value: newVal
                    });
                }

            }

        },


        data: function () {

            return {

                uicontrol: {
                    ready: true,
                },

                editor: ClassicEditor,

                editorConfig : {
                    cloudServices: {
                        tokenUrl: ckeditor_easyimage_token_url,
                        uploadUrl: ckeditor_easyimage_upload_url
                    },
                    toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote', 'undo', 'redo', 'removeFormat']
                }

            }
        }
    }
</script>
