export default {

    initialize : function(context){

        let drop_area_container = document.getElementById('drop_area');

        console.log('Droparea', drop_area_container);

        if(!Boolean(drop_area_container))
            return;


        ['dragenter', 'dragover', 'dragleave'].forEach(eventName => {
            drop_area_container.addEventListener(eventName, preventDefaults, false)
        })

        function preventDefaults (e) {
            e.preventDefault()
            e.stopPropagation()
            console.table(e);
        }

        drop_area_container.addEventListener('drop', handleDrop, false)

        function handleDrop(e) {
            console.log('Drop', e);
            e.preventDefault()
            e.stopPropagation()
            let dt = e.dataTransfer
            let files = dt.files
            handleFiles(files)
        }

        function handleFiles(files) {
            ([...files]).forEach(uploadFile)
        }

        function uploadFile(file){
            context.$emit('drop_file', file);
        }

    }

}