<template>
    <div class="t-row" v-if="this.months[0].toMillis() && this.months[this.months.length - 1].toMillis()">
        <h5 class="sq name">
            {{report.facility.name}}
            <p>{{ report.type_name }}</p>
        </h5>
        <div class="sq studies">
            <p>{{ report.title }}</p>
        </div>
        <div class="sq">
            <p>{{ report.type_name }}</p>
        </div>
        <timeline :report="report" :months="months"></timeline>
        <div class="sq open">
            <a :href="'/dashboard/science/' + category + '/' + report.identifier">Open</a>
        </div>
    </div>


</template>

<script>

import { DateTime } from "luxon";
import timeline from "./reusable/Timeline"

export default {
    name: "ExperimentReport",
    props : ['report', 'months','category'],
    components : {
        timeline
    }
}
</script>

<style scoped>

</style>
