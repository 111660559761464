<template>

    <div class="mb-5">

        <ul class="list-group" v-if="uicontrol.ready">

            <template v-if="editable && is_author">
                <li class="list-group-item" v-for="(item, itemKey) of field_value.items" v-if="val">
                    <div class="form-group mb-0 d-flex justify-content-between">
                        <h4 class="mt-2 mr-3 text-secondary">
                            <span class="text-grey font-weight-500">
                                #{{itemKey + 1}}
                            </span>
                        </h4>
                        <input type="text" class="form-control" v-model="item.content">
                        <button type="button" class="btn btn-secondary ml-2" @click="remove(itemKey)">
                            -
                        </button>
                    </div>
                </li>
            </template>

            <template v-else="">
                <li class="list-group-item" v-for="(item, itemKey) of val.items" v-if="val">
                    <span class="text-grey font-weight-500">
                        #{{itemKey + 1}} {{item.content}}
                    </span>
                </li>
            </template>

        </ul>

        <button type="button" class="btn btn-secondary mt-2 float-right" @click="add()" v-if="editable && is_author">
            Add +
        </button>

    </div>

</template>

<script>
    export default {
        name: "OrderedList",
        props: ['tab', 'field', 'val', 'editable', 'is_author'],

        computed: {

            field_value: {

                get: function () {
                    return this.val;
                },

                set: function (newVal) {
                    this.$emit('update', {
                        tab: this.tab,
                        field: this.field,
                        value: newVal
                    });
                }

            }

        },

        data: function () {

            return {

                uicontrol: {
                    ready: false
                }

            }


        },

        mounted() {

            if (this.field_value === null) {
                this.field_value = {
                    items: [
                        {
                            'content': ''
                        }
                    ]
                }
            }

            this.uicontrol.ready = true;
        },


        methods: {


            add() {

                this.field_value.items.push({
                    content: ''
                });

                this.redraw();
            },

            remove(key) {
                this.field_value.items.splice(key, 1);
                this.redraw();
            },

            /**
             *
             */
            redraw() {
                this.uicontrol.ready = false;
                this.uicontrol.ready = true;
            }


        }
    }
</script>
