<template>
    <main>
        <span v-for="dte in end_dates" v-text="dte.timestamp"></span>
    </main>
</template>

<script>
    export default {
        name: "ResultReportsTimeline",
        props : ['start_date', 'end_dates']
    }
</script>

<style scoped>

</style>
