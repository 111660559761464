<template>
    <div>

        <div class="form-group row image-component">
            <div>
                <label class="col-2 text-grey" for="" v-text="field.label"></label>
                <p class="small ml-3 text-grey" v-text="field.params.description" v-if="field.params && editable"></p>
            </div>

            <!-- Edit Mode -->
            <div class="col-10 text-center" v-if="editable">

                <!-- File missing -->
                <template v-if="!field_value && !uicontrol.uploading">
                    <a class="btn btn-edit-exp" @click="file_input.click()">Select file</a>
                    <img class="cursor-pointer" src="/assets/sections/file_placeholder.jpg" alt="" @click="file_input.click()">
                </template>

                <div v-else-if="uicontrol.uploading">
                    <div class="progress">
                        <div class="progress-bar bg-success" role="progressbar" v-bind:style="{ 'width' : (uicontrol.upload_progress + '%') }" :aria-valuenow="uicontrol.upload_progress" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <h2 class="text-center" v-text="uicontrol.upload_progress + '%'"></h2>
                </div>

                <!-- File exists-->
                <template v-else>
                    <div class="image-wrapper">
                        <button class="btn btn-sm btn-danger delete" @click="deleteFile($event)">Delete file</button>
                        <h5>File uploaded</h5>
                    </div>
                </template>

                <input type="file" class="d-none" :name="'file_input_' + field.data_id" :id="'file_input_' + field.data_id" :accept="field.params.accepted_mime_types.join(',')" v-on:change="filePicked($event)">

            </div>

            <!-- View Mode -->
            <div class="col-10" v-else>

                <component v-if="field_value"
                    :is="field_value.mime_type.replace('/', '_') + '_viewer'"
                    :field_value="field_value">
                </component>

                <img src="/assets/sections/file_placeholder.jpg" alt="No file" v-else>

            </div>

        </div>

    </div>

</template>

<script>


    import application_pdf_viewer from "./file_viewers/application_pdf";
    import text_csv_viewer from "./file_viewers/text_csv";

    export default {
        name: "FileComponent",
        props: ['tab', 'field', 'val', 'editable'],

        components : {
            application_pdf_viewer,
            text_csv_viewer
        },

        data: function () {

            return {
                uicontrol: {
                    editing: false,
                    uploading: false,
                    upload_progress: 0
                },
                selected_file: null,
                file_input: null,
                // fresh_val: null

            }

        },

        computed: {

            field_value: {

                get: function () {
                    return this.val;
                },

                set: function (newVal) {

                    // this.fresh_val = newVal;

                    this.$emit('update', {
                        tab: this.tab,
                        field: this.field,
                        value: newVal
                    });
                }

            }

        },

        mounted() {
            this.file_input = document.getElementById('file_input_' + this.field.data_id);
        },


        methods: {

            filePicked: function (event) {

                this.selected_file = event.target.files[0];

                if(!this.field.params.accepted_mime_types.includes(event.target.files[0].type)){
                    Swal.fire('File type not accepted', 'Please only upload the recommended file types', 'error');
                    return;
                }

                // Upload
                this.upload();

            },

            upload: function (event) {

                let vm = this;

                vm.uicontrol.uploading = true;

                let form_data = new FormData();

                form_data.append('uploaded_file', this.selected_file);
                // form_data.append('mime_type', this.selected_file.type);

                axios.post('/file', form_data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: function (uploadEvent) {
                        vm.uicontrol.upload_progress = Math.round(uploadEvent.loaded / uploadEvent.total * 100);
                    }
                }).then(response => {

                    this.field_value = {
                        name : response.data.file.name,
                        mime_type : response.data.file.mime_type,
                        identifier : response.data.file.identifier
                    };

                    vm.uicontrol.uploading = false;
                    this.$emit('save');
                })

            },

            deleteFile: function (event) {

                event.preventDefault();

                axios.delete('/file/' + this.field_value.identifier, {
                    identifier: this.field_value.identifier
                }).then(response => {
                    if (response.data.success) {
                        this.field_value = null;
                        this.$emit('save');
                    }

                });

            }


        }


    }
</script>
