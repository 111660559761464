<template>

	<main>

		<div class="container" v-if="uicontrol.ready">

			<div class="card">

				<div class="row">

					<div class="col-6 d-flex pt-4">
						<div>
							<h3 class="title mb-2">Shipment</h3>
							<p class="small text-grey">Created at: {{ formatTimeStamp(shipment.created_at) }}</p>
						</div>

						<div class="status" style="margin-top: 3px;">

                            <span class="ml-2 font-weight-500 text-primary" v-if="shipment.status === 'active'">
                                Active
                            </span>

							<span class="ml-2 font-weight-500 text-success" v-if="shipment.status === 'approved'">
                                Approved
                            </span>

							<span class="ml-2 font-weight-500 text-danger" v-if="shipment.status === 'rejected'">
                                Rejected
                            </span>

							<span class="ml-2 font-weight-500 text-secondary" v-if="shipment.status === 'completed'">
                                Completed
                            </span>

							<span class="ml-2 font-weight-500 text-secondary" v-if="shipment.status === 'closed'">
                                Closed
                            </span>
						</div>
					</div>

					<div class="col-6">
						<div class="pb-2 d-flex justify-content-end" v-if="ready_to_add_operators || operators.length">
							<div class="text-right mr-2 mt-2">

								<h5 class="mb-0 text-grey">Operators</h5>
								<template v-if="shipment.status === 'active'">
									<p class="small text-grey mb-0" v-if="is_author && !operators.length">You need an
										operator to approve your request</p>
									<p class="small text-grey mb-0" v-if="is_operator">You can approve or reject the
										request</p>
								</template>
							</div>

							<user_permissions :users="operators" :permission_name="'shipment.operator.' + shipment.id" :permission_prerequisite="'operations.shipment.view'" @added_user="popupAddedUser($event)" :editable="status_pending && is_author" @open_search="$validator.validate()"></user_permissions>

						</div>

						<!-- Statuses-->
						<div class="d-flex justify-content-end">

							<!-- Author -->
							<div v-if="is_author">
								<button class="btn btn-rounded btn-white" @click="save($event)" v-if="status_pending || status_ready" :class="{ loading : uicontrol.saving }">
									<div data-loader="circle-side"></div>
									Save
								</button>
							</div>

							<!-- Operator -->
							<div v-if="is_operator">
								<template v-if="['pending'].includes(shipment.status) && has_boxes && shipment.data.pick_up_ready_date && (total_stock_items === total_box_items)">
									<button class="btn btn-rounded btn-outline-success" @click="shipment.status = 'ready'; save()">
										<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
											<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
											<path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
										</svg>
										Shipment ready
									</button>
								</template>
							</div>

						</div>

					</div>

					<div class="col-12">
						<span class="status pending" v-if="shipment.status === 'pending'">Shipment awaiting author and operator inputs</span>
						<span class="status ready" v-if="shipment.status === 'ready'">Shipment is prepared and ready to be picked up at the date specified under package information</span>
						<span class="status picked_up" v-if="shipment.status === 'picked_up'">Shipment has been picked up by the courier</span>
						<span class="status completed" v-if="shipment.status === 'completed'">Shipment has arrived</span>
					</div>

				</div>

				<div class="row mb-4">

					<div class="col-12 d-flex">

						<div class="form-group mr-2 mb-0" style="width: 60%">
							<label for="title" class="form-label font-weight-500">Title</label>
							<input type="text" class="form-control" id="title" name="title" placeholder="" v-validate="'required'" v-model="shipment.title" :disabled="!is_author || !status_pending">
							<span class="text-danger">{{ errors.first('title') }}</span>
						</div>

						<div class="form-group mr-2 mb-0" style="width: 60%">
							<label for="cost" class="form-label font-weight-500">Shipment cost</label>
							<float_input
								:id="'cost'"
								:name="'cost'"
								:val="shipment.data.cost" @update="shipment.data.cost = $event"
								:decimals="2"
								:disabled="!is_author || !status_pending">
							</float_input>
							<span class="text-danger">{{ errors.first('cost') }}</span>
						</div>

						<div class="form-group mr-2 mb-0" style="width: 40%">
							<label for="currency" class="form-label font-weight-500">Currency</label>
							<select name="field" id="currency" v-model="shipment.data.currency" class="form-control" :disabled="!is_author || !status_pending">
								<option v-for="currency in available_currencies" v-bind:value="currency.value" v-html="currency.label" :selected="currency.value == shipment.data.currency"></option>
							</select> <span class="text-danger">{{ errors.first('currency') }}</span>
						</div>

						<div class="form-group mr-2 mb-0" style="width: 70%">
							<label for="tracking_number" class="form-label font-weight-500">Tracking number</label>
							<!-- Valid tracking number -->
							<input type="text" class="form-control" id="tracking_number" name="tracking_number" v-validate="'dhlTrackingNumber'" placeholder="" v-model="shipment.data.tracking_number" :disabled="!is_author || status_delivered">
							<span class="text-danger">{{ errors.first('tracking_number') }}</span>
						</div>

					</div>

				</div>

			</div>

			<div class="card-tabs mb-4">

				<nav>
					<div class="nav nav-tabs" id="nav-tab" role="tablist">
						<a class="nav-link" v-for="(tab, tab_key) in tabs" :key="tab_key" :class="{ active : (uicontrol.active_tab == tab.id || (uicontrol.active_tab == null && tab_key == 0) ) }" :id="tab.id" @click="uicontrol.active_tab = tab.id" aria-selected="true">{{
								tab.name
							}}</a>
					</div>
				</nav>

				<div class="tab-content" id="nav-tabContent">

					<div class="tab-pane fade show active pt-2 pb-5" v-show="uicontrol.active_tab == 'recipient'  || uicontrol.active_tab == null">
						<div class="row">

							<div class="col-12 d-flex">

								<div class="form-group mr-2" style="width: 25%;">
									<label for="recipient_name" class="form-label font-weight-500">Name</label>
									<input type="text" class="form-control" id="recipient_name" name="recipient_name" placeholder="" v-validate="'required'" v-model="shipment.data.recipient.name" :disabled="!is_author || !status_pending">
									<span class="text-danger">{{ errors.first('recipient_name') }}</span>
								</div>

								<div class="form-group mr-2" style="width: 25%;">
									<label for="recipient_email" class="form-label font-weight-500">Email</label>
									<input type="email" class="form-control" id="recipient_email" name="recipient_email" placeholder="" v-validate="'required|email'" v-model="shipment.data.recipient.email" :disabled="!is_author || !status_pending">
									<span class="text-danger">{{ errors.first('recipient_email') }}</span>
								</div>

								<div class="form-group mr-2" style="width: 25%;">
									<label for="recipient_telephone" class="form-label font-weight-500">Telephone <span class="small text-grey">(+12345678900)</span></label>
									<input type="text" class="form-control" id="recipient_telephone" name="recipient_telephone" placeholder="" v-validate="'required|phoneNumber'" v-model="shipment.data.recipient.telephone" :disabled="!is_author || !status_pending">
									<span class="text-danger">{{ errors.first('recipient_telephone') }}</span>
								</div>

								<div class="form-group mr-2" style="width: 25%;">
									<label for="recipient_company" class="form-label font-weight-500">Company</label>
									<input type="text" class="form-control" id="recipient_company" name="recipient_company" placeholder="" v-validate="'required'" v-model="shipment.data.recipient.company" :disabled="!is_author || !status_pending">
									<span class="text-danger">{{ errors.first('recipient_company') }}</span>
								</div>

							</div>

						</div>


						<div class="row">

							<div class="col-12 d-inline-flex">

								<div class="form-group mr-2" style="width: 50%;">
									<label for="recipient_address_1" class="form-label font-weight-500">Address</label>
									<input type="text" class="form-control" id="recipient_address_1" name="recipient_address_1" placeholder="" v-validate="'required'" v-model="shipment.data.recipient.address_1" :disabled="!is_author || !status_pending">
									<span class="text-danger">{{ errors.first('recipient_address_1') }}</span>
								</div>

								<div class="form-group mr-2" style="width: 50%;">
									<label for="recipient_address_2" class="form-label font-weight-500">Address
										2</label>
									<input type="text" class="form-control" id="recipient_address_2" name="recipient_address_2" placeholder="" v-validate="'required'" v-model="shipment.data.recipient.address_2" :disabled="!is_author || !status_pending">
									<span class="text-danger">{{ errors.first('recipient_address_2') }}</span>
								</div>

								<div class="form-group mr-2" style="width: 50%;">
									<label for="recipient_address_2" class="form-label font-weight-500">City</label>
									<input type="text" class="form-control" id="city" name="city" placeholder="" v-validate="'required'" v-model="shipment.data.recipient.city" :disabled="!is_author || !status_pending">
									<span class="text-danger">{{ errors.first('city') }}</span>
								</div>

							</div>

						</div>

						<div class="row">

							<div class="col-12 d-flex">

								<div class="form-group mr-2" style="width: 25%;">
									<label for="recipient_state" class="form-label font-weight-500">State</label>
									<input type="text" class="form-control" id="recipient_state" name="recipient_state" placeholder="" v-validate="'required'" v-model="shipment.data.recipient.state" :disabled="!is_author || !status_pending">
									<span class="text-danger">{{ errors.first('recipient_state') }}</span>
								</div>

								<div class="form-group mr-2" style="width: 25%;">
									<label for="recipient_province" class="form-label font-weight-500">Province</label>
									<input type="text" class="form-control" id="recipient_province" name="recipient_province" placeholder="" v-validate="'required'" v-model="shipment.data.recipient.province" :disabled="!is_author || !status_pending">
									<span class="text-danger">{{ errors.first('recipient_province') }}</span>
								</div>

								<div class="form-group mr-2" style="width: 25%;">
									<label for="recipient_country" class="form-label font-weight-500">Country</label>
									<input type="text" class="form-control" id="recipient_country" name="recipient_country" placeholder="" v-validate="'required'" v-model="shipment.data.recipient.country" :disabled="!is_author || !status_pending">
									<span class="text-danger">{{ errors.first('recipient_country') }}</span>
								</div>

								<div class="form-group mr-2" style="width: 25%;">
									<label for="recipient_zip" class="form-label font-weight-500">Postal / Zip
										Code</label>
									<input type="text" class="form-control" id="recipient_zip" name="recipient_zip" placeholder="" v-validate="'required'" v-model="shipment.data.recipient.zip" :disabled="!is_author || !status_pending">
									<span class="text-danger">{{ errors.first('recipient_zip') }}</span>
								</div>

							</div>

						</div>

					</div>

					<div class="tab-pane fade show active pt-2 pb-5" v-show="uicontrol.active_tab == 'products'">

						<div class="row mb-4" v-if="!shipment.data.reserved_stock_items.length">
							<div class="col-12 text-center">
								<h5>No products added</h5>
							</div>
						</div>

						<template v-else="">

							<div class="row table-header mb-1">
								<div class="col-4 text-grey font-weight-500 pb-1">
									Product name
								</div>
								<div class="col-1 text-grey font-weight-500 pb-1">
									Amount
								</div>
								<div class="col-2 text-grey font-weight-500 pb-1">
									Batch ID
								</div>
								<div class="col-5 text-grey font-weight-500 pb-1 text-right">
									Data
								</div>
							</div>

							<div class="row table-row" v-for="(stock_item, stockItemKey) in shipment.data.reserved_stock_items ">
								<div class="col-4 table-cell">
									{{ stock_item.product_name }}
								</div>
								<div class="col-1 table-cell">
									{{ number_en(stock_item.amount, 0) }}
								</div>
								<div class="col-2 table-cell">
									{{ stock_item.batch }}
								</div>
								<div class="col-4 table-cell">
                                    <span class="d-block fullwidth text-right" v-for="data_point in stock_item.data.elements">
                                        {{ data_point.title }} : {{ data_point.value }}
                                    </span>
								</div>
								<div class="col-1">
									<i class="fa fa-trash btn-icon cursor-pointer text-danger p-1" @click="unreserveStockItem(stock_item, stockItemKey)" style="margin-top: 11px; margin-left: 22px;"></i>
								</div>
							</div>

						</template>

						<div class="row">

							<div class="col-12 text-right mt-3" :class="{ 'text-center' : !shipment.data.reserved_stock_items.length }" v-if="!uicontrol.product_picker.visible">

								<div @click="uicontrol.product_picker.show()" id="add_product_btn" class="text-primary" v-if="is_author && status_pending">

									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle-fill" viewBox="0 0 16 16">
										<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"/>
									</svg>

									<span class="font-weight-500 mt-1" style="line-height:28px; display: inline-block;">
                                        Add new product
                                    </span>

								</div>

							</div>

						</div>

						<product_picker @add_product="reserveStockItem($event)" @hide="uicontrol.product_picker.hide()" v-if="uicontrol.product_picker.visible"></product_picker>

					</div>

					<!--
						PACKAGE INFORMATION
					-->

					<div class="tab-pane fade show active pt-2" v-show="uicontrol.active_tab == 'package_information'">

						<h5 class="mb-2">Required products</h5>

						<div class="row table-header mb-1">
							<div class="col-4 text-grey font-weight-500 pb-1">
								Product name
							</div>
							<div class="col-1 text-grey font-weight-500 pb-1">
								Amount
							</div>
							<div class="col-2 text-grey font-weight-500 pb-1">
								Batch ID
							</div>
							<div class="col-5 text-grey font-weight-500 pb-1 text-right">
								Data
							</div>
						</div>

						<div class="row table-row" v-for="(stock_item, stockItemKey) in shipment.data.reserved_stock_items ">
							<div class="col-4 table-cell">
								{{ stock_item.product_name }}
							</div>
							<div class="col-1 table-cell">
								{{ number_en(stock_item.amount, 0) }}
							</div>
							<div class="col-2 table-cell">
								{{ stock_item.batch }}
							</div>
							<div class="col-5 table-cell">
                                    <span class="d-block fullwidth text-right" v-for="data_point in stock_item.data.elements">
                                        {{ data_point.title }} : {{ data_point.value }}
                                    </span>
							</div>
						</div>


						<h5 class="mb-2 mt-5">Boxes</h5>

						<div class="row g-3 pb-2">

							<div class="col-1">
                                <span class="font-weight-500 text-grey">
                                    Box No.
                                </span>
							</div>

							<div class="col-1">
                                <span class="font-weight-500 text-grey">
                                    No. units in box
                                </span>
							</div>

							<div class="col-3 text-right">
                                <span class="font-weight-500 text-grey">
                                    Dimensions L x W x H(cm)
                                </span>
							</div>

							<div class="col-2 text-right">
                                <span class="font-weight-500 text-grey">
                                    Net weight (kg)
                                </span>
							</div>

							<div class="col-2 text-right">
                                <span class="font-weight-500 text-grey">
                                    Gross Weight (kg)
                                </span>
							</div>
							<div class="col-2 text-right">
                                <span class="font-weight-500 text-grey">
                                    Description
                                </span>
							</div>
							<div class="col-1 text-right">
								&nbsp;
							</div>
						</div>

						<form class="needs-validation" novalidate v-if="shipment.data.boxes.length">

							<div class="row g-3 pt-2 pb-2" style="border-top: 1px solid #DFE1E6" v-for="(box, boxKey) in shipment.data.boxes">

								<div class="col-1">
									#{{ boxKey + 1 }}
								</div>

								<div class="col-1">
									{{ number_en(box.contents_amount, 0) }}
								</div>

								<div class="col-3 text-right">
									{{ box.length }} x {{ box.width }} x {{ box.height }}
								</div>

								<div class="col-2 text-right">
									{{ box.net_weight }}
								</div>

								<div class="col-2 text-right">
									{{ box.gross_weight }}
								</div>

								<div class="col-2 text-right">
									{{ box.description || 'no description' }}
								</div>

								<div class="col-1 text-right" v-if="status_pending">
									<i class="fa fa-trash btn-icon cursor-pointer text-danger p-1" @click="shipment.data.boxes.splice(boxKey, 1)"></i>
								</div>

							</div>

							<template v-if="!uicontrol.box_editor.visible">
								<div class="row">
									<div class="col-5">
										<div class="form-group">
											<label for="pick_up_date">Package ready date (UK Time)</label>
											<flat-pickr name="pick_up_date" id="pick_up_date" v-model="shipment.data.pick_up_ready_date" :config="flatpickr_config" placeholder="Ready date" :disabled="!is_operator || shipment.status !== 'pending'"></flat-pickr>
										</div>
									</div>
									<div class="col-5">
										<div class="form-group">
											<label for="invoice_number" class="form-label font-weight-500">Invoice
												number</label>
											<input type="text" class="form-control" id="invoice_number" name="invoice_number" placeholder="" v-model="shipment.data.invoice_number" :disabled="!is_operator || shipment.status !== 'pending'">
											<span class="text-danger">{{ errors.first('invoice_number') }}</span>
										</div>
									</div>
									<div class="col-2">
										<button class="btn btn-rounded btn-outline-success" style="margin-top: 30px;" @click="shipment.status = 'ready'; save($event)"
											:disabled="!(['pending'].includes(shipment.status) && is_operator && shipment.data.boxes.length && shipment.data.pick_up_ready_date && (total_stock_items === total_box_items))">
											<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle" viewBox="0 0 16 16">
												<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
												<path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"/>
											</svg>
											Shipment ready
										</button>
									</div>
								</div>
							</template>

						</form>

						<div class="row pb-3">
							<box_editor @add="shipment.data.boxes.push($event); uicontrol.box_editor.hide()" @hide="uicontrol.box_editor.hide()" v-if="uicontrol.box_editor.visible"></box_editor>
						</div>

						<div class="col-12 text-center">

							<!-- Author Sees -->
							<span class="pt-4 mb-2 text-primary d-block mt-5" v-if="!is_operator && !shipment.data.boxes.length">
                                The operator will add the boxes and ready date
                            </span>

							<!-- Operator Sees -->
							<span class="pt-4 mb-2 text-primary d-block mt-5" v-if="is_operator && !shipment.data.boxes.length && !shipment.data.pick_up_ready_date && !uicontrol.box_editor.visible">
                                Click here to add shippers to this order
                            </span>


							<span class="pt-4 mb-2 text-primary d-block" v-if="is_operator && shipment.data.boxes.length && !shipment.data.pick_up_ready_date">
                                Specify a package ready date after adding all the boxes
                            </span>

							<p class="text-primary mb-3" v-if="['pending'].includes(shipment.status) && is_operator && shipment.data.boxes.length && shipment.data.pick_up_ready_date">
								The shipment can be marked ready. Please double check the date and boxes information
								before clicking "Shipment ready" </p>

							<button class="btn btn-sm btn-rounded btn-outline-primary mb-5 mt-1" @click="uicontrol.box_editor.show()" v-if="shipment.status !== 'completed' &&  !uicontrol.box_editor.visible" :disabled="!is_operator || shipment.status !== 'pending'">
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
									<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
									<path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
								</svg>
								Add box
							</button>



						</div>

					</div>



					<div class="tab-pane fade show active pt-2" v-show="uicontrol.active_tab == 'tracking'">
						<div class="row" v-if="shipment.data.tracking_number">
							<tracking_information :tracking_number="shipment.data.tracking_number"></tracking_information>
						</div>
						<div class="row" v-else="">
							<div class="col-12 text-center mt-5 mb-5">
								<h5>Tracking number missing</h5>
								<p>Tracking information will be displayed after a tracking number has been entered</p>
							</div>
						</div>
					</div>

				</div>

			</div>

			<div class="card">
				<div class="row">
					<div class="col-12 mt-4">
						<h5>Message board</h5>
					</div>
					<div class="col-12">
						<channel :channel="'shipment.' + shipment.identifier" :channel_type="'private'" :commenting_enabled="true" :scroll_bottom="false"></channel>
					</div>
				</div>
			</div>

		</div>

	</main>

</template>

<script>

import { formatTimeStamp } from '@/misc/date_utilities';
import available_currencies from '@/misc/available_currencies';
import box_editor from '@/components/sections/shipment/box_editor/BoxEditor';
import product_picker from '@/components/sections/shipment/product_picker/ProductPicker';
import tracking_information from '@/components/sections/shipment/tracking/TrackingInformation';
import user_permissions from '@/components/sections/science/UserPermissions';
import file_upload from '@/components/sections/reusable/FileUpload';
import file from "@/components/sections/reusable/File";
import channel from '@/components/chat/Channel';
import Swal from "sweetalert2";
import { number_en } from '@/misc/number_utilities';

import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {
	name: "Standard",
	data: function () {
		return {
			// shipment.data.selected_product : null,
			uicontrol: {
				saving: false,
				ready: false,
				show_pdf_viewer: false,
				product_picker: {
					visible: false,
					show() {
						this.visible = true;
					},
					hide() {
						this.visible = false;
					}
				},
				box_editor: {
					visible: false,
					show() {
						this.visible = true;
					},
					hide() {
						this.visible = false;
					}
				},
				active_tab: null
			},
			tabs: [
				{
					id: 'recipient',
					name: 'Recipient'
				},
				{
					id: 'products',
					name: 'Products'
				},
				{
					id: 'package_information',
					name: 'Package Information'
				},
				{
					id: 'tracking',
					name: 'Tracking'
				}
			],
			available_currencies: available_currencies,
			flatpickr_config: {
				dateFormat: "Y-m-d H:i:ss",
				altInput: true,
				altFormat: "D, d F Y h:i K",
				time_24hr: false,
				enableTime: true,
			},
			formatTimeStamp,
			touched : false,
			ready_to_add_operators : false,
			number_en
		}
	},

	props: [
		'operators',
		'shipment',
		'is_author',
		'is_operator'
	],

	components: {
		channel,
		file_upload,
		file,
		user_permissions,
		tracking_information,
		product_picker,
		box_editor,
		flatPickr
	},

	computed: {

		has_boxes() {
			return Boolean(this.shipment.data.boxes.length);
		},

		has_products() {
			return Boolean(this.shipment.data.reserved_stock_items.length);
		},

		status_pending() {
			return this.shipment.status == 'pending';
		},

		status_ready() {
			return this.shipment.status == 'ready';
		},

		status_delivered() {
			return this.shipment.status == 'delivered';
		},

		total_stock_items(){

			let total = 0;

			this.shipment.data.reserved_stock_items.forEach(element => {
				total += parseInt(element.amount)
			})

			return total;

		},

		total_box_items(){

			let total = 0;

			this.shipment.data.boxes.forEach(element => {
				total += parseInt(element.contents_amount)
			})

			return total;

		}

	},

	mounted() {

		var vm = this;

		// Set this template for the data field
		if (this.shipment.data && Object.keys(this.shipment.data).length === 0 && Object.getPrototypeOf(this.shipment.data) === Object.prototype) {
			this.shipment.data = shipment_template.getEmptyData();
			this.save();
		}

		this.uicontrol.ready = true;


		if (this.is_operator) {
			this.uicontrol.active_tab = 'package_information';
		}

		setTimeout(() => {
			vm.touched = false;
			vm.evaluateReadyToAddOperators();
		}, 500);

	},

	methods: {

		evaluateReadyToAddOperators(){
			this.ready_to_add_operators = 	Boolean(this.shipment.title) && // Shipment has title
											!Object.values(this.shipment.data.recipient).includes(null) && // none of the recipient fields is null
											this.has_products
		},

		/**
		 * @param payload
		 */
		reserveStockItem(payload) {

			payload.then = 'update_shipment';
			payload.shipment_identifier = this.shipment.identifier;

			// Get Stock items
			axios.get('/dashboard/stock-item/reserve', {
				params: payload
			}).then(response => {
				this.shipment.data.reserved_stock_items = this.shipment.data.reserved_stock_items.concat(response.data)
				this.evaluateReadyToAddOperators()
			})

			// Hide the picker
			this.uicontrol.product_picker.hide();

		},

		/**
		 * @param payload
		 * @param stock_item_key
		 */
		unreserveStockItem(payload, stock_item_key) {

			payload.then = 'update_shipment';
			payload.shipment_identifier = this.shipment.identifier;

			// Get Stock items
			axios.get('/dashboard/stock-item/unreserve', {
				params: payload
			}).then(response => {
				this.shipment.data.reserved_stock_items.splice(stock_item_key, 1)
				this.evaluateReadyToAddOperators()
			})

		},

		/**
		 * @param user
		 */
		popupAddedUser(user) {
			Swal.fire('Operator added', `You have chosen ${user.name} to prepare this shipment at the warehouse`, 'info');
		},

		/**
		 * @returns {default.methods}
		 */
		update() {
			this.$emit('update', this.shipment);
			return this;
		},

		/**
		 *
		 */
		save(e = null) {

			if(Boolean(e))
				e.preventDefault();

			var vm = this;

			vm.$validator.validate().then(valid => {

				console.log(valid);

				if (valid) {

					// Fake saving
					vm.uicontrol.saving = true;
					setTimeout(function () {
						vm.uicontrol.saving = false;
					}, 500);

					vm.$emit('save', vm.shipment);
					vm.evaluateReadyToAddOperators();
					return vm;

				}

			});

		},


	}

}

const shipment_template = {

	getEmptyData: function () {
		return {
			recipient: {
				name: null,
				email: null,
				telephone: null,
				company: null,
				address_1: null,
				address_2: null,
				state: null,
				province: null,
				country: null,
				zip: null
			},
			reserved_stock_items: [],
			boxes: [],
			tracking_number: null,
			pick_up_ready_date: null,
			invoice_number: null,
			cost: null,
			currency: null
		}
	}

}


</script>


<style scoped>

.btn-white {
	background-color: #FFFFFF;
	border: 1px solid #DFE1E6;
	box-sizing: border-box;
	box-shadow: 0px 2px 2px -1px rgba(13, 42, 64, 0.06);
	border-radius: 100px;
	color: #44526C;
	margin-left: 20px;
	outline: none;
	font-size: 14.4px;
}

.btn-white:hover {
	background-color: #f7f7f7;
}


.table-header {
	border-bottom: 1px solid #ebecf0
}

.table-row {
	border-bottom: 1px solid #ebecf0
}

.table-cell, .table-cell span {
	font-size: 14px;
}

.table-cell {
	padding: 2px 0px 2px 13px;
}


@media print {
	.file-detail-modal {
		display: none !important;

	}
}

#add_product_btn {
	display: inline-block;
	cursor: pointer;
}


</style>
