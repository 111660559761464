<template>
    <div>
        <div class="ep" action="begin"></div>

        <search
            v-if="show_search && editable"
            :category="'science_experiments'"
            :action="'select'"
            @selected_search_result="add($event)"
        ></search>

        <div v-else-if="Boolean(data.identifier)">
            <a :title="data.title" class="title" target="_blank" :href="data.url" v-html="title_with_line_breaks"></a>
        </div>

        <div v-else="">
            <span class="text-gray">No experiment selected</span>
        </div>

    </div>

</template>

<script>
export default {
    name: "Experiment",
    props: ['index','data','editable'],
    data : function(){
        return {
            show_search : true,
            show_blockchain_ui : false
        }
    },

    computed : {
        title_with_line_breaks(){

            let string_with_line_breaks = '';

            let i = 0;

            for(var letter of this.data.title){

                string_with_line_breaks+=letter;

                if(i >= 30 && letter == ' '){
                    string_with_line_breaks+='<br>';
                    i = 0;
                    continue;
                }

                i++;
            }

            return string_with_line_breaks;

        }
    },

    mounted(){
        this.show_search = (this.data.identifier == null) ? true : false;
        this.show_blockchain_ui = typeof this.data.id !== 'undefined';
    },

    methods : {
        add(data){

            this.$emit('update_node', {
                index : this.index,
                data : {
                    title : data.title,
                    url : data.url,
                    identifier : data.identifier,
                }
            });

            this.show_search = false;

        }
    },

    // Node specific properties
    readable_name: "Experiment",
    type : 'experiment',
    sample_data : {
        title : null,
        url : null,
        identifier : null,
        tags : []
    },
    show_in_menu : true
}
</script>

<style scoped>

</style>
