<template>
    <div class="result">
        <div class="line fullwidth d-flex justify-content-between">
            <span class="text-center result-title" v-text="title_with_line_breaks"></span>
            <button class="btn btn-raph btn-sm btn-primary" v-if="typeof action !== undefined && action == 'select'" @click="$emit('selected_search_result', search_result)">Select</button>
            <a class="btn btn-raph btn-sm btn-primary" :href="search_result.url" v-else>Open</a>
        </div>
        <div class="subline">
            <p class="small mb-2">Idea Board</p>
        </div>
    </div>
</template>

<script>
    export default {
        name : 'Idea',
        props : ['search_result', 'action'],
        data : function(){
            return {

            }
        },
        computed : {

            title_with_line_breaks(){

                let string_with_carriage_return = '';

                let i = 0;

                for(var letter of this.search_result.title){

                    string_with_carriage_return+=letter;

                    if(i == 50){
                        string_with_carriage_return+='\r\n';
                        i = 0;
                        continue;
                    }

                    i++;
                }

                return string_with_carriage_return;

            }

        }

    }
</script>
