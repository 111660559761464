export default
[
    {
        label: 'Select...',
        value: null
    },
    {
        label: '&pound;',
        code: 'GBP',
        value: 'pound',
    },
    {
        label: '&euro;',
        code: 'EUR',
        value: 'euro'
    },
    {
        label: '$',
        code: 'USD',
        value: 'usd'
    },
    {
        label: 'AUD ',
        code: 'AUD',
        value: 'aud'
    }
]