<template>
    <div class="container" v-if="uicontrol.ready">
        <div class="card">

            <component
                :is="payment.type"
                :payment="payment"
				:subject="subject"
                :editable="editable"
                :is_author="is_author"
                :is_participant="is_participant"
                :participants="participants"
                @update="update($event)"
                @save="save($event)"
                @set_editable="setEditable($event)"
            ></component>

        </div>
    </div>

</template>

<script>

import invoice from "./types/invoice";

export default {

    name: "Payment",

    data : function () {
      return {
		  payment : null,
          uicontrol: {
              ready : false
          },
          editable : null
      }
    },

    props : [
        'participants',
        'is_author',
        'is_participant',
		'subject'
    ],

    components : {
		invoice
    },

    mounted(){
        this.payment = payment;
        this.editable = editable;
        this.uicontrol.ready = true;
    },

    methods: {

        update(updated_payment){
            this.payment = updated_payment;
        },

        save(payment){
            var vm = this;
            this.update(payment);
            axios.put('/dashboard/payments/' + vm.payment.identifier, vm.payment);
        },

        setEditable(value){
            Vue.set(this, 'editable', value);
        }

    }

}

</script>

<style scoped>

</style>
